<template>
  <div class="list-group">
    
    <vue-good-table
      :columns="tableColumns"
      :rows="list"
      styleClass="vgt-table striped condensed"
      :search-options="{
        enabled: true}">
      <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'">
            <!--<b-button @click="changeSelected(props.row)">Vedi macchinari</b-button>-->
            <b-button @click="changeSelected(props.row)">Modifica</b-button>
          </span>
          <span v-else-if="props.column.field == 'datetime'">
            {{props.row.datetime | moment("DD/MM/YYYY")}}
          </span>
          <span v-else-if="props.column.field == 'site'">
            {{ getPlaceInfo(props.row.site, 'name') }}
          </span>
          <span v-else-if="props.column.field == 'city'">
            {{ getPlaceInfo(props.row.site, 'city') }}
          </span>
        </template>
    </vue-good-table>
  </div>
</template>

<script>
export default {
  name: "VisitList",
  props: {
    list: { type: Array, default: () => [] },
    visitList: { type: Array, default: () => [] },
    siteList: { type: Array, default: () => [] },
  },
  data() {
    return {
      tableColumns: [{
          label: 'Luogo',
          field: 'site',
        },{
          label: 'Data visita',
          field: 'datetime',
        },{
          label: 'Citta',
          field: 'city',
        },{
          label: '',
          field: 'actions',
        }
      ]
    }
  },
  mounted() {
  

  },
  computed: {
   

  },
  methods: {
    changeSelected(visit) {
      //console.log('Spare select: ',spare);
      this.$emit("visitChanged", visit);
    },
    getMachineLabel(mId) {
      let machineName = this.machineList.find(m => m._id == mId);
      if(!machineName) machineName = 'Non trovata';
      else machineName = machineName.name + ' (' + machineName.shortCode + ')'; 
      //console.log(machineName);
      return machineName;
      
    },
    getStatusLabel(thisStatus){
      if(this.orderStatusList[thisStatus])
        return  this.orderStatusList[thisStatus];
      else 
        return 'Sconosciuto';
    },
    getPlaceInfo(siteId, key) {
      const siteInfo = this.siteList.find(site => site._id === siteId);
      if(!siteInfo) {
        if(key) {
          return `${key} not found`
        } else {
          return 'not found'
        }
      }
      return key ? siteInfo[key] : siteInfo
    }
  }
};
</script>
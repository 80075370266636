<template>
  <div class="list-group">
    
    <vue-good-table
      :columns="tableColumns"
      :rows="orderListFormatted"
      styleClass="vgt-table striped condensed"
      :search-options="{
        enabled: true}">
      <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'">
            <b-button @click="changeSelected(props.row)">Modifica</b-button>
            <!--<b-button v-if="props.row.orderStatus==='COMPLETE' || props.row.orderStatus==='SENT'" @click="recived(props.row)" >Ricevuto</b-button>-->
            <b-button :disabled="props.row.orderStatus==='NEW'" @click="orderToPdf(props.row)" >Download PDF</b-button>
            
            

          </span>
          <!--<span v-else-if="props.column.field == 'machineId'">
            {{getMachineLabel(props.row.machineId)}}
          </span>-->
          <span v-else-if="props.column.field == 'orderStatus'">
            {{getStatusLabel(props.row.orderStatus)}}
          </span>
          <span v-else-if="props.column.field == 'orderDate'">
            {{props.row.orderDate | moment("DD/MM/YYYY")}}
          </span>
          <span v-else-if="props.column.field == '_id'">
            {{props.row._id.substring(props.row._id.length-5,props.row._id.length) }}
          </span>
        </template>
    </vue-good-table>
  </div>
</template>

<script>
import axios from "axios"
import moment from "moment"
import global from "../../../globalvar";

export default {
  name: "OrderList",
  props: {
    list: { type: Array, default: () => [] },
    machineList: { type: Array, default: () => [] }
  },
  data() {
    return {
      orderStatusList: {
        'NEW':'Richiesto',
        'SENT':'Inviato',
        'HIDE':'Arrivato',
      },
      tableColumns: [{
          label: '',
          field: 'actions',
        }, {
          label: 'Codice',
          field: '_id',
        },{
          label: 'Stato',
          field: 'orderStatus',
        },{
          label: 'Fornitore',
          field: 'supplier',
        }, {
          label: 'Data ordine',
          field: 'orderDate',
        },/*, {
          label: 'Email',
          field: 'contact',
        }, {
        //  label: 'Data ordine',
        //  field: 'orderDate',
        },  {
          label: 'Note',
          field: 'notes',
        }*/
      ]
    }
  },
  mounted() {
    this.list.forEach( spar => {
      spar.machineName = this.getMachineLabel(spar.machineId);
      
    });


  },
  computed: {
    orderListFormatted(){
      return this.list.map( elem => {
        return {
          ...elem,
          machineName: this.getMachineLabel(elem.machineId)
        }
      })
    }

  },
  methods: {
    
    changeSelected(order) {
      //console.log('order select: ',order);
      this.$emit("orderChanged", order);
    },
    getMachineLabel(mId) {
      let machineName = this.machineList.find(m => m._id == mId);
      if(!machineName) machineName = 'Non trovata';
      else machineName = machineName.name + ' (' + machineName.shortCode + ')'; 
      //console.log(machineName);
      return machineName;
      
    },
    getStatusLabel(thisStatus){
      if(this.orderStatusList[thisStatus])
        return  this.orderStatusList[thisStatus];
      else 
        return 'Sconosciuto';
    },
    
    orderToPdf(_data) {

      _data._id = _data._id.substring(_data._id.length-5,_data._id.length); 
      _data.orderDate =  moment(_data.orderDate).format("DD-MM-YYYY");
      axios({
      method: 'post',
      headers: {
                "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token,
      },
      url: global.ApiBaseUrl + '/api/createPDF/maintenance-order',
      responseType: 'arraybuffer',
      data: _data
    }).then(function(response) {
      let blob = new Blob([response.data], { type: 'application/pdf' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      
      link.download =   'Order_' +_data._id + '_' + _data.orderDate +'.pdf'
      link.click()
    })

    }
  }
};
</script>
<template>
  <div class="about">
    <h1>Authentication...</h1>
  </div>
</template>
<script>
import JWT from "jsonwebtoken"

export default {
  name: "auth",
  data() {
    return {};
  },
  mounted() {
    let url = new URL(location.href)
    let isLogged = url.searchParams.get("logged") == "yes";
    if(isLogged) {
      let token = url.searchParams.get("token")
      let authObj =  JWT.decode(token)
      authObj.token = token;
      this.$store.dispatch("appModule/setAuthInfo", authObj, {root:true});

      setTimeout(() => this.$router.push('/'), 1000);
      
      //this.$router.push('/');
    } else {
      alert("logged non è yes");
    }
  }
};
</script>

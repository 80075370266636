<template>
  <div class="orderPage">
    <!--<h1>Ricambi</h1>-->
    <b-alert show variant="warning"><b-icon icon="exclamation-circle-fill" variant="danger"></b-icon> La sezione ORDINI è in BETA potrebbero verificarsi perdite di dati o errori</b-alert>

    <b-modal size="lg" ref="order-modal" 
      :title="(inModifyMode ? 'Modifica' : 'Crea') + ' ordine'"
      :ok-title="inModifyMode ? 'Modifica' : 'Crea'"
      @close="onChangeOrder(null)"
      @clanel="onChangeOrder(null)"
      @ok="onOkOrder">
      <order-dettails 
        :order="selectedOrder"
        :machine-list="machineList"
        :spare="spareList"
        @deleteOrder="onDeleteOrder"
        
         />
        
    </b-modal>

    <div class="clearfix">
      <div class="float-left">
        <button class="btn btn-primary" @click="newOrder">Nuovo ordine <i class="fa fa-plus"></i></button>
      </div>
      <div class="float-right">
        <button class="btn btn-outline-primary" @click="downloadOrders">Download <i class="fa fa-clipboard-list"></i></button>
      </div>
      <div class="float-right" v-if="!showOrderArchived">
        <button class="btn btn-outline-primary" @click="showArchive">Mostra ricevuti <i class="fa fa-archive"></i></button>
      </div>
      <div class="float-right" v-if="showOrderArchived">
        <button class="btn btn-outline-primary" @click="showOpenOrder">Mostra ordini aperti <i class="fa fa-clipboard-list"></i></button>
      </div>
      
      

    </div>
    <order-list 
      @orderChanged="onChangeOrder"
      :list="orderList"
      :machine-list="machineList" />
  </div>
</template>

<script>
import OrderList from "@/components/order/OrderList.vue";
import OrderDettails from "@/components/order/OrderDettails.vue";

import { mapState } from "vuex";
import orderService from "../services/order";
const ExcelJS = require('exceljs');
import moment from 'moment';

export default {
  name: "OrderView",
  data() {
    return {
      selectedOrder: null,
      showOrderArchived: false
    };
  },
  mounted() {
    this.$store.dispatch("orderModule/loadOrders");
    //this.$store.dispatch("machineModule/loadMachines");
    this.$store.dispatch("spareModule/loadSpares");
  },
  computed: {
    ...mapState("orderModule", {
      orderList: function(state) {
        return state.orderList;
      }
    }),
    ...mapState("machineModule", {
      machineList: function(state) {
        return state.machineList;
      }
    }),
    ...mapState("spareModule", {
      spareList: function(state) {
        return state.spareList;
      }
    }),
    inModifyMode() {
      return !!(this.selectedOrder && this.selectedOrder._id)
    }
  },
  methods: {
    async downloadOrders() {
      const workbook = new ExcelJS.Workbook();
      workbook.creator = 'Kilab.eu System';
      workbook.lastModifiedBy = 'Download';
      workbook.created = new Date();
      workbook.modified = new Date();
      const sheet = workbook.addWorksheet('Oridini Ricambi');
      sheet.columns = [
        { header: 'Data', key: 'orderDate',  width: 28 },
        { header: 'Fornitore', key: 'supplier' , width: 25},
        { header: 'Note', key: 'notes' , width: 34},
        { header: 'Costo', key: 'price' , width: 20},
        { header: 'Stato', key: 'orderStatus' , width: 20 },
        { header: 'Macchinario', key: 'machineId' , width: 20 }
      ];

      this.orderList.forEach(el=> {
        let rowFormatted = [];
        rowFormatted.push(moment(el.orderDate).format("DD/MM/YYYY"))
        rowFormatted.push(el.supplier)
        rowFormatted.push(el.notes)
        rowFormatted.push(el.price)
        rowFormatted.push(el.orderStatus)
        rowFormatted.push(this.getMachineLabel(el.machineId)) 

        sheet.addRow(rowFormatted);
      })
      const buffer = await workbook.xlsx.writeBuffer();
      
      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'ordini-'+ moment().format("DD-MM-YYYY") +'.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();



    },
    getMachineLabel(mId) {
      let machineName = this.machineList.find(m => m._id == mId||'');
      if(!machineName) machineName = 'Non trovata';
      else machineName = machineName.name + ' (' + machineName.shortCode + ')'; 
      return machineName; 
    },
    async onOkOrder() {
      if(!this.selectedOrder)
        return;
      if(this.selectedOrder._id) {
        if(this.selectedOrder.supplier == '') {
          alert('Inserisci FORNITORE per salvare.');
          return;
        }
        //console.log('Request update');
        try{
          let resultUpdate = await orderService.updateOrder(this.selectedOrder);
          //console.log('This happened: ' + resultUpdate);
          if(resultUpdate.status !=200) {
            //console.log('Error!');
            this.$store.dispatch("appModule/addErrorMessage", resultUpdate.data, { root: true } )
          }  

        }catch (err) {
          this.$store.dispatch("appModule/addErrorMessage", JSON.stringify(err), { root: true } )
        } 
        
      } else {
        await orderService.createOrder(this.selectedOrder)
      }
      
      this.$store.dispatch("orderModule/loadOrders")
    },
    async onDeleteOrder(order) {
      //console.log('Deleting?');
      if(!this.selectedOrder || !this.selectedOrder._id || this.selectedOrder._id !== order._id)
        return;
      if(!confirm("Confermi di voler cancellare questa order")) {
        return;
      }
      await orderService.deleteOrder(this.selectedOrder._id)
      this.$store.dispatch("orderModule/loadOrders")
    },
    newOrder(){
        //this.onChangeOrder({})
        this.$store.dispatch("orderModule/newOrder")
    },
    
    onChangeOrder(order) {
      if(order) {
        this.selectedOrder = JSON.parse(JSON.stringify(order));        
        this.$refs['order-modal'].show()
      } else {       
        this.selectedOrder = null; 
        this.$refs['order-modal'].hide()
      }
    },
    showArchive() {
      this.$store.dispatch("orderModule/loadArchive");
      this.showOrderArchived = true;

    },
    showOpenOrder() {
      this.$store.dispatch("orderModule/loadOrders")
      this.showOrderArchived = false;

    }
  },
  components: {
    OrderList,
    OrderDettails
  }
};
</script>


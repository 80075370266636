import axios from "axios"
import global from "../../globalvar";

async function getDoneIntervations() {
    return (await axios.get(global.ApiBaseUrl +"/api/maintenance/intervention/maintenancedone", { 
        headers: {
            "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
        }
    }))
}
async function getArchivedIntervations() {
    return (await axios.get(global.ApiBaseUrl +"/api/maintenance/intervention/archived", { 
        headers: {
            "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
        }
    }))
}
async function getIntervationsByMachine(machineId) {
    return (await axios.get(global.ApiBaseUrl +"/api/maintenance/intervention/bymachine/" + machineId, { 
        headers: {
            "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
        }
    }))
}
async function getIntervationsAllOpen() {
    return (await axios.get(global.ApiBaseUrl +"/api/maintenance/intervention/allOpen", { 
        headers: {
            "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
        }
    }))
}
async function createIntervention(intervention) {
    const _intervention = JSON.parse(JSON.stringify(intervention));
    return (await axios.post(global.ApiBaseUrl +"/api/maintenance/intervention/create", _intervention, { 
        headers: {
            "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
        }
    }))
}
async function createPublicIntervention(tenant,intervention) {
    const _intervention = JSON.parse(JSON.stringify(intervention));
    return (await axios.post(global.ApiBaseUrl +"/api/public/form/" + tenant + "/create", _intervention, { 
    }))
}
async function updateIntervention(intervention) {
    const _intervention = JSON.parse(JSON.stringify(intervention));
    return (await axios.put(global.ApiBaseUrl +"/api/maintenance/intervention/" + intervention._id + "/edit", 
        _intervention, 
        { 
            headers: {
                "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
            }
        }
    ))
}
async function deleteIntervention(interventionId) {
    return (await axios.delete(global.ApiBaseUrl +"/api/maintenance/intervention/delete/" + interventionId,
        { 
            headers: {
                "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
            }
        }
    ))
}

export default {
    getDoneIntervations,
    getIntervationsAllOpen,
    getIntervationsByMachine,
    createIntervention,
    updateIntervention,
    deleteIntervention,
    createPublicIntervention,
    getArchivedIntervations
}
<template>
  <div class="maintenancePage">
    <!--<h1>Manutenzioni programmate</h1>-->

    <b-modal size="xl" ref="maintenance-modal" 
      :title="(inModifyMode ? 'Modifica' : 'Crea') + ' maintenance'"
      :ok-title="inModifyMode ? 'Modifica' : 'Crea'"
      @close="onChangeMaintenance(null)"
      @clanel="onChangeMaintenance(null)"
      @ok="onOkMaintenance">
      <maintenance-dettails 
        :maintenance="selectedMaintenance"
        :machine-list="machineList"
        @deleteMaintenance="onDeleteMaintenance"
        @addCheckListDef="onAddCheckListDef"
        @removeCheckListDef="onRemoveCheckListDef" />
    </b-modal>

    <div class="clearfix">
      <v-btn
        depressed
        color="primary"
        @click="newMaintenance"
      >
      Nuova manutenzione programmata <i class="fa fa-plus"></i>
      </v-btn>
    </div>
    <maintenance-list 
      @maintenanceChanged="onChangeMaintenance"
      :list="maintenanceList" 
      :machine-list="machineList"
    />
  </div>
</template>

<script>
import MaintenanceList from "@/components/maintenance/MaintenanceList.vue";
import MaintenanceDettails from "@/components/maintenance/MaintenanceDettails.vue";

import { mapState } from "vuex";
import maintenanceService from "../services/maintenance";

export default {
  name: "MaintenanceView",
  data() {
    return {
      selectedMaintenance: null
    };
  },
  mounted() {
    //this.$store.dispatch("maintenanceModule/loadMaintenances");
    //this.$store.dispatch("machineModule/loadMachines");
  },
  computed: {
    ...mapState("maintenanceModule", {
      maintenanceList: function(state) {
        return state.maintenanceList;
      }
    }),
    ...mapState("machineModule", {
      machineList: function(state) {
        return state.machineList;
      }
    }),
    inModifyMode() {
      return !!(this.selectedMaintenance && this.selectedMaintenance._id)
    }
  },
  methods: {
    async onOkMaintenance() {
      if(!this.selectedMaintenance)
        return;
      if(this.selectedMaintenance._id) {
        await maintenanceService.updateMaintenance(this.selectedMaintenance)
      } else {
        await maintenanceService.createMaintenance(this.selectedMaintenance)
      }
      
      this.$store.dispatch("maintenanceModule/loadMaintenances")
    },
    async onDeleteMaintenance(maintenance) {
      if(!this.selectedMaintenance || !this.selectedMaintenance._id || this.selectedMaintenance._id !== maintenance._id)
        return;
      if(!confirm("Confermi di voler cancellare questa maintenance")) {
        return;
      }
      await maintenanceService.deleteMaintenance(this.selectedMaintenance._id)
      this.$store.dispatch("maintenanceModule/loadMaintenances")
    },
    newMaintenance(){
        this.onChangeMaintenance({
            checkList:[]
        })
    },
    onAddCheckListDef() {
      if(!this.selectedMaintenance)
        return;
      this.selectedMaintenance.checkList = [...this.selectedMaintenance.checkList, { title:'', result: ''}];
    },
    onRemoveCheckListDef(index) {
      if(!this.selectedMaintenance)
        return;
      this.selectedMaintenance.checkList = this.selectedMaintenance.checkList.splice(index, 1)
    },
    onChangeMaintenance(maintenance) {
      if(maintenance) {
        this.selectedMaintenance = JSON.parse(JSON.stringify(maintenance));        
        this.$refs['maintenance-modal'].show()
      } else {       
        this.selectedMaintenance = null; 
        this.$refs['maintenance-modal'].hide()
      }
    }
  },
  components: {
    MaintenanceList,
    MaintenanceDettails
  }
};
</script>


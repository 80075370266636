<template>
  <div class="list-group">
    
    <vue-good-table
      :columns="tableColumns"
      :rows="maintenanceListFormatted"
      styleClass="vgt-table striped condensed"
      :search-options="{
        enabled: true}">
      <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'">
            <b-button @click="changeSelected(props.row)">Modifica</b-button>
          </span>
          <!--<span v-else-if="props.column.field == 'machineId'">
            {{getMachineLabel(props.row.machineId)}}
          </span>-->
        </template>
    </vue-good-table>
  </div>
</template>

<script>
export default {
  name: "MaintenanceList",
  props: {
    list: { type: Array, default: () => [] },
    machineList: { type: Array, default: () => [] }
  },
  data() {
    return {
      tableColumns: [{
          label: '',
          field: 'actions',
          
        },{
          label: 'Macchinario',
          field: 'machineName',
        },{
          label: 'Codice',
          field: 'shortCode',
        }, {
          label: 'Descrizione',
          field: 'description',
        }, {
          label: 'Frequenza man.(gg)',
          field: 'triggeredByDays',
        }
      ]
    }
  },
  computed: {
    maintenanceListFormatted(){
      return this.list.map( elem => {
        return {
          ...elem,
          machineName: this.getMachineLabel(elem.machineId)
        }
      })
    }
  },
  methods: {
    changeSelected(maintenance) {
      //console.log('Maintenenca select: ',maintenance);
      this.$emit("maintenanceChanged", maintenance);
    },
    getMachineLabel(mId) {
      let machineName = this.machineList.find(m => m._id == mId);
      if(!machineName) machineName = 'Non trovata';
      else machineName = machineName.name + ' (' + machineName.shortCode + ')'; 
      //console.log(machineName);
      return machineName; 
    }
  }
};
</script>
<template>
  <v-app>
    <!--<app-messages />-->
    <app-header />
    <app-menu />
    <v-main>
      <v-container >
        <router-view name='main'/>
      </v-container>
    </v-main>
    <v-footer app>
      Tutti i diritti sono riservati © COPYRIGHT 2022 KILAB - Aneggi Alessandro
    </v-footer>
  </v-app>
</template>

<script>
import AppMenu from "@/components/layout/appMenu.vue";
//import AppMessages from "@/components/layout/appMessages.vue";
import AppHeader from "@/components/layout/appHeader.vue";

export default {
  components: {
    //AppMessages,
    AppMenu,
    AppHeader,
  },
  mounted() {
    this.$store.dispatch("appModule/startFetching", null, { root: true });
    this.authInfo = this.$store.getters["appModule/authInfo"];
    if (this.authInfo) {
      this.$store.dispatch("interventionModule/loadInterventions");
      this.$store.dispatch("siteModule/loadSites");
      this.$store.dispatch("machineModule/loadMachines");
      this.$store.dispatch("maintenanceModule/loadMaintenances");
      this.$store.dispatch("userModule/loadUsers");
    }
    this.$store.dispatch("appModule/endFetching", null, { root: true })
  },
  //name: 'App',
  errorCaptured(err, vm, info) {
    window["console"]["log"]("errorCaptured: ", err, vm, info);
    throw err;
  },

  data () { return {
    drawer: true,
    name: 'App-Maintenance',   
    menuApps: [
      { title: 'Maintenance', icon: 'mdi-home-city' },
      { title: 'Visitor', icon: 'mdi-account' },
      { title: 'SmartGate', icon: 'mdi-account-group-outline' },
      
    ],
    mini: true,
      
        
  }
    //
  }
};
</script>

<template>
  <div class="interventionPage">
    <!--<h1>Interventi/rapporti manutenzione</h1>-->

    <b-modal ref="intervention-modal" :title="(inModifyMode ? 'Modifica' : 'Crea') + ' rapporto di intervento'" size="lg"
      :ok-title="inModifyMode ? 'Modifica' : 'Crea'"
      @close="onChangeIntervention(null)"
      @clanel="onChangeIntervention(null)"
      @ok="onOkIntervention">
      <intervention-dettails :intervention="selectedIntervention"
        :machine-list="machineList"
        @interventionChange="onChangeIntervention"
        @addInterventionMessage="onAddInterventionMessage"
        @deleteIntervention="onDeleteIntervention"
        @addCustomData="onAddCustomData"
        @removeCustomData="onRemoveCustomData" />
    </b-modal>

    <div class="clearfix">

      
      <v-btn
        depressed
        color="primary"
        @click="newIntervention"
      >
        Nuovo intervento <i class="fa fa-plus"></i>
      </v-btn>

      
      <div class="float-right" v-if="!showInterventionArchived">
        <button class="btn btn-outline-primary" @click="showArchive">Mostra archiviati <i class="fa fa-archive"></i></button>
      </div>
      <div class="float-right" v-if="showInterventionArchived">
        <button class="btn btn-outline-primary" @click="showOpenIntervention">Mostra interventi aperti <i class="fa fa-clipboard-list"></i></button>
      </div>
    </div>
    <intervention-list 
      @interventionChanged="onChangeIntervention"
      :list="interventionList"
      :machine-list="machineList" />
  </div>
</template>

<script>
import InterventionList from "@/components/intervention/InterventionList.vue";
import InterventionDettails from "@/components/intervention/InterventionDettails.vue";

import { mapState } from "vuex";
import interventionService from "../services/intervention";

export default {
  name: "InterventionView",
  data() {
    return {
      selectedIntervention: null,
      showInterventionArchived: false,
    };
  },
  mounted() {
    //this.$store.dispatch("interventionModule/loadInterventions");
    //this.$store.dispatch("machineModule/loadMachines");
    if(this.$route.query.new ==0 && this.$route.query.interventionId) { 
      //console.log('Let s go:' + this.$route.query.interventionId);
      //let interv = this.interventionList.find(inter => inter._id == this.$route.query.interventionId);
      //console.log('Intervention:' + interv );
    }
    if(this.$route.query.new ==1 && this.$route.query.machineid) {
      //console.log('Parameters:', this.$route.query );
      let newInt = {
         "status": "OPEN",
         "type": "PROGRAMMATA",
         "startDate": new Date().toISOString(),
         "endDate": new Date().toISOString(),
        "scheduledMaintenance": {
            "checkList": []
        },
        "notes":[]
      }
      if(this.$route.query.new ==1) {
        if(this.$route.query.machineid)
          newInt.machineId = this.$route.query.machineid;
        if(this.$route.query.maintenanceid)
          newInt.maintenanceId = this.$route.query.maintenanceid;  
      }
      this.selectedIntervention = JSON.parse(JSON.stringify(newInt));        
      this.$refs['intervention-modal'].show()
    }

  },
  computed: {
    ...mapState("interventionModule", {
      interventionList: function(state) {
        return state.interventionList;
      },
      

    }),
    ...mapState("machineModule", {
      machineList: function(state) {
        return state.machineList;
      }
    }),
    inModifyMode() {
      return !!(this.selectedIntervention && this.selectedIntervention._id)
    },
    authInfo() {
      return this.$store.getters["appModule/authInfo"];
    }
  },
  methods: {
    async onOkIntervention() {
      if(!this.selectedIntervention)
        return;
      if(this.selectedIntervention._id) {
        await interventionService.updateIntervention(this.selectedIntervention)
      } else {
        await interventionService.createIntervention(this.selectedIntervention)
      }
      
      this.$store.dispatch("interventionModule/loadInterventions")
    },
    async onDeleteIntervention(intervention) {
      if(!this.selectedIntervention || !this.selectedIntervention._id || this.selectedIntervention._id !== intervention._id)
        return;
      if(!confirm("Confermi di voler cancellare questa intervention")) {
        return;
      }
      await interventionService.deleteIntervention(this.selectedIntervention._id)
      this.$store.dispatch("interventionModule/loadInterventions")
    },
    newIntervention() {
      let newInt = {
         "status": "OPEN",
         "type": "RICHIESTA",
         "startDate": new Date().toISOString(),
         "endDate": new Date().toISOString(),
        "scheduledMaintenance": {
            "checkList": []
        },
        "notes":[]
      }
      this.onChangeIntervention(newInt);
    },
    onAddInterventionMessage(message) {
      this.selectedIntervention.notes = [
        ...this.selectedIntervention.notes,
        {
          date: new Date(),
          text: message,
          user: this.authInfo.fullName
        }
      ]
    },
    showArchive() {
      //console.log('request-archive');
      this.$store.dispatch("interventionModule/loadArchive");
      this.showInterventionArchived = true;

    },
    showOpenIntervention() {
      //console.log('request-archive');
      this.$store.dispatch("interventionModule/loadInterventions");
      this.showInterventionArchived = false;

    },
    onAddCustomData() {
      if(!this.selectedIntervention)
        return;
      this.selectedIntervention.customData = [...this.selectedIntervention.customData, { name:'', value: ''}];
    },
    onRemoveCustomData(index) {
      if(!this.selectedIntervention)
        return;
      this.selectedIntervention.customData = this.selectedIntervention.customData.splice(index, 1)
    },
    onChangeIntervention(intervention) {
      if(intervention) {
        intervention.isActive = intervention.isActive === undefined ? false : intervention.isActive;
        this.selectedIntervention = JSON.parse(JSON.stringify(intervention));        
        this.$refs['intervention-modal'].show()
      } else {       
        this.selectedIntervention = null; 
        this.$refs['intervention-modal'].hide()
      }
    }
  },
  components: {
    InterventionList,
    InterventionDettails
  }
};
</script>


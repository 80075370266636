<template>
  <div class="about">
    <div class="clearfix">
      <div class="float-right">
        <b-form-datepicker
          class="w-100"
          v-model="endDate"
          type="date"
          name="end-date"
          id="end-date"
          locale="en"
        ></b-form-datepicker>
      </div>
      <div class="float-right">
        <b-form-datepicker
          class="w-100"
          v-model="startDate"
          type="date"
          name="start-date"
          id="start-date"
          locale="en"
        ></b-form-datepicker>
      </div>
      <div class="float-right">
        <button class="btn btn-primary mr-2" @click="getEventsData">
          Apply
        </button>
      </div>
    </div>
    <div
      class="mt-3"
      v-if="Object.keys(eventData).length && !eventDataApiLoading"
    >
      <EventContainer v-for="(event,key) in eventData" :key="key" :eventData="event" :dataKey="key" />
    </div>
    <div v-else-if="!eventData.length && !eventDataApiLoading">
      No Data Found.
    </div>
    <br>
    <b-button v-b-toggle.collapse-dev class="m-1">**DEV**</b-button>
    <b-collapse id="collapse-dev">
      <b-card>
        <pre>
          {{tempEvents}} 
        </pre>
        <hr/>
        <pre>
          {{eventData}}
        </pre>
      </b-card>
    </b-collapse>
    
    
  </div>
</template>


<script>
import eventService from "../services/events";
import EventContainer from '../components/events/EventContainer.vue';
import { mapState } from "vuex";
export default {
  name: "EventView",
  components: {
    EventContainer
  },
  data() {
    return {
      startDate: new Date(new Date().setDate(new Date().getDate() - 10)),
      endDate: new Date(),
      eventData: [],
      eventDataApiLoading: false,
      tempEvents : null
    };
  },

  methods: {
    async getEventsData() {
      try {
        this.$store.commit("appModule/startFetching", null);
        this.eventDataApiLoading = true;
        const startDate = this.$moment(this.startDate).format("DDMMYYYY");
        const endDate = this.$moment(this.endDate).format("DDMMYYYY");
        const { data } = await eventService.getEvents(startDate, endDate);
        this.tempEvents = data;
        const eventsData = {};
        const start = 0;
        const end = data.length;

        // Create uniquie dictionary of objects
        let objList =  this.machineList.map( elem => {
              return {
                _id: elem._id.toString(),
                text: elem.name + ' (' + elem.shortCode + ')'
              }
          })
          console.log(objList.length)
          objList = objList.concat( this.interventionList.map( elem => {
              return {
                _id: elem._id.toString(),
                text: "Rich.Desc:" + elem.issueDescription + ' STATO(' + elem.status + ')'
              }
            })
          )
          console.log(objList.length)
          objList = objList.concat( this.siteList.map( elem => {
              return {
                _id: elem._id.toString(),
                text: elem.name + ' (' + elem.city + ')'
              }
            })
          )
          console.log(objList.length)

        // CICLO PER TUtTI I DATI
        for (let i = start; i < end; i += 1) {
          const event = data[i];
          const date = this.$moment(event.timestamp).format("DD/MM/YYYY");
          if (!eventsData[date]) {
            eventsData[date] = {};
          }
          if (!eventsData[date][event.action]) {
            eventsData[date][event.action] = [];
          }
          // Use for change IDs

          let result = objList.find(obj => {
            return obj._id === event.object
          }) 
          if(result) event.object = result.text 
          result = null
          result = objList.find(obj => {
            return obj._id === event.to
          }) 
          if(result) event.to = result.text 
          result = null
          result = objList.find(obj => {
            return obj._id === event.from
          }) 
          if(result) event.from = result.text 

           
          eventsData[date][event.action].push(event);
        }
        this.eventData = eventsData;
        

      } catch (error) {
        console.error(error, "error in loading events data");
      } finally {
        this.eventDataApiLoading = false;
        this.$store.commit("appModule/endFetching", null);
      }
    },
  },

  mounted() {
    this.getEventsData();
  },
  computed: {
    ...mapState("machineModule", {
      machineList: function(state) {
        return state.machineList;
      }
    }),
    ...mapState("siteModule", {
      siteList: function(state) {
        return state.siteList;
      }
    }),
    ...mapState("interventionModule", {
      interventionList: function(state) {
        return state.interventionList;
      },
    }),
  }
};
</script>
<template>
  <v-app-bar app
      color="deep-purple accent-4"
      dense
      dark
    >
      <!--<v-app-bar-nav-icon></v-app-bar-nav-icon>-->
      <v-toolbar-title>Maintenance ({{authInfo?.fullName}})</v-toolbar-title>
      
      <v-menu left offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon to="/configuration">mdi-dots-vertical</v-icon> 
          </v-btn>
          
        </template>
        
        
        <v-list >

          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-toolbox</v-icon>
            </v-list-item-icon>
            <v-list-item-title >Manutenzioni</v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-title >Visitatori</v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-stamper</v-icon>
            </v-list-item-icon>
            <v-list-item-title >Gestione collaboratori</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      
      <!--<v-menu left offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-chevrond-down</v-icon><b>Maintenance ({{authInfo?.fullName}})</b>
          </v-btn>
          
        </template>
        <v-list>

          <v-list-item >
            
            <v-list-item-title >Maintenance</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    -->
       
      <!--<v-spacer></v-spacer>

      <v-btn rounded color="success" dark>
        OK
        <v-icon dark right>
          mdi-checkbox-marked-circle
        </v-icon>
      </v-btn>
-->
      <!--<v-progress-circular indeterminate color="amber"></v-progress-circular>
      <v-progress-linear
      rounded
      height="6"
      indeterminate
      color="yellow darken-2"
      ></v-progress-linear>



      <v-btn rounded color="error" dark>
        Error<v-icon dark right>
          mdi-cancel
        </v-icon>
      </v-btn>-->
    <v-spacer></v-spacer>

      <v-menu left offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-bell-alert-outline</v-icon>
          </v-btn>
        </template>
        <v-list>
          Notifice in sviluppo
        </v-list>
      </v-menu>
      <!--<v-btn icon>
        <v-icon>mdi-account</v-icon> 
      </v-btn>-->


      
      <v-menu left offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </template>
        <v-list>


          <v-list-item link>
            <v-list-item-avatar>
            <v-img src="https://randomuser.me/api/portraits/lego/1.jpg"></v-img>
            </v-list-item-avatar>
            <v-list-item-title> {{authInfo?.fullName}}</v-list-item-title>
          </v-list-item>
          <v-divider inset></v-divider>
          <v-list-item href="https://docs.google.com/forms/d/e/1FAIpQLSenZx5q9X5NeBv5OqDzQDkPrXSTyygncm7JYdALVJOyjeq_CQ/viewform">
            <v-list-item-icon>
              <v-icon>mdi-cloud-question</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Richiedi assistenza</v-list-item-title>
          </v-list-item>

          <v-list-item href="http://192.168.8.252:4005/info" target="_blank">
            <v-list-item-icon>
              <v-icon>mdi-chart-areaspline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Dati Tempo Reale</v-list-item-title>
          </v-list-item>

          <v-list-item to="/configuration">
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title >Configurazione</v-list-item-title>
          </v-list-item>

          <v-list-item @click="logout()">
            <v-list-item-icon>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-icon>
            <v-list-item-title @click="logout()">Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
  </v-app-bar>
</template>

<script>

export default {
  name: "appHeader",
  mounted() {
    this.authInfo = this.$store.getters["appModule/authInfo"]; 
    
   },
  methods: {
    toggleAside() {
      this.$store.commit("appModule/toggleAside");
    },
    logout() {
      this.$store.dispatch('appModule/setAuthInfo', null, {root:true})
      this.$router.push('/')
    },
    openRealTimeData() {
      const url = 'http://localhost:4004';
      const windowFeatures = 'width=800,height=600,toolbar=no,location=no,menubar=no';
      window.open(url, '_blank', windowFeatures);
    }
  },
  computed: {
    authInfo() {
      return this.$store.getters["appModule/authInfo"];
    },
  },
};
</script>
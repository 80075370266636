<template>
  <div class="conatiner-fluid" v-if="machine">
    
    <v-form v-model="valid" ref="form" lazy-validation>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="machine.shortCode"
            :rules="validationRules.shortCode"
            :counter="8"
            label="Codice breve"
            required
            filled
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="machine.name"
            
            label="Nome"
            required
            filled
          ></v-text-field>
        </v-col>

        
      </v-row>



    </v-container>

    <v-tabs>
      <v-tab key="base">Base</v-tab>
      <v-tab key="avanz">Avanzati</v-tab>
      <v-tab key="custom">Personalizzati</v-tab>
      <v-tab-item key="base">
        <v-text-field
            v-model="machine.manufacture"
            label="Produttore"
            required
            filled
          ></v-text-field>
          <v-text-field
            v-model="machine.serial"
            label="Numero Serie"
            required
            filled
          ></v-text-field>
          <v-text-field
            v-model="machine.model"
            label="Modello"
            required
            filled
          ></v-text-field>

          <v-select
            v-model="machine.tags"
            :items="tagslist"
            attach
            chips
            deletable-chips
            label="Etichette"
            multiple
          ></v-select>

          <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="machine.installDate"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="machine.installDate"
            label="Data di installazione"
            prepend-icon="mdi-calendar"
            hint="MM/DD/YYYY format"
            
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="machine.installDate"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(machine.installDate)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>

      </v-tab-item>
      <v-tab-item key="avanz">
        <v-autocomplete
            v-model="machine.site"
            :items="siteListFormatted"
            dense
            filled
            label="Luogo"
          ></v-autocomplete>
          <v-autocomplete
            v-model="machine.customer"
            :items="customerListFormatted"
            dense
            filled
            label="Cliente"
          ></v-autocomplete>
          <b-row>
                          <b-col>
                            
                            
                            <!--<div class="form-group">
                                <label>Fornitore</label>
                                <input type="text" class="form-control" v-model="machine.supplier" disabled/>
                            </div>-->
                            
                            <div class="form-group">
                              <label>Proprietario oggetto (utente KiLab)</label>
                              <b-form-select v-model="machine.owner" :options="userList" value-field="id" text-field="name">
                                <b-form-select-option :value="null">Orfano...</b-form-select-option>
                              </b-form-select>
                              <!--<input type="text" class="form-control" v-model="machine.owner" /> -->
                          </div>
                          </b-col>
                        </b-row>
     
                        <b-row>
                          <b-col>

                            <div class="form-group">
                                <label>Ultima modifica </label>
                                <!--<input type="text" class="form-control" v-model="intervention.endDate" />-->
                                <b-form-datepicker id="example-datepicker-end34" v-model="machine.lastUpdate" class="mb-2"></b-form-datepicker>
                            </div>

                            <v-text-field
                              v-model="machine.manualLink"
                              label="Link ai manuali"
                              required
                              filled
                            ></v-text-field>
                            
                          </b-col>
                          <b-col>
                          
                      <!--    <div class="form-group">
                              <label>Gruppo</label>
                              <input type="text" class="form-control" v-model="machine.groupName" />
                          </div>
                      <div class="form-group">
                              <label>Reparto</label>
                              <input type="text" class="form-control" v-model="machine.department" />
                          </div>-->
                          <v-checkbox v-model="machine.ceMark" :label="`Marcatura CE `"></v-checkbox>
                          <v-checkbox v-model="machine.therIsManual" :label="`Presenza manuale `"></v-checkbox>
                          <v-checkbox v-model="machine.conformCertificate" :label="`Certificato di conformaità presente `"></v-checkbox>

                        
                          </b-col>
                    </b-row>


      </v-tab-item>
      <v-tab-item key="custom">

        <div class="list-group" v-if="machine.customData && machine.customData.length > 0">
                      <div class="list-group-item" >
        
        
                        <v-row v-for="(item, index) in machine.customData" :key="item._id">

          <v-col
          cols="12"
          md="5"
        >
          <v-text-field
            v-model="item.name"
            label="Etichetta"
            required
            filled
          ></v-text-field>
          </v-col>
          <v-col
          cols="12"
          md="5"
        >
          <v-text-field
            v-model="item.value"
            label="Valore"
            required
            filled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn class="mx-2" fab dark small color="indigo" @click="removeCustomData(index)">
                  <v-icon dark>
                    mdi-minus
                  </v-icon>
                </v-btn>
        </v-col>

    
      </v-row>
      <v-row>
        <v-btn class="mx-2" fab dark color="indigo" @click="addCustomData">
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
        
      </v-row>
                       
                      </div>
                  </div> 

      </v-tab-item>
    </v-tabs>


    

  </v-form>
  

  



  



    <div class="row">
        
        <div class="form-group">
          <input hidden type="checkbox" v-model="machine.isActive" />&nbsp;<label hidden>Attiva </label>
        </div>
    </div>
      
      
      <div class="float-right" v-if="machine._id">
        <button class="btn btn-outline-danger"   @click="deleteMachine" type="button">ELIMINA MACCHINARIO &times;</button>
        <!--:disabled="invalid"-->
      </div>
  </div>
  
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { mapState } from "vuex"; //, mapGetters
export default {
  name: "MachineDettails",
  props: {
    machine: { type: Object, default: () => null },
    customerList: { type: Array, default: () => [] },
    siteList: { type: Array, default: () => [] }
  },
  components: {
    vSelect
  },
  computed: {
    customerListFormatted(){
      let data = this.customerList.map( elem => {
        return {
          value: elem._id.toString(),
          text: elem.name + ' (' + elem.code + ')', //elem.machineId
        }
      })
      //console.log("Oggetto:" + JSON.stringify(data));
      return data;
    },
    siteListFormatted(){
      let data = this.siteList.map( elem => {
        return {
          value: elem._id.toString(),
          text: elem.name + ' (' + elem.code + ')', //elem.machineId
        }
      })
      return data;
    },
    ...mapState("userModule", {
      userList: function(state) {
        return state.userList;
      }
    }),
    
    
  },
  data() {
    return {
      tagslist: ["Non verificato","Provvisorio","Privato"],
      geolocationPresent : false,
      valid: true,
      menu: false,
      validationRules: {
        shortCode:[
          v => !!v || 'Name is required',
          v => v.length > 3 || 'Name must be min 4',
          v => v.length < 9 || 'Name must be max 8',
        ]
      },
      items: [
          'web', 'shopping', 'videos', 'images', 'news',
        ],
    }
  },
  methods: {
    parseDate (date) {
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    validate () {
        this.$refs.form.validate()
      },
    addCustomData() {
      this.$emit("addCustomData", {});
    },
    removeCustomData(index) {
      this.$emit("removeCustomData", index);
    },
    deleteMachine() {
      this.$emit("deleteMachine", this.machine);
    },
    getLocationNow() {
      var options = {
          enableHighAccuracy: false,
          timeout: 3000,
          maximumAge: 0
        };
      this.$getLocation(options).then(coordinates => {
          console.log(coordinates);
      });
    }
    
  }

};
</script>
import axios from "axios"
import global from "../../globalvar";

async function getProductionStatFromTo(from, to ) {
    return (await axios.get(global.ApiBaseUrl +"/api/industrialdatalog/days/" + from + "/"+ to, {
        headers: {
            "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
        }
    }))
}



export default {
    getProductionStatFromTo,
    
}
<template>
  <div class="list-group">
    

    <!--
    <v-data-table
    :headers="headers"
    :items="list"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>My CRUD</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              New Item
            </v-btn>
          </template>
          </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
        disabled
      >
        mdi-delete
      </v-icon>-->
    <!--</template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn>
    </template>
  </v-data-table>-->



    <vue-good-table
      :columns="tableColumns"
      :rows="list"
      styleClass="vgt-table striped condensed"
      :search-options="{
        enabled: true}">
      <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'">
            <!--<b-button @click="changeSelected(props.row)">Vedi macchinari</b-button>-->
            <b-button @click="changeSelected(props.row)">Modifica</b-button>
          </span>
          
          <!--<span v-else-if="props.column.field == 'machineId'">
            {{getMachineLabel(props.row.machineId)}}
          </span>-->
          <!--<span v-else-if="props.column.field == 'orderStatus'">
            {{getStatusLabel(props.row.orderStatus)}}
          </span>-->
        </template>
    </vue-good-table>
  </div>
</template>

<script>
export default {
  name: "SiteList",
  props: {
    list: { type: Array, default: () => [] },
    siteList: { type: Array, default: () => [] }
  },
  data() {
    return {
      headers: [
        {
          text: 'Nome',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Indirizzo', value: 'address' },
        { text: 'Citta', value: 'city' },
        { text: 'Codice', value: 'code' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      tableColumns: [{
          label: 'Nome',
          field: 'name',
        },{
          label: 'Indirizzo',
          field: 'address',
        },{
          label: 'Citta',
          field: 'city',
        },{
          label: 'Codice',
          field: 'code',
        },{
          label: '',
          field: 'actions',
        }/*, {
          label: 'Email',
          field: 'contact',
        }, {
        //  label: 'Data ordine',
        //  field: 'orderDate',
        },  {
          label: 'Note',
          field: 'notes',
        }*/
      ]
    }
  },
  mounted() {
  

  },
  computed: {
   

  },
  methods: {
    changeSelected(site) {
      //console.log('Spare select: ',spare);
      this.$emit("siteChanged", site);
    },
    getMachineLabel(mId) {
      let machineName = this.machineList.find(m => m._id == mId);
      if(!machineName) machineName = 'Non trovata';
      else machineName = machineName.name + ' (' + machineName.shortCode + ')'; 
      //console.log(machineName);
      return machineName;
      
    },
    getStatusLabel(thisStatus){
      if(this.orderStatusList[thisStatus])
        return  this.orderStatusList[thisStatus];
      else 
        return 'Sconosciuto';
    }
  }
};
</script>
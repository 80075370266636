<template>
  <div class="list-group">
    
    <vue-good-table
      :columns="tableColumns"
      :rows="list"
      styleClass="vgt-table striped condensed"
      :search-options="{
        enabled: true}">
      <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'">
            <b-button @click="changeSelected(props.row)">Vedi macchinari</b-button>

          </span>
          <!--<span v-else-if="props.column.field == 'machineId'">
            {{getMachineLabel(props.row.machineId)}}
          </span>-->
          <!--<span v-else-if="props.column.field == 'orderStatus'">
            {{getStatusLabel(props.row.orderStatus)}}
          </span>-->
        </template>
    </vue-good-table>
  </div>
</template>

<script>
export default {
  name: "CustomerList",
  props: {
    list: { type: Array, default: () => [] },
    customerList: { type: Array, default: () => [] }
  },
  data() {
    return {
      tableColumns: [{
          label: 'Cliente',
          field: 'name',
        },{
          label: 'Codice',
          field: 'code',
        },{
          label: 'Città',
          field: 'address.city',
        },{
          label: '',
          field: 'actions',
        }/*, {
          label: 'Email',
          field: 'contact',
        }, {
        //  label: 'Data ordine',
        //  field: 'orderDate',
        },  {
          label: 'Note',
          field: 'notes',
        }*/
      ]
    }
  },
  mounted() {
  

  },
  computed: {
   

  },
  methods: {
    changeSelected(spare) {
      //console.log('Spare select: ',spare);
      this.$emit("spareChanged", spare);
    },
    getMachineLabel(mId) {
      let machineName = this.machineList.find(m => m._id == mId);
      if(!machineName) machineName = 'Non trovata';
      else machineName = machineName.name + ' (' + machineName.shortCode + ')'; 
      //console.log(machineName);
      return machineName;
      
    },
    getStatusLabel(thisStatus){
      if(this.orderStatusList[thisStatus])
        return  this.orderStatusList[thisStatus];
      else 
        return 'Sconosciuto';
    }
  }
};
</script>
import axios from "axios"
import global from "../../globalvar";

async function getAllSite() {
    return (await axios.get(global.ApiBaseUrl +"/api/site/all", {
        headers: {
            "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
        }
    }));
}
async function getSiteByMachine(machineId) {
    return (await axios.get(global.ApiBaseUrl +"/api/site/bymachine/" + machineId, { 
        headers: {
            "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
        }
    }));
}
async function createSite(site) {
    const _site = JSON.parse(JSON.stringify(site));
    return (await axios.post(global.ApiBaseUrl +"/api/site/create", _site, { 
        headers: {
            "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
        }
    }));
}
async function updateSite(site) {
    const _site = JSON.parse(JSON.stringify(site));
    return (await axios.put(global.ApiBaseUrl +"/api/site/" + site._id + "/edit", 
        _site, 
        { 
            headers: {
                "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
            }
        }
    ))
}
async function deleteSite(siteId) {
    return (await axios.delete(global.ApiBaseUrl +"/api/site/delete/" + siteId,
        { 
            headers: {
                "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
            }
        }
    ))
}


export default {
    getAllSite,
    getSiteByMachine,
    createSite,
    updateSite,
    deleteSite
}
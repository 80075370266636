import axios from "axios"
import global from "../../globalvar";

async function getMachine(machineId) {
    return (await axios.get(global.ApiBaseUrl +"/api/maintenance/machine/single/" + machineId, { 
        headers: {
            "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
        }
    }))
}
async function getPublicMachines(TCODE) {
    return (await axios.get(global.ApiBaseUrl +"/api/public/machine/" + TCODE + "/all", { 
        
    }))
}
async function createMachine(machine) {
    const _machine = JSON.parse(JSON.stringify(machine));
    return (await axios.post(global.ApiBaseUrl +"/api/maintenance/machine/create", _machine, { 
        headers: {
            "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
        }
    }))
}
async function updateMachine(machine) {
    const _machine = JSON.parse(JSON.stringify(machine));
    return (await axios.put(global.ApiBaseUrl +"/api/maintenance/machine/" + machine._id + "/edit", 
        _machine, 
        { 
            headers: {
                "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
            }
        }
    ))
}
async function deleteMachine(machineId) {
    return (await axios.delete(global.ApiBaseUrl +"/api/maintenance/machine/delete/" + machineId,
        { 
            headers: {
                "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
            }
        }
    ))
}

export default {
    getMachine,
    createMachine,
    updateMachine,
    deleteMachine,
    getPublicMachines
}
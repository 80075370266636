import axios from "axios"
import global from "../../globalvar";

async function getAllOrder() {
    return (await axios.get(global.ApiBaseUrl +"/api/maintenance/order/all", {
        headers: {
            "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
        }
    }))
}
async function getOrdersByMachine(machineId) {
    return (await axios.get(global.ApiBaseUrl +"/api/maintenance/order/bymachine/" + machineId, { 
        headers: {
            "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
        }
    }))
}
async function createOrder(order) {
    const _order = JSON.parse(JSON.stringify(order));
    return (await axios.post(global.ApiBaseUrl +"/api/maintenance/order/create", _order, { 
        headers: {
            "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
        }
    }))
}
async function updateOrder(order) {
    delete order.__v;
    delete order.vgt_id;
    delete order.originalIndex;
    delete order.machineName;
    const _order = JSON.parse(JSON.stringify(order));
    try{
        const result = await axios.put(global.ApiBaseUrl +"/api/maintenance/order/" + order._id + "/edit", _order, 
            { 
                headers: {
                    "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
                }
            }
        );
        return result;

    }catch(err){
        return err;
    }

    /*return (await axios.put(global.ApiBaseUrl +"/api/maintenance/order/" + order._id + "/edit", 
        _order, 
        { 
            headers: {
                "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
            }
        }
    ))*/
}
async function deleteOrder(orderId) {
    return (await axios.delete(global.ApiBaseUrl +"/api/maintenance/order/delete/" + orderId,
        { 
            headers: {
                "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
            }
        }
    ))
}


export default {
    getAllOrder,
    getOrdersByMachine,
    createOrder,
    updateOrder,
    deleteOrder
}
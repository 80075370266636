<template>
  <div class="homePage">
    <v-row>
      <b-col>
        <!-- {{tenantData?.services?.maintenance?.config}}-->
          <v-alert
            outlined
            color="green"
          >
            <div class="text-h6">
              CARICA e VISUALIZZA (NON SI POSSONO VEDERE AL MOMENTO I DATI DEL GIORNO ODIERNO)
            </div>
            <div>Seleziona anzitutto il giorno desiderato, poi premi su carica, infine seleziona quali valori vuoi visualizzare nel grafico dal menu a tendina, puoi vedere piu dati assieme. I dati che vedi sono al momento rispetto all orario internazionale GMT. Quindi dovrai aggiungere 1 ora. I dati sono la media dei valori raccolti in un intervallo di 15 minuti.</div>
          </v-alert>
      </b-col>
    </v-row>
    <v-row>
      <b-col>
              <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Picker without buttons"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                @input="menu2 = false"
                @change="changeDate()"
              ></v-date-picker>
            </v-menu>
      </b-col>
      <b-col>
        <b-button variant="primary" @click="getData()">Carica dati
          <v-chip v-if="chartData.length>0"
            class="ma-2"
            color="white"
            outlined
            :loading="loading"
            :disabled="loading"
          >
            {{ chartData.length }}
            
          </v-chip>
        </b-button>
        <!--<b-badge v-if="chartData.length>0" variant="secondary">Dati presenti:{{ chartData.length }}</b-badge>-->
      </b-col>
      <b-col>

        <b-button :disabled="chartData.length<1" variant="primary" @click="downloadData()">
          Download
          
        </b-button>
      </b-col>

    </v-row>
    <v-row>


      <b-col>

          
        
          <v-select
            v-model="dataForChart"
            :items="chartData"
            item-text="name"
            item-value="dataCode"
            filled
            chips
            label="Dati visualizzabili"
            multiple
            @change="changeChartData()"
          ></v-select>

      </b-col>
    </v-row>
    <v-row>
      <b-col>
        <v-row>
          <!--<v-col v-for="(item, index) in chartData" :key="index" cols="12" md="3" lg="2">
            <v-row>
              <v-col cols="3">
                <v-checkbox dense
                  v-model="item.selected"
                  :label="item.name"
                  @change="changeChartSelection(index)"
                  class="shrink"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="1">
                <v-checkbox
                  v-model="item.selected"
                  :label="''"
                ></v-checkbox>
              </v-col>
              <v-col>{{ item }}</v-col>
            </v-row>
          </v-col>-->
        </v-row>
        
      </b-col>

   



    </v-row>

   
   
    <div>
      <apexchart width="1000" type="area" :options="chartNew" :series="chartDataShow"></apexchart>
  </div>
  <!--<div>
      <apexchart width="800" type="bar" :options="chartNew" :series="chartData1"></apexchart>
  </div>-->
    
   
    

  </div>
 
</template>

<script>
//import serviceIntervention from "../services/intervention"
import serviceStat from "../services/stat"
import VueApexCharts from 'vue-apexcharts'
const ExcelJS = require('exceljs');

import moment from 'moment';
//import { mapState } from "vuex";
//import axios from "axios"

export default {
  
  
  data() {
    return {
      loader: null,
      loading: false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu2: false,
      dataForChart: [],
      rawDataStat: [],

      chartData1: [
          {
            name: "High - 2013",
            data: [28, 29, 33, 36, 32, 32, 33]
          },
          {
            name: "Low - 2013",
            data: [12, 11, 14, 18, 17, 13, 13]
          }
        ],
      chart1 : {
          chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#77B6EA', '#545454'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: 'Average High & Low Temperature',
          align: 'left'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
          title: {
            text: 'Month'
          }
        },
        yaxis: {
          title: {
            text: 'Temperature'
          },
          min: 5,
          max: 40
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      },

      chartNew : {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'Data of the day',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          tickPlacement: 'on',
          tickAmount: 24, //mostra solo 24 di quelli sotto nelle x
          categories : [
            '00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30','04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30',
            '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30','12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30',
            '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30','20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'
          ]
              
        }
        
      },

      chartOptions: {
        chart: {
          id: "basic-bar",
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
        },
      },
      chartData: [
        //{   name: "Sample Data",     data: [30, 40, 45, 50, 49, 60, 70, 91, 125],     },
      ],
      chartDataShow: [
        //{   name: "Sample Data",     data: [30, 40, 45, 50, 49, 60, 70, 91, 125],     },
      ],

      nextMaintenances:[],
      lastMaintenanceTable:[],
      stat: {
        last90: {
          newMachine:  0,
          interventionClosed: 0,
          interventionOpen: 0,
          visitsDone: 0

        }
      },
      tableColumns: [{
          label: 'Macchinario',
          field: 'machineId',
        }, {
          label: 'Descrizione',
          field: 'description',
        }
        ,
        //{
        //  label: 'Interventi fatti',
        //  field: 'interventionCount',
        //},
         {
          label: 'Frequenza man.(gg)',
          field: 'triggeredByDays',
        }, {
          label: 'Ultima manutenzione',
          field: 'lastInterventionDate',
        },
        {
          label: 'Scadenza',
          field: 'expiringDate',
        },
        {
          label: 'Giorni alla scadenza',
          field: 'daysToExpire',
        }
        
      ]
      
    };
  },
  async mounted() {
    


  },
  methods: {
    async downloadData() {
      console.log(this.chartData)
      const workbook = new ExcelJS.Workbook();
      workbook.creator = 'Kilab.eu System';
      workbook.lastModifiedBy = 'Download';
      workbook.created = new Date();
      workbook.modified = new Date();
      const sheet = workbook.addWorksheet('Production Logs');
      
      let columsData = [
        { header: 'Nome Sensore e codice', key: 'name',  width: 28 },
      ]
      for(let i=0;i<48;i++){
        columsData.push({header: this.chartNew.xaxis.categories[i], key: ('Tq-'+i) , width: 15})
      }
      sheet.columns = columsData;     
      for(let i=0;i<this.chartData.length;i++){
        //let rowFormatted = [];
        //for(let j=0;j<this.changeDate[i].data.length;j++){
          //rowFormatted.push(this.changeDate[i].data[j])
        //}
        sheet.addRow([this.chartData[i].name  ,...this.chartData[i].data]);
      }

      for(let i=0;i<this.rawDataStat.length;i++) {
        const sheetDetail = workbook.addWorksheet( this.rawDataStat[i].dataCode );
        console.log('Details:' + this.rawDataStat[i].details.length)
        for(let j=0;j<this.rawDataStat[i].details.length;j++){
          sheetDetail.addRow([this.rawDataStat[i].details[j].momentInfo,this.rawDataStat[i].details[j].value])
        }
        
        
      }

      const buffer = await workbook.xlsx.writeBuffer();
      
      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'manutenzioni.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();



    },
    changeDate() {
      this.chartData = []
      this.dataForChart = []
      this.chartDataShow = []
    },
    changeChartData() {
      //console.log(this.dataForChart)
      this.chartDataShow = [];
      
      for(let i=0;i<this.chartData.length;i++){
          if( this.dataForChart.includes(this.chartData[i].dataCode)){
            this.chartDataShow.push(this.chartData[i])
          }
      }
      


    },
    /*changeChartSelection() {
      this.chartDataShow = [];
      for(let i=0;i<this.chartData.length;i++){
          if(  this.chartData[i].selected){
            this.chartDataShow.push(this.chartData[i])
          }
      }
    },*/
    async getData() {
      this.loader = 'loading';
      this.chartData = []
      this.dataForChart = []
      this.chartDataShow = []
      let formattedDateSelected = moment(this.date).format('DD-MM-YYYY')
      //console.log(formattedDateSelected)
      this.rawDataStat  = (await serviceStat.getProductionStatFromTo(formattedDateSelected,formattedDateSelected)).data;
      this.loader = null
      let dataStat = this.rawDataStat;
      console.log('Data get from API:' + JSON.stringify(dataStat));
      for(let i=0;i<dataStat.length;i++){
        this.chartData.push({ name: dataStat[i].name, dataCode:dataStat[i].dataCode  , data: dataStat[i].avgValues})
      }
      this.chartData.sort((a, b) => {
          // Use localeCompare for string comparison
          return a.name.localeCompare(b.name);
        });
      return dataStat;
      /*if(this.tenantData?.services?.maintenance?.config?.dataLogApiAdress){
        const dataStat = await axios.get( this.tenantData?.services?.maintenance?.config?.dataLogApiAdress + "/api/datalog/days/" + formattedDateSelected + "/" + formattedDateSelected, //http://localhost:4005
          { 
              headers: {
                  "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
              }
          }
        )
        console.log(dataStat.data);
        for(let i=0;i<dataStat.data.length;i++){
          this.chartData.push({ name: dataStat.data[i].name, dataCode:dataStat.data[i].dataCode  , data: dataStat.data[i].avgValues})
        }

        this.chartData.sort((a, b) => {
          // Use localeCompare for string comparison
          return a.name.localeCompare(b.name);
        });
        return dataStat.data;
      }else {
        console.log('Error, data server not configured in TEANANT data')
        return []
      }*/
    },
    
    
  },
  computed: {
    tenantData() {
      console.log(this.$store.getters["appModule/tenantData"])
      return this.$store.getters["appModule/tenantData"];
    }
  },
  components:{
   
    //VueApexCharts,
    apexchart: VueApexCharts,
    
  }
};
</script>

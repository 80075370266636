<template>
  <div class="home">
    
    <StatPage 
      :machine-list="machineList"
      :intervention-list1="interventionList1"
    />
    
  </div>
  
</template>

<script>
// @ is an alias to /src
import StatPage from "@/components/Stat.vue";

import { mapState } from "vuex";
export default {
  name: "home",
  
  data() {
    return {
      
    };
  },
  mounted() {
    //this.$store.dispatch("machineModule/loadMachines");
    this.$store.dispatch("interventionModule/loadInterventions");
    
  },
  computed: {
    ...mapState("machineModule", {
      machineList: function(state) {
        return state.machineList;
      }
    }),
    ...mapState("interventionModule", {
      interventionList1: function(state) {
        //console.log('List:',state.interventionList );
        return state.interventionList;
      }
    }),
  },
  components: {
    StatPage
  }
};
</script>

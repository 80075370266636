<template>
  <div class="conatiner-fluid" v-if="sensor">
    
    <div class="row">
        
        <div class="col-sm-6">   
          <v-select
                v-model="sensor.type"
                :items="typeList"
                filled
                label="Tipo"
              ></v-select>
        </div>
      </div>
    <div class="row" v-if="sensor.type=='MODBUS'">
      <div class="col-sm-6">
        <v-text-field
            v-model="sensor.dataCode"
            
            counter="8"
            hint="Fondamentale per statistiche"
            label="Codice breve (DATA CODE)"
          ></v-text-field>
        
          

          <v-text-field
                  v-model="sensor.sampling"
                  counter="3"
                  type="number"
                  label="Frequenza campionamento"
                  hint="In minuti, da 0 a 60 "
                ></v-text-field>

          <v-text-field
            v-model="sensor.unit"
            counter="5"
            label="Unità di misura"
          ></v-text-field>
        </div>          
        <div class="col-sm-6">  
          <v-text-field
            v-model="sensor.register"
            type="number"
            counter="8"
            hint="Codice del registro MODBUS"
            label="Registro"
          ></v-text-field>
                <v-switch
                  v-model="sensor.saveOnlyChanges"
                  :label="`Memorizza solo cambio di stato`"
                ></v-switch>
                <v-text-field
                  v-model="sensor.subordinate"
                  counter="6"
                  label="Subordinato a"
                  type="number"
                  hint="(indicare registro che indica accessione o spegnimento)"
                ></v-text-field>
                
                
            </div>
            <div class="col-sm-12">  
        <v-text-field
                v-model="sensor.description"
                label="Descrizione"
              ></v-text-field>
        <v-text-field
          v-model="sensor.connection"
          counter="20"
          
          label="Connessione"
          hint="indicare connessione specifica, diversa dalla standard, formato IP:PORTA "
        ></v-text-field>
      </div>
      </div>
      <div class="row" v-else>
        <div class="col-sm-6">
          Non implmentato, seleziona MODBUS per proseguire
        </div> 
      </div>
      
      
      
    
    <div class="float-right" v-if="sensor._id">
        <button class="btn btn-outline-danger" @click="deleteSensor" type="button">Elimina Sensore&times;</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SensorDetails",
  
  props: {
    sensor: { type: Object, default: () => null },
    machineList: { type: Array, default: () => [] }
  },
  data() {
    return {
      typeList: //{    'MODBUS':'Registro Modbus',    'OTHER':'Altro...',   },
      [
        { value: 'MODBUS', text: 'Registro Modbus'},
        { value: 'OTHER', text: 'Altro...'},
        // ... altre opzioni
      ]
    }
  },
  methods: {
    
    deleteSensor() {
      this.$emit("deleteSensor", this.sensor);
    }
    
  }
};
</script>
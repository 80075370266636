<template>
  <div class="conatiner-fluid" v-if="intervention">

    <div class="float-right" v-if="intervention._id">
      <i class="fas fa-code fa-fw" v-b-toggle.collapse-1></i>
      <b-collapse id="collapse-1" class="mt-2">
        <b-card>{{intervention}}</b-card>
      </b-collapse>
    </div>

    <div class="clearfix">
      
    </div>
    <div class="row">

      <div class="col-sm-3">        
          <div class="form-group">
              <label>Tipo</label> <b-icon id="qmtipo" icon="question-circle-fill"></b-icon>
              <b-tooltip target="qmtipo" title="Tipologie" triggers="focus">
                <p>Richiesta di intervento | Viene richiesta una manutenzione o un azione perché qualcosa non sta funzionando correttamente </p>
                <p>Programmato (parte delle manutenzioni) | Una manutenzione programmata fra quelle inserite a sistema viene eseguita  </p>
                <p>Provvisorio (da rivedere) | Una ripazione o sistemazione temporanea che dovrà essere rivista più avanti  </p>
                <p>Permanente (intervento stabile) | Una mripazione o soluzione di problema finale </p>
        
        
              </b-tooltip>
              <v-select
                v-model="intervention.type"
                :items="helpTypeInterventionnew"
                
                filled
                label="Tipo"
              ></v-select>

            
          </div>
      </div>
      <div class="col-sm-3">        
          <div class="form-group">

            
            <v-select
                v-model="intervention.status"
                :items="interventionStatusListnew"
                
                filled
                label="Stato"
              ></v-select>

             
          </div>
      </div>
      <div class="col-sm-3" v-if="['PROVVISORIA', 'PERMANENTE', 'ESTERNA','RICHIESTA'].includes(intervention.type)">        
          <div class="form-group">
            <v-select
                v-model="intervention.priority"
                :items="priorityListnew"
                
                filled
                label="Gravita"
              ></v-select>
              
          </div>
      </div>
      
      <div class="col-sm-3"   > 
        <label>Assegnato a:</label>
        <div class="form-group"  >
           
            <input class="form-control" v-model="intervention.externalExecutor" :disabled="intervention.status =='CLOSED'"/>
        </div>
      </div>
    
      
    </div>
    <div class="row">
      
    </div>
    <div class="row">
        <div class="col-sm-6">
          <div class="form-group">



           

           <v-autocomplete
            v-model="intervention.machineId"
            :items="machineListFormatted"
            :disabled="(intervention.status =='CLOSED'||  !!intervention._id)"
            dense
            filled
            label="Macchinario:"
          ></v-autocomplete>

          <v-select
                v-model="intervention.priority"
                :items="machineMaintenancesList"
                v-if="intervention.type === 'PROGRAMMATA'"
                :disabled="(intervention.status =='CLOSED' || !!intervention._id )"
                filled
                label="Manutenzione programmata"
              ></v-select>
          </div>
        </div>
       
        <div class="col-sm-6" >
          <div class="form-group">
              <label>Aperto ({{intervention.openBy}}): </label>
              <!-- https://bootstrap-vue.org/docs/components/form-datepicker -->
              <!--<input type="text" class="form-control" v-model="intervention.startDate" />-->
              <b-form-datepicker id="example-datepicker" v-model="intervention.startDate" class="mb-2" :disabled="intervention.status =='CLOSED'"></b-form-datepicker>
          </div>
          <div class="form-group" v-if="intervention.type != 'RICHIESTA'">
              <label>Fine(prevista): </label>
              
              <b-form-datepicker id="example-datepicker-end" v-model="intervention.endDate" class="mb-2" ></b-form-datepicker>
          </div>
        </div>
        
        <div class="col-12" v-if="intervention.type !== 'PROGRAMMATA'">          
          <v-textarea
            outlined
            v-model="intervention.issueDescription"
            :disabled="intervention.status =='CLOSED'"
            name="input-7-4"
            label="Descrizione problema"
          ></v-textarea>
        </div>
        <div class="col-12" v-if="['PROVVISORIA', 'PERMANENTE', 'ESTERNA'].includes(intervention.type)">          
          <v-textarea
          outlined
          v-model="intervention.solveDescription"
          :disabled="intervention.status =='CLOSED'"
          name="input-7-4"
          label="Descrizione soluzione"
          ></v-textarea>
        </div>

    </div>
    <div class="row" >
       <div class="col-sm-6" >
          
        </div>
      <div class="col-12 mb-3 mt-2 bg-light p-3" v-if="intervention.type === 'PROGRAMMATA'" >
        <h4>Check List</h4>
        <div class="list-group" v-if="intervention.scheduledMaintenance.checkList && intervention.scheduledMaintenance.checkList.length > 0">
          <div class="list-group-item" v-for="(item, index) in intervention.scheduledMaintenance.checkList" :key="index" :disabled="intervention.status =='CLOSED'">
            <label>{{item.title}}</label>&nbsp;&nbsp;
            <button type="button" :disabled="intervention.status =='CLOSED'" 
              class="btn btn-sm btn-secodnary" :class="{'btn-success': item.result==='OK'}"
              @click="onResolveCheckListItem(intervention.scheduledMaintenance, index)">OK</button>
            &nbsp;
            <button type="button" :disabled="intervention.status =='CLOSED'"
              class="btn btn-sm btn-secodnary" :class="{'btn-warning': item.result===''}"
              @click="onRejectCheckListItem(intervention.scheduledMaintenance, index)">KO</button>
          </div>
        </div>
      </div>
      <div class="col-6"   >
        <v-checkbox
          v-model="intervention.alreadyReported"
          :label="`Già segnalato in precedenza`"
          :disabled="intervention.status =='CLOSED'"
        ></v-checkbox>
      </div>
      
    </div>

    <div class="float-right" v-if="intervention._id">
      <button class="btn btn-outline-danger" @click="deleteIntervention" type="button" >Elimina Intervento&times;</button>
    </div>
  </div>
</template>

<script>


  import serviceMaintenance from '../../services/maintenance'
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css';

export default {
  name: "InterventionDettails",
  components: {
    vSelect
  },
  props: {
    intervention: { type: Object, default: () => null },
    machineList: { type: Array, default: () => [] }
  },
  data() {
    return {
      /*interventionDett: this.intervention,*/
      machineMaintenances:[],
      interventionStatusList:{
        'OPEN':'Aperto',
        'ASSIGNED':'Assegnato',
        'WAITING PARTS':'Attesa ricambi',
        'CLOSED':'Chiuso',
      },
      interventionStatusListnew:[
        {value:'OPEN',text:'Aperto'},
        {value:'ASSIGNED',text:'Assegnato'},
        {value:'WAITING PARTS',text:'Attesa ricambi'},
        {value:'CLOSED',text:'Chiuso'}
      ] ,
      priorityListnew: [
        {value:'URGENT',text:'Urgente'},
        {value:'NORMAL',text:'Normale'},
        {value:'LOW',text:'Bassa'},
      ],
      priorityList:{
        'URGENT':'Urgente',
        'NORMAL':'Normale',
        'LOW':'Bassa',
        
      },
      helpTypeIntervention : {
        'RICHIESTA':'Richiesta di intervento',
        'PROGRAMMATA':'Programmato (parte delle manutenzioni)',
        'PROVVISORIA':'Provvisorio (da rivedere)',
        'PERMANENTE':'Permanente (intervento stabile)',
       
      },
      helpTypeInterventionnew : [
        {value:'RICHIESTA',text:'Richiesta di intervento'},
        {value:'PROGRAMMATA',text:'Programmato (parte delle manutenzioni)'},
        {value:'PROVVISORIA',text:'Provvisorio (da rivedere)'},
        {value:'PERMANENTE',text:'Permanente (intervento stabile)'}
       
      ]
    }
  },
  computed: {
    machineListFormatted(){
      let data = this.machineList.map( elem => {
        return {
          value: elem._id.toString(),
          text: elem.name + ' (' + elem.shortCode + ')', //elem.machineId
        }
      })
      //console.log("Oggetto:" + JSON.stringify(data));
      return data;
    },
    machineMaintenancesList() {
      let data = this.machineMaintenances.map( elem => {
        return {
          value: elem._id.toString(),
          text: elem.description , //elem.machineId
        }
      })
      //console.log("Oggetto:" + JSON.stringify(data));
      return data;
    },
  },
  mounted() {
    if(this.intervention && this.intervention._id) {
      this.onChangeMachine(this.intervention.machineId)
      
    }
    
    if(this.intervention.maintenanceId)
        this.onChangeMaintenenceQueryString()  
    this.onChangeMachine(this.intervention.machineId)
  },
  watch: {
    // interventionDett(new_val) {
    //   this.$emit("interventionChange", new_val)
    // },    
    "intervention.machineId"(new_val) {
      //console.log("new_val", new_val)
      this.onChangeMachine(new_val)
    },
    "intervention.type"() {
      this.intervention && (this.intervention.scheduledMaintenance = {
        "checkList": []
      })
    },
    "intervention.status"(new_val) {
      if(new_val === 'CLOSED'){
        this.intervention.endDate = new Date();
        //console.log('Impostata Data Chiusura automatica');

      }
      //this.intervention && (this.intervention.scheduledMaintenance = {
      //  "checkList": []
      //})
    }
  },
  methods: {
    onChangeMachineTest(event) {
      var shortCodeEvent = event.substring(1,event.indexOf(")"));
      //console.log(shortCodeEvent);
      var resultFind = this.machineList.find(m => m.shortCode === shortCodeEvent );
      //console.log(resultFind);
      this.intervention.machineId = resultFind._id;
    },
    onResolveCheckListItem(scheduledMaintenance, index) {
      let cl = JSON.parse(JSON.stringify(scheduledMaintenance.checkList));
      cl[index].result = "OK";
      scheduledMaintenance.checkList = cl;
    },
    onRejectCheckListItem(scheduledMaintenance, index) {
      let cl = JSON.parse(JSON.stringify(scheduledMaintenance.checkList));
      cl[index].result = "";
      scheduledMaintenance.checkList = cl;
    },
    async onChangeMachine(machineId) {
      if(!machineId || this.intervention.type !== 'PROGRAMMATA'){
        this.machineMaintenances = [];
        return;
      }
      const maintenances = (await serviceMaintenance.getMaintenancesByMachine(machineId)).data
      this.machineMaintenances = maintenances;
    },
    async onChangeMaintenenceQueryString() {
      console.log(this.intervention.maintenanceId);
      this.machineMaintenances = (await serviceMaintenance.getMaintenancesByMachine(this.intervention.machineId)).data
      //this.onChangeMaintenence({ target: { value : this.intervention.maintenanceId  }  });
      //console.log("Value maintenance: " + this.intervention.maintenanceId);
      
      const maintenance = this.machineMaintenances.find(m => m._id === this.intervention.maintenanceId)
      console.log(this.machineMaintenances);
      if(!maintenance) {
        alert("manutenzione non trovata...");

        return;
      }
      this.intervention && (this.intervention.maintenanceId = maintenance._id);
      
      if(!this.intervention._id)
        this.intervention && (this.intervention.scheduledMaintenance.checkList = maintenance.checkList);
    },
    async onChangeMaintenance(event) {
      delete this.intervention.maintenanceId;
      let maintenanceId = event.target.value;
      //console.log("Value maintenance: " + event.target.value);
      const maintenance = this.machineMaintenances.find(m => m._id === maintenanceId)
      if(!maintenance) {
        alert("manutenzione non trovata...");

        return;
      }
      this.intervention && (this.intervention.maintenanceId = maintenance._id);
      if(!this.intervention._id)
        this.intervention && (this.intervention.scheduledMaintenance.checkList = maintenance.checkList);
    },
    addInterventionMessage() {
      this.$emit("addInterventionMessage", this.$refs.interventionMessage.value)
    },
    addCustomData() {
      this.$emit("addCustomData", {});
    },
    removeCustomData(index) {
      this.$emit("removeCustomData", index);
    },
    deleteIntervention() {
      this.$emit("deleteIntervention", this.intervention);
    }
    
  }
};
</script>
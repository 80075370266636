<template>
  <v-navigation-drawer app
    v-model="drawer"
      :mini-variant.sync="mini"
      permanent
      stateless>
      <v-list-item class="px-2">
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
        
        <v-list-item class="px-2" >
          <v-list-item-title>
            {{tenantData?.billingdata?.name}}
          </v-list-item-title>
        </v-list-item>

        <v-btn
          icon
          @click.stop="mini = !mini"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

      </v-list-item>
      

      <v-divider ></v-divider>

      <v-list dense>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to=item.to
          :hidden="item.hidden"
        >
          <!--<router-link :to=item.to>-->
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          <!--</router-link>-->
        </v-list-item>
        <v-list-item 
          @click="showAllMenuItems()">
            <v-list-item-icon>
              <v-icon></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Vedi tutti</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </v-list>
      
      
        
      
  </v-navigation-drawer>
  <!--<div class="navbar-nav">
    <div class="nav-item">
      
      <router-link class="nav-link" to="/">
        <span>
          <i class="fas fa-fw fa-columns"></i>&nbsp;Attività da fare
        </span>
      </router-link>
      <router-link class="nav-link" to="/intervention">
        <i class="fa fa-clipboard"></i>&nbsp; Interventi
      </router-link>
      <router-link class="nav-link" to="/visit">
        <i class="fa fa-eye"></i>&nbsp; Visite
      </router-link>      
      <router-link class="nav-link" to="/spare">
        <b-icon icon="screwdriver" aria-hidden="true"></b-icon>&nbsp; Ricambi
      </router-link>
      <router-link class="nav-link" to="/order">
        <b-icon icon="cart-plus-fill" aria-hidden="true"></b-icon>&nbsp; Ordini ricambi
      </router-link>
      
      <router-link class="nav-link" to="/site">
        <b-icon icon="person-lines-fill" aria-hidden="true"></b-icon>&nbsp; Luoghi
      </router-link>
      
    </div>
    

    <hr class="sidebar-divider" />

    <div class="sidebar-heading">Configurazione</div>

    <div class="nav-item">      
      
      <router-link class="nav-link" to="/machine">
        <b-icon icon="hdd" aria-hidden="true"></b-icon>
        &nbsp; Macchinari
      </router-link>     
      <router-link class="nav-link" to="/maintenance">
        <i class="fa fa-tools"></i>&nbsp; Man. Programmate
      </router-link>
      <router-link class="nav-link" :to="getLink()" id="tooltip-target-1">
        
        <i class="fa fa-exclamation-triangle" ></i>&nbsp; Richiesta intervento
       
      </router-link>
      <router-link class="nav-link" to="/events" id="tooltip-target-1">
        
        <i class="fa fa-calendar" ></i>&nbsp; Events
       
      </router-link>
      
    </div>

    <hr class="sidebar-divider" />

    <div class="text-center border m-3 p-2 d-sm-none rounded" style="opacity:0.8;font-size:0.7rem;">
      Text for
      <br />say something...
    </div>
  </div>-->
</template>

<script>
export default {
  name: "appMenu",
  mounted() {
    this.items.push({ title: 'Richiesta Intervento', to: '/form?tenant=' + this.authInfo.TCODE, icon: 'mdi-tooltip-plus-outline' })

    console.log('Menu hiding...' + JSON.stringify(this.tenantData?.services?.maintenance?.menuhidden))
    if(this.tenantData?.services?.maintenance?.menuhidden) {
      console.log('Hidden menus:' +this.tenantData.services.maintenance.menuhidden);
      for(let i=0;i<this.items.length;i++) {
        if(this.tenantData.services.maintenance.menuhidden[i])
          this.items[i].hidden = true
      }
    }
    
  },
  data () { return {
    drawer: true,
    items: [
      //{ title: 'Home', to:'/', icon: 'mdi-home-city' },
      { title: 'Attività da fare', to:'/', icon: 'mdi-home-city', hidden: false },
      { title: 'Interventi', to:'/intervention', icon: 'mdi-notebook-edit-outline' , hidden: false},
      { title: 'Visite', to: '/visit', icon: 'mdi-account-multiple-plus-outline' , hidden: false},
      { title: 'Ricambi', to: '/spare', icon: 'mdi-hammer-screwdriver' , hidden: false},
      { title: 'Ordini ricambi', to: '/order', icon: 'mdi-cart-check ', hidden: false },
      { title: 'Luoghi', to: '/site', icon: 'mdi-store-marker', hidden: false },
      { title: 'Macchinari', to: '/machine', icon: 'mdi-washing-machine' , hidden: false},
      { title: 'Man. Programmate', to: '/maintenance', icon: 'mdi-calendar-multiple-check' , hidden: false},
      { title: 'Eventi', to: '/events', icon: 'mdi-account-group-outline' , hidden: false},
      { title: 'Statistiche', to: '/stat', icon: 'mdi-chart-areaspline', hidden: false },

    ],
    menuApps: [
      { title: 'Maintenance', icon: 'mdi-home-city' },
      { title: 'Visitor', icon: 'mdi-account' },
      { title: 'SmartGate', icon: 'mdi-account-group-outline' },
      
    ],
    mini: true,
  
      
        
    }
  
  },
  methods:{
    showAllMenuItems(){
      for(let i=0;i<this.items.length;i++) {
        this.items[i].hidden = false
      }
    }
  },  
  computed:{
    authInfo() {
      return this.$store.getters["appModule/authInfo"];
    },
    tenantData() {
      console.log(this.$store.getters["appModule/tenantData"])
      return this.$store.getters["appModule/tenantData"];
    }
  }
  
}
</script>
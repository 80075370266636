import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store/index'

//import Layout from '../components/layout/Layout.vue'
import Layout2 from '../components/layout/Layout2.vue'

import homeView from '../views/Home.view.vue';
import statView from '../views/Stat.view.vue';
import productionStatView from '../views/ProductionStat.view.vue';
import aboutView from '../views/About.view.vue';
import formView from '../views/Form.view.vue';
import todoView from '../views/Todo.view.vue';
import interventionView from '../views/Intervention.view.vue';
import machineView from '../views/Machine.view.vue';
import machineView2 from '../views/Machine2.view.vue';
import machineDettailsView from '../views/MachineDettails.view.vue';
import maintenanceView from '../views/Maintenance.view.vue';
import spareView from '../views/Spare.view.vue';
import sensorView from '../views/Sensor.view.vue';
import orderView from '../views/Order.view.vue';
import customerView from '../views/Customer.view.vue';
import siteView from '../views/Site.view.vue';
import visitView from '../views/Visit.view.vue';
import configurationView from '../views/Configuration.view.vue';
import authView from '../views/Auth.view.vue';
import ErrorView from '../views/Error.view.vue';
import EventView from '../views/Event.view.vue';

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    
    components: {
      main: Layout2 
    },
    children: [{
        path: '/',
        name: 'Home',
        components: {
          page: homeView 
        }
      },

    ]
  },
  {
    path: '/about',
    
    components: {
      main: Layout2 
    },
    children: [{
        path: '/',
        name: 'About',
        components: {
          page: aboutView 
        }
      },

    ]
  },
  {
    path: '/stat',
    
    components: {
      main: Layout2 
    },
    children: [{
        path: '/',
        name: 'Statistic',
        components: {
          page: statView 
        }
      },

    ]
  },
  {
    path: '/productionstat',
    
    components: {
      main: Layout2 
    },
    children: [{
        path: '/',
        name: 'Production statistic',
        components: {
          page: productionStatView 
        }
      },

    ]
  },
  {
    path: '/form',
    
    components: {
      main: Layout2 
    },
    children: [{
        path: '/',
        name: 'Segnalazione guasto',
        components: {
          page: formView 
        }
      },

    ]
  },
  {
    path: '/machine',
    
    components: {
      main: Layout2 
    },
    children: [{
        path: '/',
        name: 'Machine',
        components: {
          page: machineView 
        }
      },

    ]
  },
  {
    path: '/machine2',
    
    components: {
      main: Layout2 
    },
    children: [{
        path: '/',
        name: 'Machine2',
        components: {
          page: machineView2
        }
      },

    ]
  },
  {
    path: '/machine/:id',
    
    components: {
      main: Layout2 
    },
    children: [{
        path: '/',
        name: 'MachineDettails',
        components: {
          page: machineDettailsView 
        }
      },

    ]
  },
  {
    path: '/configuration/sensor',
    
    components: {
      main: Layout2 
    },
    children: [{
        path: '/',
        name: 'Sensor',
        components: {
          page: sensorView 
        }
      },

    ]
  },
  {
    path: '/intervention',
    components: {
      main: Layout2 ,
    },
    children: [{
        path: '/',
        name: 'Intervention',
        components: {
          page: interventionView 
        }
      },

    ]
  },
  {
    path: '/maintenance',
    
    components: {
      main: Layout2 
    },
    children: [{
        path: '/',
        name: 'Maintenance',
        components: {
          page: maintenanceView 
        }
      },

    ]
  },
  {
    path: '/customer',
    
    components: {
      main: Layout2 
    },
    children: [{
        path: '/',
        name: 'Customer',
        components: {
          page: customerView 
        }
      },

    ]
  },
  {
    path: '/site',
    
    components: {
      main: Layout2 
    },
    children: [{
        path: '/',
        name: 'Site',
        components: {
          page: siteView 
        }
      },

    ]
  },
  {
    path: '/visit',
    
    components: {
      main: Layout2 
    },
    children: [{
        path: '/',
        name: 'Visit',
        components: {
          page: visitView 
        }
      },

    ]
  },
  {
    path: '/configuration',
    
    components: {
      main: Layout2 
    },
    children: [{
        path: '/',
        name: 'Configuration',
        components: {
          page: configurationView 
        }
      },

    ]
  },
  {
    path: '/spare',
    
    components: {
      main: Layout2 
    },
    children: [{
        path: '/',
        name: 'Spare',
        components: {
          page: spareView 
        }
      },

    ]
  },
  {
    path: '/order',
    
    components: {
      main: Layout2 
    },
    children: [{
        path: '/',
        name: 'Order',
        components: {
          page: orderView 
        }
      },

    ]
  },
  {
    path: '/auth',
    
    components: {
      main: Layout2 
    },
    children: [{
        path: '/',
        name: 'Auth',
        components: {
          page: authView 
        }
      },

    ]
  },

  {
    path: '/todo',
    
    components: {
      main: Layout2 
    },
    children: [{
        path: '/',
        name: 'Todo',
        components: {
          page: todoView 
        }
      },

    ]
  },
  {
    path: '/error',
    
    components: {
      name: 'Error',
      main: ErrorView 
      
    },

  },
  {
    path: '/events',
    
    components: {
      main: Layout2
    },
    children: [{
      path: '/',
      name: 'Events',
      components: {
        page: EventView
      }
    },

    ]
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(async (to, from, next) => {  
  store.commit("appModule/toggleAside", { opening: false });

  let redirectRoute = "";
  let isLogged = !!store.state.appModule.authInfo;
  if(!isLogged && localStorage.getItem("authInfo")) {
    store.dispatch("appModule/setAuthInfo", JSON.parse(localStorage.getItem("authInfo")), {root:true});
    
    isLogged=true;
  }
  if (to.path != "/form") {
    if(((to.path !== "/auth" ) && !isLogged )   ) {
      let authUrl = encodeURI((location.origin + "/auth"))
      let currentUrl = encodeURI((location.href))
      location.href="https://auth.kilab.eu/login?destination=" + currentUrl + "&from=" + authUrl + "&requestlogout=" + store.state.appModule.requestlogout;
      //location.href="https://192.168.218.109:3011/login?destination=" + currentUrl + "&from=" + authUrl + "&requestlogout=" + store.state.appModule.requestlogout;
      return;
    }
  }
  // redirect logic

  if(redirectRoute)
    next(redirectRoute);
  else
    next();
})
export default router

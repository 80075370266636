<template>
  <div class="list-group">

    <v-data-table
    :headers="headers"
    :items="interventionListFormatted"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Interventi</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              Nuovo
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.name"
                      label="Dessert name"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.calories"
                      label="Calories"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.fat"
                      label="Fat (g)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.carbs"
                      label="Carbs (g)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.protein"
                      label="Protein (g)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <!--<v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>-->
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn>
    </template>
  </v-data-table>


    <vue-good-table
      :columns="tableColumns"
      :rows="interventionListFormatted"
      :search-options="{
        enabled: true}"
      :pagination-options="{
        enabled: true,
        mode: 'pages',
        perPageDropdown: [10, 25, 50],
        nextLabel: 'Prossima',
        prevLabel: 'Precedente',
        rowsPerPageLabel: 'Righe per pagina',
      }">
      <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'">
            <b-button @click="changeSelected(props.row)">
                Modifica
            </b-button>
          </span>
          <span v-else-if="props.column.field == 'startDate'">
            {{props.row.startDate | moment("DD/MM/YYYY")}}
          </span>
          <span v-else-if="props.column.field == 'endDate'">
            {{props.row.endDate | moment("DD/MM/YYYY")}}
          </span>
          <span v-else-if="props.column.field == 'openDate'">
            {{props.row.openDate | moment("DD/MM/YYYY")}}
          </span>
          <!--<span v-else-if="props.column.field == 'machineId'">
            {{getMachineLabel(props.row.machineId)}}
          </span>-->
          <span v-else-if="props.column.field == 'status'">
            <!--{{getStatusLabel(props.row.status)}}-->
            <h6>
              <b-badge v-if="props.row.status=='OPEN'" variant="warning">Apreto</b-badge>
              <b-badge v-if="props.row.status=='CLOSED'" variant="light">Chiuso</b-badge>
              <!--<b-badge v-if="props.row.priority=='NORMAL'" variant="secondary">Normale</b-badge>
              <b-badge v-if="props.row.priority=='LOW'" variant="light">Bassa</b-badge>-->
              <b-badge v-if="props.row.status=='WAITING PARTS'" variant="light">Attesa ricambi</b-badge>
              <b-badge v-if="props.row.status=='ASSIGNED'" variant="secondary">Assegnato</b-badge>
            </h6>
          </span>
          <span v-else-if="props.column.field == 'priority'">
            <h6>
              <b-badge v-if="props.row.priority=='URGENT'" variant="danger">URGENTE</b-badge>
              <!--<b-badge v-if="props.row.priority=='NORMAL'" variant="secondary">Normale</b-badge>
              <b-badge v-if="props.row.priority=='LOW'" variant="light">Bassa</b-badge>-->
              <b-badge v-if="props.row.alreadyReported==true" variant="secondary">GIA SEGNALATO</b-badge>
            </h6>
          </span>
          
        </template>
    </vue-good-table>
  </div>
</template>

<script>
export default {
  name: "InterventionList",
  props: {
    list: { type: Array, default: () => [] },
    machineList: { type: Array, default: () => [] }
  },
  data() {
    return {
      headers: [
        {
          text: 'Macchinario',
          align: 'start',
          sortable: false,
          value: 'machineName',
        },
        { text: 'Stato', value: 'status' },
        
      ],
      tableColumns: [{
        label: '',
        field: 'actions',
      },{
        label: 'Macchinario',
        field: 'machineName',
      }, {
        label: 'Stato',
        field: 'status',
      },{
        label: 'Descrizione',
        field: 'issueDescription',
      },  {
        label: 'Tipo intervento',
        field: 'type',
      }, {
        label: '',
        field: 'priority',
        //type: 'date',
        //dateInputFormat: 'yyyy-MM-dd HH:mm:ss.SSS ',
        //dateOutputFormat: 'yyyy',
     // }, {
     //   label: 'Apertura',
     //   field: 'openDate',
      }, {
        label: 'Fine',
        field: 'endDate',
      }
      //, {
      //   label: 'customData',
      //   field: 'customData',
      // }]
      ],
      interventionStatusList:{
        'OPEN':'Aperto',
        'ASSIGNED':'Assegnato',
        'WAITING PARTS':'Attesa ricambi',
        'CLOSED':'Chiuso',
      },
      priorityList:{
        'URGENT':'Urgente',
        'NORMAL':'Normale',
        'LOW':'Bassa',
        
      },
      helpTypeIntervention : {
        'RICHIESTA':'Richiesta di intervento',
        'PROGRAMMATA':'Programmato (parte delle manutenzioni)',
        'PROVVISORIA':'Provvisorio (da rivedere)',
        'ESTERNA':'Esterno (interviene azienda esterna)',
        'PERMANENTE':'Permanente (intervento stabile)'
      }
    }
  },
  computed: {
    interventionListFormatted(){
      return this.list.map( elem => {
        return {
          ...elem,
          machineName: this.getMachineLabel(elem.machineId)
        }
      })
    }
  },
  methods: {
    getStatusLabel(thisStatus) {
      if(this.interventionStatusList[thisStatus])
        return this.interventionStatusList[thisStatus];
      else 
        return 'Sconosciuto';
    },
    changeSelected(intervention) {
      //console.log(intervention);
      this.$emit("interventionChanged", intervention);
    },
    getMachineLabel(mId) {
      let machineName = this.machineList.find(m => m._id == mId);
      if(!machineName) machineName = 'Non trovata';
      else machineName = machineName.name + ' (' + machineName.shortCode + ')'; 
      return machineName; 
    }
  }
};
</script>
<template>
  <div class="conatiner-fluid">
    <div class="row">
        <div class="col-sm-12">
          <div class="form-group">


           

            <!--<label>Seleziona una località:</label><br>
            <b-button class="w-100 mb-1" style="white-space:nowrap;"
              v-for="(site, index) in siteListNearest" :key="index" 
              @click="selectedSite = site._id" :class="{ 'active': selectedSite === site._id }"
              variant="info">{{site.name}}<br>{{site.city}}</b-button>-->
             
              <v-autocomplete
            v-model="selectedSite"
            :items="siteListnew"
            :disabled="!!visit._id"
            dense
            filled
            label="Seleziona una località:"
          ></v-autocomplete>
              {{selectedSite}}

            <select v-model="selectedSite" class="custom-select" hidden>
              <option v-for="site in siteList" :key="site._id" :value="site._id">{{site.name}} {{site.city}}</option>
            </select>
          </div>
        </div>

        <div class="col-sm-12 mb-3">
          <label>Macchine presenti:</label>

          <v-card elevation="2" v-for="(m, index) in visit.machines" :key="index" class="mx-auto">
            <v-row>
              <v-col>{{m.code}} {{m.name}}</v-col>
              <v-col><v-select
                  v-model="visit.machines[index].status"
                  :items="statusListNew"
                  label="Stato"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            
          </v-card>


          <!--<div class="list-group">
            <div class="list-group-item" v-for="(m, index) in visit.machines" :key="index">
              <span class="d-inline-block w-75">
                {{m.code}} {{m.name}}
              </span>
              

                <v-select
                  v-model="visit.machines[index].status"
                  :items="statusListNew"
                  label="Stato"
                  outlined
                ></v-select>

                <select v-model="visit.machines[index].status">
                  <option v-for="(desc ,state) in statusList"
                        :key="state"
                        :value="state">{{desc}}</option>
                </select>               
             
            </div>
          </div>-->

        </div>
        <div class="col-sm-6">
          <div class="form-group">
              <label>Fatta da:{{visit.owner}}</label>
              {{authInfo?.uid}}
              <b-form-select :options="userList" v-model="visit.owner" />
          </div>
        </div>          
        <div class="col-sm-6">
          <div class="form-group">
            <label>Data:</label>
            <b-form-datepicker id="example-datepicker-end" v-model="visit.datetime" class="mb-2" ></b-form-datepicker>
          </div>        
        </div>
    </div>
    <div class="row">
      <div class="col-sm-12">  
          <div class="form-group">
              <label>Note:</label>
              <textarea class="form-control" v-model="visit.note" ></textarea>
          </div>
      </div>
    </div>
    <!--<div class="float-right" v-if="visit._id">
        <button class="btn btn-outline-danger" @click="deleteVisit" type="button">Elimina Visita&times;</button>
    </div>-->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {isInRectBounds, distance, BOUND_NUMBER_LIMIT} from '../../core/helpers/geoHelper'
export default {
  name: "VisitDettails",
  owner: null,
  props: {
    visit: { type: Object, default: () => null },
    siteList: { type: Array, default: () => [] },
    machineList: { type: Array, default: () => [] }
  },
  watch: {
    selectedSite(newValue) {      
      this.$emit("changeSite", newValue);
    },
    
  },
  computed: {
    authInfo() {
      return this.$store.getters["appModule/authInfo"];
    },
    siteListnew() {
      let data = this.siteList.map( elem => {
        return {
          value: elem._id.toString(),
          text: elem.name + ' (' + elem.city + ')', //elem.machineId
        }
      })
      return data;
    },
    siteListNearest() {
      if(!this.currentPosition) {
        return [];
      }
      return this.siteList
                  .map((s) => {
                    s.point = {
                      lat: s.gpsLat,
                      long: s.gpsLon
                    }
                    return s;
                  })
                  .filter((machine) => machine.point.lat && machine.point.long && isInRectBounds(this.currentPosition.point, machine.point))
                  .sort((l1, l2) => {
                    return (
                      distance(this.currentPosition.point, l1.point) -
                      distance(this.currentPosition.point, l2.point)
                    );
                  }).slice(0, BOUND_NUMBER_LIMIT);
    },
    ...mapState("userModule", {
      userList: function(state) {
        if(!state.userList || !state.userList.length) {
          return []
        }
        //console.log("state.userList", state.userList);
        const usersList = state.userList.map(user => {
          return {
            value: user._id,
            text: `${user.name} ${user.surname}`
          }
        })
        return usersList;
      }
    }),
  },
  data() {
    return {
      selectedSite: '',
      currentPosition: null,
      statusList: {
        'GOOD':'Buono',
        'SOSO':'Discreto',
        'BAD':'Cattivo',
        'OUTOFORDER':'Non funzionante',
        'OTHER':'Altro',
      },
      statusListNew: [
        {value:'GOOD',text:'Buono'},
        {value:'SOSO',text:'Discreto'},
        {value:'BAD',text:'Cattivo'},
        {value:'OUTOFORDER',text:'Non funzionante'},
        {value:'OTHER',text:'Altro'},
    ],
      tableColumns: [{
        label: 'Codice breve',
        field: 'shortCode',
      }, {
        label: 'Nome',
        field: 'name',
      }, {
        label: 'Reparto',
        field: 'department',
      }, {
        label: 'Stato',
        field: 'status',
      }
      ]
    }
  },
  mounted() {
    if(this.visit.owner == null ||this.visit.owner == '' ) 
      this.visit.owner = this.authInfo?.uid

    if(this.visit.site) {
      this.selectedSite = this.visit.site;
    }
      
    navigator.geolocation.getCurrentPosition((data) => {
      this.currentPosition = {
        point: {
          lat: data.coords.latitude,
          long: data.coords.longitude
        }
      };
    }, (err) => {
      console.error(err);
    }, {
      enableHighAccuracy: true
    });
  },
  methods: {
    deleteVisit() {
      this.$emit("deleteVisit", this.visit);
    }
  }
};
</script>
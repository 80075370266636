import Vue from 'vue'
import Vuex from 'vuex'

import appModule from '@/store/modules/app.module'
import todoModule from '@/store/modules/todo.module'
import interventionModule from '@/store/modules/intervention.module'
import machineModule from '@/store/modules/machine.module'
import maintenanceModule from '@/store/modules/maintenance.module'
import spareModule from '@/store/modules/spare.module'
import customerModule from '@/store/modules/customer.module'
import siteModule from '@/store/modules/site.module'
import sensorModule from '@/store/modules/sensor.module'
import visitModule from '@/store/modules/visit.module'
import userModule from '@/store/modules/user.module'
import orderModule from '@/store/modules/order.module'
import { version } from '../../package.json'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    appModule,
    todoModule,
    interventionModule,
    machineModule,
    maintenanceModule,
    version,
    spareModule,
    orderModule,
    customerModule,
    siteModule,
    userModule,
    visitModule,
    sensorModule
  }
})
<template>
  <div class="conatiner-fluid" v-if="machine">
    <div class="form-group">
        
    </div>

    <b-row>
      <b-col>
        <div class="form-group">
              <label>Codice breve: </label>
              {{machine.shortCode}}
          </div>
          <div class="form-group">
              <label>Nome macchinario: </label>
              {{machine.name}}
          </div>
          <div class="form-group">
              <label>Produttore: </label>
              {{machine.manufacture}}
          </div>
          <div class="form-group">
                <label>Modello: </label>
                {{machine.model}}
            </div>
          <div class="form-group">
                <label>Numero Seriale:</label>
                {{machine.serial}}
            </div>
            
          <div class="form-group">
              Data di installazione: {{machine.installDate | moment("DD/MM/YYYY")}} 
            </div>
            <div class="form-group">
              Ultimo aggiornamento: {{machine.lastUpdate | moment("DD/MM/YYYY")}} 
            </div>

      </b-col>
      <b-col>

        




            <div class="form-group">
                <input disabled type="checkbox" :checked="machine.isActive" />&nbsp;<label>Attivo</label><br>
                <input disabled type="checkbox" :checked="machine.ceMark" />&nbsp;<label>Marcatura CE</label><br>
                <input disabled type="checkbox" :checked="machine.therIsManual" />&nbsp;<label>Presenza manuale</label><br>
                <input disabled type="checkbox" :checked="machine.conformCertificate" />&nbsp;<label>Certificato di conformita</label><br>
                
            </div>


            <div class="form-group">
                <label>Dipartimento: </label>
                {{machine.department}}
            </div>
            <div class="form-group">
              <!--<b>Documentazione/manuali:</b>
              <a  target="_blank" :href="machine.manualLink">{{machine.manualLink}}</a>-->
              <b-button v-if="machine.manualLink" target="_blank" :href="machine.manualLink" id="butt_manual"> Documentazione/manuali <b-icon icon="link"></b-icon> </b-button>
              <b-tooltip target="butt_manual" triggers="hover">
                {{machine.manualLink}}
              </b-tooltip>
            </div>
            
            <div class="form-group">
              <!--<b>Segnalazione diretta:</b>
              <a :href="qrcodevalue">{{qrcodevalue}}</a>-->
              <b-button target="_blank" :href="qrcodevalue"> Segnalazione diretta <b-icon icon="link"></b-icon> </b-button>
              <b-tooltip target="butt_manual" triggers="hover">
                {{qrcodevalue}}
              </b-tooltip>
              
            </div>
      </b-col>

    </b-row>
    <b-row>
      <b-col>
      </b-col>
      <b-col>
      </b-col>

    </b-row>

    <b-row>
      <b-col>

        <b-tabs pills card vertical>
          <b-tab title="Etichette:" active>
            Qui si trovano le etichette da poter utilizzare per i macchinari
          </b-tab>
          <b-tab title="segnalazione diretta"><b-card-text></b-card-text>
            
              <b-card style="max-width: 280px;" title="" id="label-direct" >  <!-- :id="machine.shortCode" -->  
                <b-card-text>
                  <h1>Cod. <b>{{machine.shortCode}}</b></h1>
                  <h3><b>{{machine.name}}</b></h3><br>
                  <qrcode-vue :value="qrcodevalue" :size="240" level="Q"></qrcode-vue> <br>  
                  <i>Software MAINTENANCE.KILAB.EU</i>  
                </b-card-text>
              </b-card>
              <b-button variant="primary" @click="printLabelJpg('label-direct')">Scarica (jpeg)</b-button> &nbsp;
              <b-button variant="primary" @click="printLabelPng('label-direct')">Scarica (png)</b-button>
          </b-tab>
          <b-tab title="Codice breve"><b-card-text></b-card-text>
              <b-card style="max-width: 280px;" title="" id="label-short" >    
                <b-card-text>
                  <h1>Cod. <b>{{machine.shortCode}}</b></h1>
                  <h3><b>{{machine.name}}</b></h3><br>
                  <qrcode-vue :value="machine.shortCode" :size="240" level="Q"></qrcode-vue> <br>  
                  <i>Software MAINTENANCE.KILAB.EU</i>  
                </b-card-text>
              </b-card>
              <b-button variant="primary" @click="printLabelJpg('label-short')">Scarica (jpeg)</b-button> &nbsp;
              <b-button variant="primary" @click="printLabelPng('label-short')">Scarica (png)</b-button>

          </b-tab>
          <b-tab title="ID macchinario"><b-card-text></b-card-text>
              <b-card style="max-width: 280px;" title="" id="label-ID" >    
                <b-card-text>
                  <h1>Cod. <b>{{machine.shortCode}}</b></h1>
                  <h3><b>{{machine.name}}</b></h3><br>
                  <qrcode-vue :value="machine._id" :size="240" level="Q"></qrcode-vue> <br>  
                  <i>Software MAINTENANCE.KILAB.EU</i>  
                </b-card-text>
              </b-card>
              <b-button variant="primary" @click="printLabelJpg('label-ID')">Scarica (jpeg)</b-button> &nbsp;
              <b-button variant="primary" @click="printLabelPng('label-ID')">Scarica (png)</b-button>

          </b-tab>
          <b-tab title="Seriale macchinario"><b-card-text></b-card-text>
              <b-card style="max-width: 280px;" title="" id="label-serial" >    
                <b-card-text>
                  <h1>Cod. <b>{{machine.shortCode}}</b></h1>
                  <h3><b>{{machine.name}}</b></h3><br>
                  <qrcode-vue :value="machine.serial" :size="240" level="Q"></qrcode-vue> <br>  
                  <i>Software MAINTENANCE.KILAB.EU</i>  
                </b-card-text>
              </b-card>
              <b-button variant="primary" @click="printLabelJpg('label-serial')">Scarica (jpeg)</b-button> &nbsp;
              <b-button variant="primary" @click="printLabelPng('label-serial')">Scarica (png)</b-button>

          </b-tab>
        </b-tabs>
      </b-col>
      
    </b-row>

    <b-row>
      <b-col>
      </b-col>
      <b-col>
      </b-col>

    </b-row>

    
    

    
      
    <div class="mb-3 mt-2 bg-light p-3">
      <h4>Campi personalizzati</h4>
      <div class="list-group" v-if="machine.customData && machine.customData.length > 0">
          <div class="list-group-item" v-for="item in machine.customData" :key="item._id">
              <b>{{item.name}} :</b> {{item.value}}
          </div>
      </div>
    </div>

    
    
    <div class="list-group" v-if="associatedMaintenances.length > 0">
      <div class="mb-3 mt-2 bg-light p-3">
        <h4>Manutenzioni programmate</h4>

          <div class="list-group-item" v-for="mintenance in associatedMaintenances" :key="mintenance._id">
            <b-row>
            <b-col>
              <b>Descrizione:</b> {{mintenance.description}}<br>
              <b>Intervallo fra manutenzioni in giorni:</b> {{mintenance.triggeredByDays}}<br>
            </b-col>
            <b-col>
              <div  v-if="mintenance.checkList.length > 0">
                <b>Check List (controlli da effettuare):</b>
                  <div  v-for="(mCL,index) in mintenance.checkList" :key="index">
                      {{mCL.title}}; <!--<br>-->
                  </div>
              </div>
            </b-col>
            </b-row>
              <!--triggeredByTime: {{mintenance.triggeredByTime}}<br>-->
              
          </div>
      </div>
    </div>
    


    <div class="list-group" v-if="associatedIntervations.length > 0">
      <div class="mb-3 mt-2 bg-light p-3">
        <h4>Interventi eseguiti: </h4>

        <b-table striped hover :items="interventionStats"></b-table>

        <b-button v-b-toggle.collapse-list variant="primary">Dettaglio interventi</b-button>


        <v-btn depressed color="primary" @click="downloadMaintenance">
          Download Interventi
        </v-btn>
       
    

        <b-collapse id="collapse-list" class="mt-2">
          <b-card>
            <div class="list-group-item" v-for="intervation in associatedIntervations" :key="intervation._id">
            <b-col>
              <strong>Iniziato</strong> {{intervation.startDate | moment("DD/MM/YYYY")}} 
              <!--<strong>openBy</strong> {{intervation.openBy}}<br>-->
              <!--<strong>openDate</strong> {{intervation.openDate}}<br>-->
              <!--<strong>closedBy</strong> {{intervation.closedBy}}<br>-->
              <strong>Terminato: </strong> {{intervation.endDate | moment("DD/MM/YYYY")}}<br>
              <strong>Tipo finale: </strong> {{intervation.type}}<br>
              <strong>Descrizione problema:</strong> {{intervation.issueDescription}}<br>
              <strong>Descrizione soluzione: </strong> {{intervation.solveDescription}}<br>
              <strong>Stato attuale: </strong> {{intervation.status}}<br>
            </b-col>
            <b-col>
              <div class="list-group" v-if="intervation.notes.length > 0">
                Note riportate:
                <div class="list-group-item" v-for="(note,index) in intervation.notes" :key="index">
                    <strong>{{note.user}}</strong>&nbsp;&nbsp;&nbsp;{{note.text}} | {{note.date && new Date(note.date).toLocaleString()}}<br>
                </div>
              </div>
              <div class="list-group" v-if="intervation.scheduledMaintenance.checkList.length > 0">
                Controlli con esito:
                <div class="list-group-item" v-for="(mCL,index) in intervation.scheduledMaintenance.checkList" :key="index">
                    <strong>{{mCL.title}} : </strong>&nbsp;&nbsp;&nbsp;{{mCL.result}}<br>
                </div>
              </div>
            </b-col>
          </div>
          </b-card>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import machineService from "../services/machine"
import maintenanceService from "../services/maintenance"
import interventionService from "../services/intervention"
import QrcodeVue from 'qrcode.vue'
const ExcelJS = require('exceljs');
import moment from 'moment';
import * as htmlToImage from 'html-to-image';
export default {
  name: "MachineDettails",
  data() {
    return {
      machine: null,
      associatedMaintenances: [],
      associatedIntervations: [],
      qrcodevalue: "https://maintenance.kilab.eu/form?tenant=" + this.$store.getters["appModule/authInfo"].TCODE + "&machine=" + this.$route.params.id,
    }
  },
  async mounted() {    
    this.machine = (await machineService.getMachine(this.$route.params.id)).data;
    if(this.machine) {
      this.associatedMaintenances = (await maintenanceService.getMaintenancesByMachine(this.machine._id)).data
      this.associatedIntervations = (await interventionService.getIntervationsByMachine(this.machine._id)).data
    }
  },
  methods: {
    async downloadMaintenance() {
      const workbook = new ExcelJS.Workbook();
      workbook.creator = 'Kilab.eu System';
      workbook.lastModifiedBy = 'Download';
      workbook.created = new Date();
      workbook.modified = new Date();
      const sheet = workbook.addWorksheet('Dettaglio Manutenzioni');
      sheet.columns = [
        //{ header: 'Macchinario', key: 'machine',  width: 28 },
        { header: 'Data apertura', key: 'startDate' , width: 34},
        { header: 'Data chiusura', key: 'endDate' , width: 15},
        { header: 'Stato', key: 'status' , width: 20},
        { header: 'Tipo', key: 'type' , width: 20},
        { header: 'Priorita', key: 'priority' , width: 20},
        { header: 'Descrizione problema', key: 'issueDescription' , width: 20},
        { header: 'Aperta da', key: 'openBy' , width: 20},
        //{ header: 'Giorni alla scadenza', key: 'desc' , width: 20 }
      ];

      //alert(JSON.stringify( this.associatedIntervations[0]));
      this.associatedIntervations.forEach(el=> {
        let rowFormatted = [];
        //rowFormatted.push(this.getMachineLabel(el.machineId)) 
        rowFormatted.push( moment(el.startDate).format("DD/MM/YYYY"))
        rowFormatted.push( moment(el.endDate).format("DD/MM/YYYY"))
        rowFormatted.push(el.status)
        rowFormatted.push(el.type)
        rowFormatted.push(el.priority)
        rowFormatted.push(el.issueDescription)
        rowFormatted.push(el.openBy)
        

        sheet.addRow(rowFormatted);
      })
      const buffer = await workbook.xlsx.writeBuffer();
      
      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'statistiche-man-'+ this.machine.name  +'.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();



    },
    printMachine() {
      alert("Machine")
    },
    printLabelJpg(labelElement) {
      //var htmlToImage = require('html-to-image');
      let linkname = 'machine_' + this.machine.name + '.jpeg';
      htmlToImage.toJpeg(document.getElementById(labelElement), { quality: 0.90 }) //this.machine.shortCode), { quality: 0.90 })
      .then(function (dataUrl) {
        var link = document.createElement('a');
        
        console.log(linkname);
        link.download = linkname;
        //link.download = 'machine_.jpeg';
        link.href = dataUrl;
        link.click();
      });


    },
    printLabelPng(labelElement) {
      //var htmlToImage = require('html-to-image');
      let linkname = 'machine_' + this.machine.name + '.png';
      htmlToImage.toPng(document.getElementById(labelElement), { quality: 0.90 })
      .then(function (dataUrl) {
        var link = document.createElement('a');
        console.log(linkname);
        link.download = linkname;
        //link.download = 'machine_.jpeg';
        link.href = dataUrl;
        link.click();
      });


    }
    
  },
  computed: {
    authInfo() {
      return this.$store.getters["appModule/authInfo"];
    },
    interventionStats() {
      
      this.associatedIntervations.length;


      return {items: [ { test:45  } ] }
    }
  },
  components: {
    QrcodeVue,
  }
};
</script>
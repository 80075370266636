<template>
  <div class="conatiner-fluid" v-if="spare">
    
    <div class="row">
        <div class="col-sm-6">
            <div class="form-group">
                <label>Codice interno:</label>
                <input type="text" class="form-control" v-model="spare.code" />
            </div>
            <div class="form-group">
                <label>Codice fornitore:</label>
                <input type="text" class="form-control" v-model="spare.code2" />
            </div>
            <div class="form-group">
                <label>Macchinario:</label>
                <select v-model="spare.machineId" class="form-control">
                  <option value="">Scegli una machine...</option>
                  <option v-for="machine in machineList" :key="machine._id" :value="machine._id">{{machine.name}}</option>
                </select>
            </div>
            <div class="form-group">
                <label>Unità di misura:</label>
                <input type="text" class="form-control" v-model="spare.unity" />
            </div>
          </div>          
          <div class="col-sm-6">
            <div class="form-group">
                <label>Nome:</label>
                <input type="text" class="form-control" v-model="spare.name" />
            </div>
            <div class="form-group">
                <label>Fornitore:</label>
                <input type="text" class="form-control" v-model="spare.manufacture" />
            </div>
            <div class="form-group">
                <label>Descrizione:</label>
                <input type="text" class="form-control" v-model="spare.description" />
            </div>
            <div class="form-group">
                <label>Email:</label>
                <input type="text" class="form-control" v-model="spare.contact" />
            </div>
            <div class="form-group">
                <label>Note:</label>
                <input type="text" class="form-control" v-model="spare.notes" />
            </div>
            
            <!--<div class="form-group">
                <label>triggeredByTime</label>
                <input type="text" class="form-control" v-model="spare.triggeredByTime" />
            </div>-->
        </div>
    </div>
    <div class="row">
      <div class="col-sm-6">        
          <div class="form-group">
              <label>Stato</label>
              <b-form-select v-model="spare.orderStatus" class="mb-3">
                <b-form-select-option v-for="(desc ,state) in orderStatusList"
                   :key="state"
                   :value="state">{{desc}}</b-form-select-option>
              </b-form-select>
          </div>
      </div>
    </div>
      
      <!--<div class="mb-3 mt-2 bg-light p-3">
        <h4>Check List <button class="btn btn-sm btn-success align-center" @click="addCheckListDef">+</button></h4>
        <div class="list-group" v-if="spare.checkList && spare.checkList.length > 0">
          <div class="list-group-item" v-for="(item, index) in spare.checkList" :key="index">
            <input type="text" class="form-control w-50 d-inline-block" v-model="item.title" />
            
            
            <button type="button" class="btn btn-sm" @click="removeCheckListDef(index)">&times;</button>
          </div>
        </div>
      </div>-->
    <div class="float-right" v-if="spare._id">
        <button class="btn btn-outline-danger" @click="deleteSpare" type="button">Elimina Ricambio&times;</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpareDettails",
  
  props: {
    spare: { type: Object, default: () => null },
    machineList: { type: Array, default: () => [] }
  },
  data() {
    return {
      orderStatusList: {
        'FINISH':'Esaurito',
        'ORDERED':'Ordinato',
        'IN_STOCK':'Presente',
        'UNKNOW':'(Ignoto...)',
      },
    }
  },
  methods: {
    addCheckListDef() {
      this.$emit("addCheckListDef", {});
    },
    removeCheckListDef(index) {
      this.$emit("removeCheckListDef", index);
    },
    deleteSpare() {
      this.$emit("deleteSpare", this.spare);
    }
    
  }
};
</script>
<template>
  <div class="sparePage">
    <!--<h1>Ricambi</h1>-->

    <b-modal size="xl" ref="spare-modal" 
      :title="(inModifyMode ? 'Modifica' : 'Crea') + ' ricambi/o'"
      :ok-title="inModifyMode ? 'Modifica' : 'Crea'"
      @close="onChangeSpare(null)"
      @clanel="onChangeSpare(null)"
      @ok="onOkSpare">
      <spare-dettails 
        :spare="selectedSpare"
        :machine-list="machineList"
        @deleteSpare="onDeleteSpare"
        
         />
        
    </b-modal>

    <div class="clearfix">
      <div class="float-left">
        <button class="btn btn-primary" @click="newSpare">Nuovo ricambio <i class="fa fa-plus"></i></button>
      </div>
    </div>
    <spare-list 
      @spareChanged="onChangeSpare"
      :list="spareList"
      :machine-list="machineList" />
  </div>
</template>

<script>
import SpareList from "@/components/spare/SpareList.vue";
import SpareDettails from "@/components/spare/SpareDettails.vue";

import { mapState } from "vuex";
import spareService from "../services/spare";

export default {
  name: "SpareView",
  data() {
    return {
      selectedSpare: null
    };
  },
  mounted() {
    this.$store.dispatch("spareModule/loadSpares");
    //this.$store.dispatch("machineModule/loadMachines");
  },
  computed: {
    ...mapState("spareModule", {
      spareList: function(state) {
        return state.spareList;
      }
    }),
    ...mapState("machineModule", {
      machineList: function(state) {
        return state.machineList;
      }
    }),
    inModifyMode() {
      return !!(this.selectedSpare && this.selectedSpare._id)
    }
  },
  methods: {
    async onOkSpare() {
      if(!this.selectedSpare)
        return;
      if(this.selectedSpare._id) {
        await spareService.updateSpare(this.selectedSpare)
      } else {
        await spareService.createSpare(this.selectedSpare)
      }
      
      this.$store.dispatch("spareModule/loadSpares")
    },
    async onDeleteSpare(spare) {
      //console.log('Deleting?');
      if(!this.selectedSpare || !this.selectedSpare._id || this.selectedSpare._id !== spare._id)
        return;
      if(!confirm("Confermi di voler cancellare questa spare")) {
        return;
      }
      await spareService.deleteSpare(this.selectedSpare._id)
      this.$store.dispatch("spareModule/loadSpares")
    },
    newSpare(){
        this.onChangeSpare({})
    },
    
    onChangeSpare(spare) {
      if(spare) {
        this.selectedSpare = JSON.parse(JSON.stringify(spare));        
        this.$refs['spare-modal'].show()
      } else {       
        this.selectedSpare = null; 
        this.$refs['spare-modal'].hide()
      }
    }
  },
  components: {
    SpareList,
    SpareDettails
  }
};
</script>


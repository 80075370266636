<template>
  <div class="mt-3">
    

    <b-card-group deck>
      <b-card  :header=dataKey  border-variant="secondary" header-border-variant="secondary">
        <b-button-group  class="text-center"  v-for="(events, key) in eventData" :key="key">
          
          <b-button  v-on:click="eventSelected(events)" v-b-toggle="`collapse-` + key">{{ key }} <b-badge>{{ events.length }}</b-badge></b-button>
          
        </b-button-group>
      </b-card>
    </b-card-group>
    <b-modal v-model="modalShow" v-if="selectedEvent.length>0">
      <v-chip class="ma-2"><b>AZIONE:</b>  {{selectedEvent[0].action}}</v-chip> 
      <b-list-group>
        <div v-for="(se,key) in selectedEvent" :key="key">
          <b-list-group-item><b>SOGGETTO:</b> {{se.object}} <bR></bR> 
            
            <b>DA:</b><i v-if="se.from">{{se.from}}</i><bR></bR>
            <b>VERSO:</b> {{se.to}} </b-list-group-item>
        </div>
      </b-list-group>
      <!--<b-table striped hover :items="selectedEvent"></b-table>-->
    </b-modal>

    
    
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() { return{
    modalShow: false,
    selectedEvent: []
    }
  },
  computed: {
    ...mapState("machineModule", {
      machineList: function(state) {
        return state.machineList;
      }
    }),
  },
  props: {
    eventData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    
	dataKey: {
		type: String,
		required: true,
		default: () => ''
	}
  
  },
  methods: {
    eventSelected(ev) {
      this.selectedEvent = ev
      this.modalShow = true;
    }
  }
};
</script>
import axios from "axios"
import global from "../../globalvar";

async function getAllSpares() {
    return (await axios.get(global.ApiBaseUrl +"/api/maintenance/spare/all", {
        headers: {
            "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
        }
    }))
}
async function getSparesByMachine(machineId) {
    return (await axios.get(global.ApiBaseUrl +"/api/maintenance/spare/bymachine/" + machineId, { 
        headers: {
            "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
        }
    }))
}
async function createSpare(spare) {
    const _spare = JSON.parse(JSON.stringify(spare));
    return (await axios.post(global.ApiBaseUrl +"/api/maintenance/spare/create", _spare, { 
        headers: {
            "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
        }
    }))
}
async function updateSpare(spare) {
    const _spare = JSON.parse(JSON.stringify(spare));
    return (await axios.put(global.ApiBaseUrl +"/api/maintenance/spare/" + spare._id + "/edit", 
        _spare, 
        { 
            headers: {
                "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
            }
        }
    ))
}
async function deleteSpare(spareId) {
    return (await axios.delete(global.ApiBaseUrl +"/api/maintenance/spare/delete/" + spareId,
        { 
            headers: {
                "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
            }
        }
    ))
}


export default {
    getAllSpares,
    getSparesByMachine,
    createSpare,
    updateSpare,
    deleteSpare
}
<template>
  <div class="machinePage">

    
    

    

    <!--<h1>Macchinari/dispositivi</h1>-->
    <!--  machineDetail   <machine-dettails :machine="selectedMachine"-->
    <b-modal ref="machine-modal" :title="(inModifyMode ? 'Modifica' : 'Crea') + ' macchinario/dispositivo'" size="xl"
      :ok-title="inModifyMode ? 'Modifica' : 'Crea'"
      @close="closeDetail(null)"
      @clanel="closeDetail(null)"
      @ok="onOkMachine">
      
      <machine-dettails :machine="machineDetail"
        :customer-list="customerList"
        :site-list="siteList"
        @deleteMachine="onDeleteMachine"
        @addCustomData="onAddCustomData"
        @removeCustomData="onRemoveCustomData" />
    </b-modal>

    <div class="clearfix">
      
      <v-btn
        depressed
        color="primary"
        @click="newMachine"
      >
      Nuovo macchinario <i class="fa fa-plus"></i>
      </v-btn>

      <div class="float-right" >
        <b-button v-b-toggle.filter-1 class="btn btn-outline-primary" >Filtri <i class="fa fa-filter"></i></b-button>
      </div>
    </div>
    <!--<b-button v-b-toggle.collapse-1 variant="primary">Toggle Collapse</b-button>-->
    <b-collapse id="filter-1" class="mt-2">
      <b-card>
        <p class="card-text">Filtra macchinari per: </p>  
        

          <v-autocomplete
            v-model="filter.site"
            :items="siteListFormatted"
            dense
            filled
            label="Luogo"
          ></v-autocomplete>
          
            
          

          <v-select
            v-model="filter.ownerid"
            :items="userListFormatted"
            
            label="Proprietario"
            required
            
            
          ></v-select>



      
      </b-card>
    </b-collapse>
    <machine-list 
      @machineChanged="onChangeMachine"
      
      :list="machineListFiltered" 
      
      />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import MachineList from "@/components/machine/MachineList.vue";
import MachineDettails from "@/components/machine/MachineDettails.vue";

import { mapState } from "vuex"; //, mapGetters
import machineService from "../services/machine";

export default {
  name: "MachineView",
  data() {
    return {
      //selectedMachine: null,
      //machineListFiltered:null,
      valid: true,
      filter: {
        customerid: null,
        ownerid: null,
        site: null
      },
      showFilters : false,
      ownerList : [],
      
      

    };
  },
  mounted() {
    //this.$store.dispatch("machineModule/loadMachines");

    // query string search
    if(this.$route.query.customerid) {
      this.filter.customerid = this.$route.query.customerid;
      this.showFilters = true;
    }
    if(this.$route.query.ownerid) {
      this.filter.ownerid = this.$route.query.ownerid;
      this.showFilters = true;
    }
        
    this.filter.ownerid = null // activate when ready with: this.authInfo.uid;
      
  },
  computed: {
    siteListFormatted(){
      let data = this.siteList.map( elem => {
        return {
          value: elem._id.toString(),
          text: elem.name + ' (' + elem.address + ',' + elem.city + ')', //elem.machineId
        }
      })
      //console.log("Oggetto:" + JSON.stringify(data));
      return data;
    },
    userListFormatted(){
      console.log(this.userList)
      let data = this.userList.map( elem => {
        return {
          value: elem._id.toString(),
          text: elem.name + ' ' +  elem.surname 
        }
      })
      data.unshift({value: null, text: " -- TUTTI -- " });
      //console.log("Oggetto:" + JSON.stringify(data));
      return data;
    },
    /*...mapGetters("machineModule", {
      machineList: function(getters) {
        //return state.machineList;
        return getters.machineListFiltered;
      }
    }),**/
    ...mapState("machineModule", {
      machineDetail: function(state) {
        return state.machineDetail;
      },
      machineListFiltered: function(state) {
        if(!this.filter.site && !this.filter.ownerid) {
          return state.machineList;
        }
        //debugger;
        return state.machineList.filter(m=>  {
          return (!this.filter.site || m.site == this.filter.site) &&
                 (!this.filter.ownerid || m.owner == this.filter.ownerid) 
        })
        
      }
    }),
    ...mapState("customerModule", {
      customerList: function(state) {
        return state.customerList;
      }
    }),
    ...mapState("userModule", {
      userList: function(state) {
        return state.userList;
      }

      


    }),
    ...mapState("siteModule", {
      siteList: function(state) {
        return state.siteList;
      }
    }),
    inModifyMode() {
      return !!(this.machineDetail && this.machineDetail._id)
    },
    authInfo() {
      return this.$store.getters["appModule/authInfo"];
    }
  },
  methods: {
    
    
    async onOkMachine() {
      if(!this.machineDetail)
        return;
      let reqAnwser ;
      try{
        if(this.machineDetail._id) {
          reqAnwser = await machineService.updateMachine(this.machineDetail)
        } else {
          reqAnwser = await machineService.createMachine(this.machineDetail)
        }
        this.$store.dispatch("appModule/addSuccessMessage", "Inserimento/modifica completata" )
      }catch(err) {
        this.$store.dispatch("appModule/addErrorMessage", "Errore nella creazione o nella modifica:" + JSON.stringify(err) + " ||| Answer:"+JSON.stringify(reqAnwser), { root: true } )
      }
      
      this.$store.dispatch("machineModule/loadMachines")
    },
    async onDeleteMachine(machine) {
      if(!this.machineDetail || !this.machineDetail._id || this.machineDetail._id !== machine._id)
        return;
      if(!confirm("Confermi di voler cancellare questa machine")) {
        return;
      }
      await machineService.deleteMachine(this.machineDetail._id)
      this.$store.dispatch("machineModule/loadMachines" )
    },
    newMachine(){
        this.onChangeMachine()
    },
    onAddCustomData() {
      if(!this.machineDetail)
        return;
      this.machineDetail.customData = [...this.machineDetail.customData, { name:'', value: ''}];
    },
    onRemoveCustomData(index) {
      if(!this.machineDetail)
        return;
      console.log(index);
      console.log(this.machineDetail.customData);
      this.machineDetail.customData = this.machineDetail.customData.filter((x,i)=> i!=index )
      console.log(this.machineDetail.customData);
    },
    closeDetail() {
      this.$refs['machine-modal'].hide()
    },
    onChangeMachine(machine) {
      this.$store.dispatch("machineModule/loadMachineDetails", machine )
      this.$refs['machine-modal'].show()
    },
    onChangeSiteFilter(siteId) {
      //console.log("Place changed:" + siteId)
      this.filter.site = siteId;
    }
  },
  components: {
    MachineList,
    MachineDettails,
    vSelect
  }
};
</script>


import axios from "axios"
import global from "../../../globalvar";

let state = {
    spareList: []
};
let getters = {};
let mutations = {
    setSpareList(state, spareList) {
        state.spareList = spareList
    }
};
let actions = {
    async loadSpares({commit, dispatch, rootState}) {
        console.log(rootState);
        try {
            commit("appModule/startFetching", null, { root: true });
            let spareList = (await axios.get(global.ApiBaseUrl +"/api/maintenance/spare/all", {
                headers: {
                    "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
                }
            })).data;
            commit("appModule/endFetching", null, { root: true })
            commit("setSpareList", spareList)
        } catch (e) {
            dispatch("appModule/addErrorMessage", e, { root: true } )
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
<template>
  <div class="list-group">
    
    <vue-good-table
      :columns="tableColumns"
      :rows="spareListFormatted"
      styleClass="vgt-table striped condensed"
      :search-options="{
        enabled: true}">
      <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'">
            <b-button @click="changeSelected(props.row)">Modifica</b-button>

          </span>
          <!--<span v-else-if="props.column.field == 'machineId'">
            {{getMachineLabel(props.row.machineId)}}
          </span>-->
          <span v-else-if="props.column.field == 'orderStatus'">
            {{getStatusLabel(props.row.orderStatus)}}
          </span>
        </template>
    </vue-good-table>
  </div>
</template>

<script>
export default {
  name: "SpareList",
  props: {
    list: { type: Array, default: () => [] },
    machineList: { type: Array, default: () => [] }
  },
  data() {
    return {
      orderStatusList: {
        'FINISH':'Esaurito',
        'ORDERED':'Ordinato',
        'IN_STOCK':'Presente',
        'UNKNOW':'(Ignoto...)',
      },
      tableColumns: [{
          label: '',
          field: 'actions',
        }, {
          label: 'Stato',
          field: 'orderStatus',
        },{
          label: 'Macchinario',
          field: 'machineName',
        }, {
          label: 'Codice Interno',
          field: 'code',
        }, {
        
          label: 'Nome',
          field: 'name',
        }/*, {
          label: 'Email',
          field: 'contact',
        }, {
        //  label: 'Data ordine',
        //  field: 'orderDate',
        },  {
          label: 'Note',
          field: 'notes',
        }*/
      ]
    }
  },
  mounted() {
    this.list.forEach( spar => {
      spar.machineName = this.getMachineLabel(spar.machineId);
      
    });


  },
  computed: {
    spareListFormatted(){
      return this.list.map( elem => {
        return {
          ...elem,
          machineName: this.getMachineLabel(elem.machineId)
        }
      })
    }

  },
  methods: {
    changeSelected(spare) {
      //console.log('Spare select: ',spare);
      this.$emit("spareChanged", spare);
    },
    getMachineLabel(mId) {
      let machineName = this.machineList.find(m => m._id == mId);
      if(!machineName) machineName = 'Non trovata';
      else machineName = machineName.name + ' (' + machineName.shortCode + ')'; 
      //console.log(machineName);
      return machineName;
      
    },
    getStatusLabel(thisStatus){
      if(this.orderStatusList[thisStatus])
        return  this.orderStatusList[thisStatus];
      else 
        return 'Sconosciuto';
    }
  }
};
</script>
<template>
  <div class="visitPage">
    <!--<h1>Ricambi</h1>-->

    <b-modal size="xl" ref="visit-modal" 
      :title="(inModifyMode ? 'Modifica' : 'Crea') + ' visita'"
      :ok-title="createOrUpdateVisitLoading ? 'Loading...' : (inModifyMode ? 'Modifica' : 'Crea')"
      :ok-disabled="createOrUpdateVisitLoading"
      @close="onChangeVisit(null)"
      @clanel="onChangeVisit(null)"
      
      @ok="onOkVisit">
      <visit-dettails v-if="selectedVisit"
        :visit="selectedVisit"
        :site-list="siteList"
        :machine-list="machineList"
        @deleteVisit="onDeleteVisit"
        @changeSite="onChangeSite"
         />
        
    </b-modal>

    <div class="clearfix">
      <v-btn
        depressed
        color="primary"
        @click="newVisit"
      >
      Nuova Visita <i class="fa fa-plus"></i>
      </v-btn>
      
    </div>
    <visit-list 
      @visitChanged="onChangeVisit"
      :list="visitList"
      :site-list="siteList"
      :machine-list="machineList" />
  </div>
</template>

<script>
import VisitList from "@/components/visit/visitList.vue";
import VisitDettails from "@/components/visit/visitDettails.vue";
import { mapState } from "vuex";
import visitService from "../services/visit";

export default {
  name: "visitView",
  data() {
    return {
      selectedVisit: null,
      createOrUpdateVisitLoading: false
    };
  },
  mounted() {
    this.$store.dispatch("visitModule/loadVisits");
  },
  computed: {
    ...mapState("visitModule", {
      visitList: function(state) {
        return state.visitList;
      }
    }),
    ...mapState("machineModule", {
      machineList: function(state) {
        return state.machineList;
      }
    }),
    ...mapState("siteModule", {
      siteList: function(state) {
        return state.siteList;
      }
    }),
    inModifyMode() {
      return !!(this.selectedVisit && this.selectedVisit._id)
    }
  },
  methods: {
    async onOkVisit(event) {
      try {
        let successMessage = ''
        this.createOrUpdateVisitLoading = true
        if(!this.selectedVisit) {
            return;
        }
        event.preventDefault()
        if(this.selectedVisit._id) {
          const { data } = await visitService.updateVisit(this.selectedVisit)
          successMessage = data.message
        } else {
          const { data } = await visitService.createVisit(this.selectedVisit)
          successMessage = data.message
        }

        this.$store.dispatch("visitModule/loadVisits")
        this.$store.dispatch('appModule/addSuccessMessage', successMessage || "Success")
      } catch (error) {
        let errorMessage = error?.response?.data?.message || String(error) || 'something went wrong, please try again.'
        this.$store.dispatch('appModule/addErrorMessage', errorMessage)
      } finally {
        this.createOrUpdateVisitLoading = false
        this.$refs['visit-modal'].hide()
      }
    },
    async onDeleteVisit(visit) {
      //console.log('Deleting?');
      if(!this.selectedVisit || !this.selectedVisit._id || this.selectedVisit._id !== visit._id)
        return;
      if(!confirm("Confermi di voler cancellare questa visita")) {
        return;
      }
      await visitService.deleteSpare(this.selectedVisit._id)
      this.$store.dispatch("spareModule/loadSpares")
    },
    newVisit(){
        this.onChangeVisit({ datetime: new Date()})
    },
    onChangeVisit(visit) {
      if(visit) {
        this.selectedVisit = JSON.parse(JSON.stringify(visit));        
        this.$refs['visit-modal'].show()
      } else {       
        this.selectedVisit = null; 
        this.$refs['visit-modal'].hide()
      }
    },
    onChangeSite(siteid) {
      if(!this.selectedVisit) {
        return
      }
      this.selectedVisit.site = siteid;
      if(!this.selectedVisit._id) {
        this.selectedVisit.machines= this.machineList.filter((a) => a.site === this.selectedVisit.site)
                                                      .map(m => {
                                                        return {
                                                          machineId: m._id,
                                                          name: m.name,
                                                          code: m.code,
                                                          status: ''
                                                        };
                                                      });
      } 
    }   
  },
  components: {
    VisitDettails,
    VisitList,
    
  }
};
</script>


<template>
  <div id="app">
    <app-messages />
    <router-view name="page"/>
    <v-overlay :value="appIsFetching">
      <v-progress-circular
        indeterminate
        size="70"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>

import AppMessages from "@/components/layout/appMessages.vue"; 
export default {
  name: 'layout',
   components: {
    AppMessages
  },
    computed: {
    appIsFetching() {
      return this.$store.getters["appModule/appIsFetching"];
    },
    asideIsOpened() {
      return this.$store.getters["appModule/asideIsOpened"];
    },
    
    authInfo() {
      return this.$store.getters["appModule/authInfo"];
    }
  
  }
}

</script>
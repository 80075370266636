import axios from "axios"
import global from "../../../globalvar";

let state = {
    customerList: []
};
let getters = {};
let mutations = {
    setCustomerList(state, customerList) {
        state.customerList = customerList
    }
};
let actions = {
    async loadCustomers({commit, dispatch}){ //, rootState}) {
        //console.log(rootState);
        try {
            commit("appModule/startFetching", null, { root: true });
            let customerList = (await axios.get(global.ApiBaseUrl +"/api/customer/all", {
                headers: {
                    "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
                }
            })).data;
            commit("appModule/endFetching", null, { root: true })
            commit("setCustomerList", customerList)
        } catch (e) {
            dispatch("appModule/addErrorMessage", e, { root: true } )
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
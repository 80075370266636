<template>
  <div class="homePage">
    <!--<h1>Attività da fare</h1>-->
    <b-button v-b-modal.table-modal>Tabella manutenzioni programmate con ultimo intervento</b-button>
    <b-modal id="table-modal" title="Manutenzioni programmate" size="lg"
      
      
      @clanel="onChangeIntervention(null)"
      >
      
      <vue-good-table
      :columns="tableColumns"
      :rows="lastMaintenanceTable"
      styleClass="vgt-table striped condensed"
      :search-options="{
        enabled: true}">
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'lastInterventionDate'">
            {{props.row.lastInterventionDate | moment("DD/MM/YYYY")}}
          </span>
          <span v-else-if="props.column.field == 'machineId'">
            {{getMachineLabel(props.row.machineId)}}
          </span>
          <span v-else-if="props.column.field == 'expiringDate'">
            {{props.row.expiringDate | moment("DD/MM/YYYY")}}
          </span>
        </template>
      </vue-good-table>
    </b-modal>
    
    <b-card-group columns>
      <b-card 
        footer-bg-variant="warning"
        v-for="(mant, index) in nextMaintenances"
        :key="'MAN-'+index">
        <pre hidden>{{mant}}</pre>
        <b-card-text>
          <!--<div class="float-left">
            <button class="btn btn-primary" @click="newIntervention">{{getMachineData(mant.machineId)}} <i class="fa fa-tools"></i></button>
          </div>-->
           <a v-if="!mant.doing" :href="'intervention?new=1&machineid=' + mant.machineId + '&maintenanceid=' + mant._id"  ><h3>{{getMachineData(mant.machineId)}} <i class="fa fa-tools"></i> <br> <!--- {{mant.machineId}}--></h3></a>
           <a v-if="mant.doing" href="#"><h3>{{getMachineData(mant.machineId)}} <i class="fa fa-tools"></i> <br> <!--- {{mant.machineId}}--></h3></a>
           <label>{{mant.description}}</label><br>
          <!--<label>Ultimo intervento:</label> {{(mant.interventionDone && mant.interventionDone.endDate)| moment("DD/MM/YYYY") }} <br>-->
          <!--<label>Ultimo intervento:</label> {{mant.interventionDone.endDate | moment("DD/MM/YYYY")}} <br>-->
          <!--<label>Intervallo di tempo: </label> {{mant.triggeredByDays}}<br>-->
          <!--{{mant.isToDo}}-->
        </b-card-text>
        <template v-slot:footer>
          <big class="text-muted"><label>Scadenza il:</label> {{mant.expirationDate | moment("DD/MM/YYYY")}}</big>
          <b-badge class="m-2" v-if="(mant.expirationDate<new Date()) && !mant.doing" variant="danger"> SCADUTA </b-badge>
          <b-badge class="m-2" v-if="mant.doing" variant="info"> IN CORSO </b-badge>
          
        </template>
      </b-card>

      
      <b-card title=""
        footer-bg-variant="secondary"
        footer-text-variant="white"
        v-for="(mant, index) in interventionList1"
        :key="index">
        
        <!--<a :href="'intervention?new=0&interventionid=' + mant._id"  >  -->
        <b-card-text>
          
          <a :href="'intervention?new=0&interventionId=' + mant._id"  >
            <h3 v-if="mant.machineId"> {{getMachineData(mant.machineId) }}  <i class="fa fa-clipboard"></i>  <br> <!--- {{mant.machineId}}--></h3>
          </a>
          <!--<h3 v-else> Macchinario da specificare   <br>  {{mant.machineId}}</h3>-->
           <label>{{mant.issueDescription}}</label><br>
          <!--<label>Stato:</label> {{(mant.interventionDone && mant.interventionDone.endDate)| moment("DD/MM/YYYY") }} <br>-->
          <!--<label>Ultimo intervento:</label> {{mant.interventionDone.endDate | moment("DD/MM/YYYY")}} <br>-->
          <!--<label>Intervallo di tempo: </label> {{mant.triggeredByDays}}<br>-->
           
        </b-card-text>
        <!--</a>-->
        <template v-slot:footer>
          <big ><label>Aperta il:</label> {{mant.startDate | moment("DD/MM/YYYY")}}</big>
          <b-badge class="m-2" v-if="mant.priority=='URGENT'" variant="danger"> URGENTE </b-badge>
        </template>
        
      </b-card>

    </b-card-group>

    

  </div>
</template>

<script>
import serviceIntervention from "../services/intervention"
import serviceMaintenance from "../services/maintenance"


export default {
  name: "HomePage",
  props: {
    machineList: { type: Array, default: () => [] },
    interventionList1: { type: Array, default: () => [] },
  },
  data() {
    return {
      nextMaintenances:[],
      lastMaintenanceTable:[],
      tableColumns: [{
          label: 'Macchinario',
          field: 'machineId',
        }, {
          label: 'Descrizione',
          field: 'description',
        }
        ,
        //{
        //  label: 'Interventi fatti',
        //  field: 'interventionCount',
        //},
         {
          label: 'Frequenza man.(gg)',
          field: 'triggeredByDays',
        }, {
          label: 'Ultima manutenzione',
          field: 'lastInterventionDate',
        },
        {
          label: 'Scadenza',
          field: 'expiringDate',
        },
        {
          label: 'Giorni alla scadenza',
          field: 'daysToExpire',
        }
        
      ]
      
    };
  },
  async mounted() {
    let interventionsDone = (await serviceIntervention.getDoneIntervations()).data;
    let interventionsDone1 = JSON.stringify(interventionsDone);

    let tempInteventionsDic = {};
    for(let intervention of interventionsDone) {
      if(!tempInteventionsDic[intervention.maintenanceId]){
        tempInteventionsDic[intervention.maintenanceId] = intervention;
      } else {
        let _i = tempInteventionsDic[intervention.maintenanceId];
        if(new Date(intervention.endDate) > new Date(_i.endDate)) {
          tempInteventionsDic[intervention.maintenanceId] =intervention;
        }
      }
    }
    //console.log(tempInteventionsDic);
    let arrMaintenances = (await serviceMaintenance.getAllMaintenances()).data;
    arrMaintenances.forEach(m => {
      m.interventionDone = tempInteventionsDic[m._id];
      m.isToDo = this.isToDoMaintenance(m);
      if(m.interventionDone){  
        m.expirationDate = this.getExpirationDate(m.interventionDone.endDate, m.triggeredByDays);
      } else {
        m.expirationDate = this.getExpirationDate(new Date(1950,1,1,1,1,1,1), m.triggeredByDays);
      }
    });
    arrMaintenances = arrMaintenances.filter(m => m.isToDo);
    
    //ordino
    arrMaintenances.sort((a,b) => {
      return new Date(b) - new Date(a);
    })                           
    this.nextMaintenances = [...arrMaintenances];


    // -----LAST INTERVENTION TABLE
    //let interventionsDone1 = (await serviceIntervention.getDoneIntervations()).data;
    //const analyze_machine = '5fb946e804971d0017c5feff';

    let arrMaintenances1 = (await serviceMaintenance.getAllMaintenances()).data;
    //console.log("arrMaintenances1 -> " , arrMaintenances1 )
    arrMaintenances1.forEach(m =>{ 
      m.lastInterventionDate = new Date(1950,1,1,1,1,1,1);
      //if(m.machineId == analyze_machine)
        //console.log('Maintenance: ', m);
      let interventionsDone2 = JSON.parse(interventionsDone1);

      //interventionsDone2.forEach(int => {
        //console.log('ID INT DONE:' +  int._id +   ' | INT DONE:' + int.maintenanceId + ' | MAN PROG:' + m._id);
        //if(int.maintenanceId == m._id) 
          //console.log("Trovata corrispondenza:" +int.maintenanceId );
      //});
      //console.log("interventionsDone2 -> " , interventionsDone2.length )
      let interventionFiltered = interventionsDone2.filter(function(i) {
        //if(i.maintenanceId == analyze_machine && analyze_machine ==m._id)
          //console.log('Filter by:' + i.maintenanceId + ' with:' + m._id );
        return (i.maintenanceId == m._id)
      });
      //console.log("interventionFiltered -> " , interventionFiltered.length )
      //console.log("Intervention filtered: " + JSON.stringify(interventionFiltered));
      //if(m._id == analyze_machine)
        //console.log('Intervention filtered: ', interventionFiltered);
      
      //if(m._id == analyze_machine)
        //console.log(interventionFiltered);
      interventionFiltered.forEach( fi => {
        //console.log('Check dates FI:' +new Date(fi.endDate) + ' | M:' + new Date( m.lastInterventionDate));
        if(new Date(fi.endDate) > new Date(m.lastInterventionDate) ) {
          
          m.lastInterventionDate = fi.endDate;
        }
      } )
      let now = new Date();
      
      let lastIntDate = new Date(m.lastInterventionDate);
      let expiring = new Date(lastIntDate);
      m.interventionCount = interventionFiltered.length;
      expiring.setDate( expiring.getDate() + parseInt(m.triggeredByDays) );
      
      //m.expiringDate =  expiring;
      //console.log("Lastdate:" + lastIntDate + " + Days:" +m.triggeredByDays + " = Expiring:" + expiring   );
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      m.daysToExpire = Math.round((expiring - now) / oneDay);
      m.expiringDate = expiring;
      //let timetoexp = expiring - now;
      //m.daysToExpire = timetoexp.get;
    })
    this.lastMaintenanceTable = arrMaintenances1;


    //verify if there are a next maintenance that is started but not completed
    this.nextMaintenances.forEach(main => {
      //console.log(main);
      let iDoing = this.interventionList1.filter( lmain => {
       return (lmain.maintenanceId == main._id) 
      } )
      //console.log("Intervention Doing:" + iDoing.length)
      if(iDoing.length>0) {
        main.doing = true;
      }
    })
    //this.interventionList1.forEach(a=> {
      //console.log(a);
    //})


  },
  methods: {
    isToDoMaintenance(mantenanceWithLastIntervention) {
      var expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() - (mantenanceWithLastIntervention.triggeredByDays - 15));
      if(!mantenanceWithLastIntervention.interventionDone) 
        return true;
      return expirationDate > new Date(mantenanceWithLastIntervention.interventionDone.endDate);
    },
    getExpirationDate(date, days) {
      var expirationDate = new Date(date);
      expirationDate.setDate(expirationDate.getDate() + days);
      return expirationDate;
    },
    getMachineData(mId) {
      //console.log('Machine Id:' , mId);
      //console.log('Machine List:' , this.machineList);
      let machineFound = this.machineList.find(m => m._id == mId);
      if(machineFound) {
        return '(' + machineFound.shortCode + ') ' + machineFound.name;
      }
      return "MACCHINARIO NON INSERITO";
      
    },
    getMachineLabel(mId) {
      let machineName = this.machineList.find(m => m._id == mId);
      if(!machineName) machineName = 'Non trovata';
      else machineName = machineName.name + ' (' + machineName.shortCode + ')'; 
      return machineName; 
    },
    
    newIntervention() {
      this.onChangeIntervention({
         "status": "OPEN",
         "type": "RICHIESTA",
         "startDate": new Date().toISOString(),
         "endDate": new Date().toISOString(),
        "scheduledMaintenance": {
            "checkList": []
        },
        "notes":[]
      })
    },
    onChangeIntervention(intervention) {
      if(intervention) {
        intervention.isActive = intervention.isActive === undefined ? false : intervention.isActive;
        this.selectedIntervention = JSON.parse(JSON.stringify(intervention));        
        this.$refs['intervention-modal'].show()
      } else {       
        this.selectedIntervention = null; 
        this.$refs['intervention-modal'].hide()
      }
    }
  },
  components:{
    
    
  }
};
</script>

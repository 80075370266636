<template>
  <v-form v-model="valid">
    <v-text-field
      v-model="site.name"
      label="Name"
      :rules="[v => !!v || 'Name is required']"
      required
    ></v-text-field>
    <v-text-field
      v-model="site.postalCode"
      label="Codice postale"
      
    ></v-text-field>
    <v-text-field
      v-model="site.phone"
      label="Telefono"
      
    ></v-text-field>
    <v-text-field
      v-model="site.address"      
      label="Indirizzo"
      :rules="[v => !!v || 'Indirizzo è obbligatorio']"
      required
    ></v-text-field>
    <v-text-field
      v-model="site.city"
      label="Citta"
      required
      :rules="[v => !!v || 'Città è obbligatorio']"
    ></v-text-field>
    <!--
      :error-messages="emailErrors"
      @input="$v.email.$touch()"
      @blur="$v.email.$touch()"
     -->
    <v-text-field
      v-model="site.email"
      
      :rules="emailRules"
      label="E-mail"
      
      
    ></v-text-field>

    <v-row no-gutters>
      <v-col cols="12" sm="5">
        <v-text-field
          v-model="site.code"
          label="Codice"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12"
        sm="5">
        <v-text-field
          v-model="site.excode"
          label="Codice Esterno"
          
        ></v-text-field>
      </v-col>
    </v-row>
    <v-btn
      class="mr-4"
      @click="convertGps"
    >
      Converti
    </v-btn>
    <v-btn
      target="_blank"
      :href="'https://www.google.com/maps/search/?api=1&query=' +  site.gpsLat +'%2C' +  site.gpsLon"
      class="mr-4"
    >
      Vedi in Google Maps
    </v-btn>
    

    <v-row no-gutters>
      <v-col cols="12" sm="5">
        <v-text-field
          v-model="site.gpsLat"
          label="Latitudine"
          
        ></v-text-field>
      </v-col>
      <v-col cols="12"
        sm="5">
        <v-text-field
          v-model="site.gpsLon"
          label="Longitudine"
          
        ></v-text-field>
      </v-col>
    </v-row>
   
  

  <div class="conatiner-fluid" v-if="site">
    
      
    <div class="float-right" v-if="site._id">
        <button class="btn btn-outline-danger" @click="deleteSite" type="button">Elimina Luogo&times;</button>
    </div>
  </div>
</v-form>
</template>

<script>
export default {
  name: "SiteDettails",
  
  props: {
    site: { type: Object, default: () => null },
    machineList: { type: Array, default: () => [] }
  },
  data() {
    return {
      valid: false,
      emailRules: [
        //v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      orderStatusList: {

        'FINISH':'Esaurito',
        'ORDERED':'Ordinato',
        'IN_STOCK':'Presente',
        'UNKNOW':'(Ignoto...)',
      }
      
      
    }
  },
  methods: {
    deleteSite() {
      this.$emit("deleteSite", this.site);
    },
    convertGps() {
      let copiedGps =  prompt("Entra in Google Map, punta il luogo corretto, premi con il tasto destro e copia le coordinate. Incolla poi qui sotto");
      let gpsSplitted = copiedGps.split(","); 
      this.site.gpsLat = gpsSplitted[0].replace(/\s/g, '');
      this.site.gpsLon = gpsSplitted[1].replace(/\s/g, '');
      
    }
    
  }
};
</script>
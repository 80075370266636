<template>
  <div class="list-group">


    

    
    <vue-good-table
      :columns="tableColumns"
      styleClass="vgt-table striped condensed"
      :rows="list"
      :search-options="{
        enabled: true}">
      <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'">
            <b-button @click="changeSelected(props.row)">
                Modifica
            </b-button>
            <router-link class="btn btn-secondary" :to="'/machine/'+ props.row._id">Scheda riepilogo</router-link>
          </span>
          <span v-else-if="props.column.field == 'shortCode'">
            
            {{props.row.shortCode}}<b-badge variant="warning" v-if="props.row.shortCode.substring(0,3) === 'XX-'">Temp</b-badge>
            
          </span>
          <span v-else-if="props.column.field == 'tags'">
            <b-badge v-for="(t,index) in props.row.tags" variant="secondary" pill :key="index">{{t}}</b-badge>
          </span>
        </template>
    </vue-good-table>
  </div>
</template>

<script>
export default {
  name: "MachineList",
  props: {
    list: { type: Array, default: () => [] }
  },
  data() {
    return {
      tableColumns: [{
        label: '',
        field: 'actions',
      },{
        label: 'Codice breve',
        field: 'shortCode',
      }, {
        label: 'Nome',
        field: 'name',
      }, {
        label: 'Etichette',
        field: 'tags',
      }, {
        label: 'Modello',
        field: 'model',
      }, {
        label: 'N.Seriale',
        field: 'serial',
      }
      //, {
      //   label: 'customData',
      //   field: 'customData',
      // }]
      ]
    }
  },
  
  methods: {
    changeSelected(machine) {
      //console.log(machine);
      this.$emit("machineChanged", machine);
    }
  }
};
</script>
import axios from "axios"
import global from "../../../globalvar";

let state = {
    siteList: []
};
let getters = {};
let mutations = {
    setSiteList(state, SiteList) {
        state.siteList = SiteList
    }
};
let actions = {
    async loadSites({commit, dispatch, rootState}) {
        console.log(rootState);
        try {
            commit("appModule/startFetching", null, { root: true });
            let siteList = (await axios.get(global.ApiBaseUrl +"/api/site/all", {
                headers: {
                    "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
                }
            })).data;
            
            

            siteList = siteList.map(p => { 
                if(!p.gpsLat || !p.gpsLon)
                    return p;
                try {
                    p.gpsLat = parseFloat(p.gpsLat);
                    p.gpsLon = parseFloat(p.gpsLon);
                } catch {
                    console.warn('bad gps data', p.gpsLat, p.gpsLon);
                } 
                return p;
            })
            siteList.sort((s1,s2)=> {
                if (s1.name == s2.name ) {
                    return 0
                }
                if(s1.name > s2.name) {
                    return 1
                } else { return -1 }                       
                
            })
            commit("appModule/endFetching", null, { root: true })
            commit("setSiteList", siteList)
            
        } catch (e) {
            dispatch("appModule/addErrorMessage", e, { root: true } )
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
import axios from "axios"
import global from "../../../globalvar";

let state = {
    interventionList: [],
    selectedIntervention : null
};
let getters = {};
let mutations = {
    setInterventionList(state, interventionList) {
        state.interventionList = interventionList
    }
};
let actions = {
    async loadInterventions({commit, dispatch}) {
        try {
            commit("appModule/startFetching", null, { root: true });
            let interventionList = (await axios.get(global.ApiBaseUrl +"/api/maintenance/intervention/allOpen", {
                headers: {
                    "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
                }
            })).data;
            commit("appModule/endFetching", null, { root: true })
            commit("setInterventionList", interventionList)
            
        } catch (e) {
            dispatch("appModule/addErrorMessage", e, { root: true } )
        }
    },
    async loadArchive({commit, dispatch, rootState}) {
        console.log(rootState);
        try {
            commit("appModule/startFetching", null, { root: true });
            let interventionList = (await axios.get(global.ApiBaseUrl +"/api/maintenance/intervention/archived", {
                headers: {
                    "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
                }
            })).data;
            commit("appModule/endFetching", null, { root: true })
            commit("setInterventionList", interventionList)
        } catch (e) {
            dispatch("appModule/addErrorMessage", e, { root: true } )
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
import axios from "axios"
import global from "../../../globalvar";

let state = {
    orderList: []
};
let getters = {};
let mutations = {
    setOrderList(state, orderList) {
        state.orderList = orderList
    },
    addRow(state, row) {
        //console.log('Add row:' + row);
        state.orderList.rows.push(row);
    },
    addNewOrder(state, neworder) {
        //console.log('Add row:' + neworder);
        state.orderList.push(neworder);
    }
};
let actions = {
    async loadOrders({commit, dispatch}){//, rootState}) {
        //console.log(rootState);
        try {
            commit("appModule/startFetching", null, { root: true });
            //let orderList = (await axios.get(global.ApiBaseUrl +"/api/maintenance/order/all", {
            let orderList = (await axios.get(global.ApiBaseUrl + "/api/maintenance/order/all", {
                headers: {
                    "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
                }
            })).data;
            commit("appModule/endFetching", null, { root: true })
            commit("setOrderList", orderList)
        } catch (e) {
            dispatch("appModule/addErrorMessage", e, { root: true } )
        }
    },
    async loadArchive({commit, dispatch}){//, rootState}) {
        //console.log(rootState);
        try {
            commit("appModule/startFetching", null, { root: true });
            let orderList = (await axios.get(global.ApiBaseUrl + "/api/maintenance/order/archived", {
                headers: {
                    "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
                }
            })).data;
            commit("appModule/endFetching", null, { root: true })
            commit("setOrderList", orderList)
        } catch (e) {
            dispatch("appModule/addErrorMessage", e, { root: true } )
        }
    },


    async addOrderRow({commit}, row) {
        //console.log('Action add row');
        commit("addRow",row);

    },
    async orderToPdf({commit}, order) {
        commit("appModule/startFetching", null, { root: true });
        //console.log('Request PDF');
        await axios.post(global.ApiBaseUrl + "/api/createPDF/maintenance-order", order , {
            headers: {
                "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
            }
        });
        commit("appModule/endFetching", null, { root: true })

    },
    async newOrder({commit ,dispatch}) {
        try {
            commit("appModule/startFetching", null, { root: true });
            let neworder = (await axios.get(global.ApiBaseUrl + "/api/maintenance/order/create", {
                headers: {
                    "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
                }
            })).data;
            commit("appModule/endFetching", null, { root: true })
            commit("addNewOrder", neworder)
        } catch (e) {
            dispatch("appModule/addErrorMessage", e, { root: true } )
        }

    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
<template>
  <div class="conatiner-fluid" v-if="maintenance">
    
    <v-form v-model="valid">
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="8"
        >
          <v-text-field
            v-model="maintenance.description"
            :rules="nameRules"
            :counter="30"
            label="Descrizione"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="maintenance.triggeredByDays"
            :rules="number"
            label="Intervallo manutenzione in GG"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-autocomplete
            v-model="maintenance.machineId"
            :items="machineListFormatted"
            dense
            filled
            label="Macchinario"
          ></v-autocomplete>



      </v-row>




    </v-container>
  </v-form>


    <!--<div class="row">
        <div class="col-sm-6">
            <div class="form-group">
                <label>Macchinario:</label>
                <select v-model="maintenance.machineId" class="form-control">
                  <option value="">Scegli una machine...</option>
                  <option v-for="machine in machineList" :key="machine._id" :value="machine._id">{{machine.name}}</option>
                </select>
            </div>
          </div>          
          <div class="col-sm-6">
            <div class="form-group">
                <label>Frequenza manutenzione in giorni:</label>
                <input type="text" class="form-control" v-model="maintenance.triggeredByDays" />
            </div>
            <div class="form-group">
                <label>triggeredByTime</label>
                <input type="text" class="form-control" v-model="maintenance.triggeredByTime" />
            </div>
        </div>
    </div>-->
      
      <div class="mb-3 mt-2 bg-light p-3">
        <h4>Check List <button class="btn btn-sm btn-success align-center" @click="addCheckListDef">+</button></h4>
        <div class="list-group" v-if="maintenance.checkList && maintenance.checkList.length > 0">
          <div class="list-group-item" v-for="(item, index) in maintenance.checkList" :key="index">
            <input type="text" class="form-control w-50 d-inline-block" v-model="item.title" />
            <!-- <input type="text" class="form-control w-25 d-inline-block" v-model="item.result" /> -->
            
            <button type="button" class="btn btn-sm" @click="removeCheckListDef(index)">&times;</button>
          </div>
        </div>
      </div>
      <div class="float-right" v-if="maintenance._id">
        <button class="btn btn-outline-danger" @click="deleteMaintenance" type="button">&times; Elimina manutenzione</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "MaintenanceDettails",
  props: {
    maintenance: { type: Object, default: () => null },
    machineList: { type: Array, default: () => [] }
  },
  data() {
    return {}
  },
  methods: {
    addCheckListDef() {
      this.$emit("addCheckListDef", {});
    },
    removeCheckListDef(index) {
      this.$emit("removeCheckListDef", index);
    },
    deleteMaintenance() {
      this.$emit("deleteMaintenance", this.maintenance);
    }
    
  },
  computed: {
    machineListFormatted(){
      let data = this.machineList.map( elem => {
        return {
          value: elem._id.toString(),
          text: elem.name + ' (' + elem.code + ')', //elem.machineId
        }
      })
      return data;
    },
  }
};
</script>
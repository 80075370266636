<template>
  <div class="conatiner-fluid" v-if="order">
    
    <div class="row">
      <div class="col-sm-6">

          <div class="form-group">
              <label>Codice univoco ordine:</label>
              <b><label>{{order._id.substring(order._id.length-5,order._id.length)}}</label></b> 
             <!-- &nbsp;<b-button variant="primary" :disabled="order.orderStatus==='NEW'"  @click="pdfToOrder" type="button">Download PDF Ordine</b-button>-->
          </div>
          <div class="form-group">
              <label>Fornitore:</label>
              <input type="text" class="form-control" v-model="order.supplier" :disabled="order.orderStatus!='NEW'" />
          </div>
          <div class="form-group">
              <label>Contatto:</label>
              <input type="text" class="form-control" v-model="order.contact" :disabled="order.orderStatus!='NEW'" />
          </div>
            
        </div>          
        <div class="col-sm-6">
          <div class="form-group">
              <b-form-datepicker id="example-datepicker" v-model="order.orderDate" class="mb-2" :disabled="true"></b-form-datepicker>
          </div>

          <div class="form-group">
              <!--<label>Costo Totale:</label>
              <input type="text" class="form-control" v-model="order.price" />-->
              <v-text-field
                v-model="order.price"
                label="Costo in Euro"
                prefix="€"
                type="text"
                
              ></v-text-field>
          </div>
          

          <div class="form-group">
              <label>Note:</label>
              <input type="text" class="form-control" v-model="order.notes" />
          </div>
          <div class="form-group">
            <label>Stato</label>
            <b-form-select v-model="order.orderStatus" class="mb-3">
              <b-form-select-option v-for="(desc ,state) in orderStatusList"
                  :key="state"
                  :value="state">{{desc}}</b-form-select-option>
            </b-form-select>
        </div>

        <v-autocomplete
            v-model="order.machineId"
            :items="machineListFormatted"
            dense
            filled
            label="Macchinario"
          ></v-autocomplete>
            
            <!--<div class="form-group">
                <label>triggeredByTime</label>
                <input type="text" class="form-control" v-model="order.triggeredByTime" />
            </div>-->
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">

      <b-card no-body>
    <b-tabs content-class="mt-3" justifie v-if="order.orderStatus==='NEW'">
      <b-tab title="Aggiungi ricambio:" active >
        Seleziona PERSONALIZZATO o LISTA per aggiungere un ricambio all'ordine
      </b-tab>
      <b-tab title="Personalizzato" active>
        <b-card-text>
          <div class="row">
          
          <div class="col-sm-4">
            <div class="form-group">
              <label>Codice:</label>
              <input type="text" class="form-control" v-model="customSpare.code" />
            </div>
            <div class="form-group">
              <label>Codice fornitore:</label>
              <input type="text" class="form-control" v-model="customSpare.code2" />
            </div>
            <div class="form-group">
                <label>Nome:</label>
                <input type="text" class="form-control" v-model="customSpare.name" />
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
                <label>Descrizione:</label>
                <input type="text" class="form-control" v-model="customSpare.description" />
            </div>
            <div class="form-group">
                <label>Produttore:</label>
                <input type="text" class="form-control" v-model="customSpare.manufacture" />
            </div>
            <div class="form-group">
                <label>Macchinario:</label>
                <select v-model="customSpare.machineName" class="form-control">
                  <option value="">Scegli una machine...</option>
                  <option v-for="machine in machineList" :key="machine._id" :value="machine.name">{{machine.name}}</option>
                </select>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <div class="form-group">
              <label>UM:</label>
              <input type="text" class="form-control" v-model="customSpare.unity" />
            </div>
            <div class="form-group">
                <label>Quantità:</label>
                <input type="text" class="form-control" v-model="customSpare.qt" />
            </div>
            </div>
            <div class="form-group">

              <b-button v-b-toggle.collapse-1 @click="addCustomSpareToOrder()" variant="primary">Inserisci</b-button>
            </div>
          </div>
          </div>
        </b-card-text>
      </b-tab>
      <b-tab title="Lista">
        <b-card-text>
          <vue-good-table
      :columns="spareColumns"
      :rows="spare"
      :search-options="{
        enabled: true
      }"
      :pagination-options="{
        enabled: true,
        perPageDropdownEnabled: false,
        perPage: 5

      }"
      styleClass="vgt-table striped condensed"
        compactMode>
      <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'">
            
            <b-button  @click="addThisSpare(props.row)">Aggiungi</b-button>
          </span>
          <span v-else-if="props.column.field == 'qt'">
            <!--<input type="text" class="form-control" v-model="props.row.qt" />-->
            <b-input-group>
              <b-form-input type="number" v-model="props.row.qt" min="0.00"></b-form-input>
            </b-input-group>
          </span>
        </template>
    </vue-good-table>
        </b-card-text>
      </b-tab>
    </b-tabs>
  </b-card>


      
      </div>
    </div>



    
    <!--<b-button class="primary" @click="changeSelected(props.row)" disabled>Aggiungi ricambio</b-button>-->
      
    <br>
    <div class="row">
      <div class="col-sm-12">
        <vue-good-table
      :columns="tableColumns"
      :rows="order.rows"
      styleClass="vgt-table striped condensed"
        compactMode>
      <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions' && order.orderStatus==='NEW'">
            <b-button class="btn btn-outline-danger" @click="deleteRow(props.row.originalIndex)" >X</b-button>
          </span>
          <!--<span v-else-if="props.column.field == 'qt'">
            <input type="text" class="form-control" v-model="props.row.qt" :disabled="order.orderStatus!='NEW'" />
          </span>-->

          <span v-else-if="props.column.field == 'orderStatus'">
            {{getStatusLabel(props.row.orderStatus)}}
          </span>
        </template>
    </vue-good-table>

       <!-- <div class="mb-3 mt-2 bg-light p-3">
          <h4>Ricambi in ordine: <button class="btn btn-sm btn-success align-center" @click="addCheckListDef">+</button></h4>
          Nome:
          <div class="list-group" v-if="order.rows && order.rows.length > 0">
            <div class="list-group-item" v-for="(item, index) in order.rows" :key="index">
              <label>Codice:</label>
               <input type="text" class="form-control w-50 d-inline-block" v-model="item.code" />
              <label>Nome:</label> <input type="text" class="form-control w-50 d-inline-block" v-model="item.name" />
              Descrizione: <input type="text" class="form-control w-50 d-inline-block" v-model="item.description" />
              Produttore: <input type="text" class="form-control w-50 d-inline-block" v-model="item.manufacture" />
              Macchinario: <input type="text" class="form-control w-50 d-inline-block" v-model="item.machine" />
              Note: <input type="text" class="form-control w-50 d-inline-block" v-model="item.note" />
               <input type="text" class="form-control w-25 d-inline-block" v-model="item.result" /> 
              
              <button type="button" class="btn btn-sm" @click="removeCheckListDef(index)">&times;</button>
            </div>
          </div>
        </div>-->

      </div>
    </div>
      
      <!--<div class="mb-3 mt-2 bg-light p-3">
        <h4>Check List <button class="btn btn-sm btn-success align-center" @click="addCheckListDef">+</button></h4>
        <div class="list-group" v-if="order.checkList && order.checkList.length > 0">
          <div class="list-group-item" v-for="(item, index) in order.checkList" :key="index">
            <input type="text" class="form-control w-50 d-inline-block" v-model="item.title" />
            
            
            <button type="button" class="btn btn-sm" @click="removeCheckListDef(index)">&times;</button>
          </div>
        </div>
      </div>-->
    <div class="float-right" v-if="order._id">
        
        <button class="btn btn-outline-danger" @click="deleteOrder" type="button">Elimina Ordine&times;</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderDettails",
  
  props: {
    order: { type: Object, default: () => null },
    machineList: { type: Array, default: () => [] },
    spare: { type: Array, default: () => [] },
  },
  data() {
    return {
      orderStatusList: {
        'NEW':'Richiesto',
        'SENT':'Inviato',
        'HIDE':'Arrivato',
        
      },
    
      customSpare: {
        name: '',
        code : '',
        code2 : '',
        description : '',
        manufacture: '',
        notes: '',
        unity: '',
        qt: 0,
        
        
        
      },
      tableColumns: [ {
          label: 'Codice',
          field: 'code',
        },{
          label: 'Nome',
          field: 'name',
        },{
          label: 'Produttore',
          field: 'manufacture',
        },{
          label: 'Macchinario',
          field: 'machineName',
        }, {
          label: 'UM',
          field: 'unity',
        },{
          label: 'Qt.',
          field: 'qt',
        },
        {
          label: '',
          field: 'actions',
        },
      ],
      spareColumns: [ {
          label: 'Codice',
          field: 'code',
        },{
          label: 'Nome',
          field: 'name',
        },{
          label: 'Produttore',
          field: 'manufacture',
        },{
          label: 'Macchinario',
          field: 'machine',
        }, {
          label: 'Qt.',
          field: 'qt',
        },{
          label: '',
          field: 'actions',
        },
      ]

    
    }
  },
  computed : {
    machineListFormatted(){
      let data = this.machineList.map( elem => {
        return {
          value: elem._id.toString(),
          text: elem.name + ' (' + elem.code + ')', //elem.machineId
        }
      })
      return data;
    },
  },
  methods: {
    
    pdfToOrder() {
      //console.log('RequestPDF');
    },
    addCheckListDef() {
      this.$emit("addCheckListDef", {});
    },
    removeCheckListDef(index) {
      this.$emit("removeCheckListDef", index);
    },
    deleteOrder() {
      //console.log('DELETE!');
      this.$emit("deleteOrder", this.order);
    },
    addCustomSpareToOrder() {
      //this.customSpare
      let newSpare = JSON.parse(JSON.stringify(this.customSpare));
      if(newSpare.code == '' || newSpare.name == '' || newSpare.qt == '' || newSpare.qt == 0 ) {
        alert('Inserisci almeno CODICE, NOME e QUANTITA per inserire una parte!')

      }else {
        delete newSpare._id;
        delete newSpare.vgt_id;
        delete newSpare.originalIndex;
        delete newSpare.machine;
        this.order.rows.push(newSpare);
      }
      
      //console.log('Request add order');
      //this.$emit("addOrderRow",this.customSpare );
      //this.$store.dispatch("orderModule/addOrderRow",this.customSpare);
      
    },
    deleteRow(_rowIndex) {
      //console.log(_rowIndex);
      this.order.rows.splice(_rowIndex,1);

    },
    addThisSpare(spare) {
      //console.log('Spare passed:' + JSON.stringify(spare));
      if(spare.qt == 0 || spare.qt == '' || !spare.qt){
        alert('Devi indicare la quantità prima di aggiungere il ricambio!');
      }
      else {
        let tempSpare = {
          name: spare.name,
          code : spare.code,
          code2 : spare.code2,
          description : spare.description,
          manufacture: spare.manufacture,
          notes: spare.notes,
          unity: spare.unity,
          qt: spare.qt,
        }
        
        //delete spare._id;
        //delete spare.vgt_id;
        //delete spare.originalIndex;
        //delete spare.machine;
        this.order.rows.push(JSON.parse(JSON.stringify(tempSpare)));
      }
      
    }
    
  }
};
</script>
<template>
  <div class="customerPage">
    <!--<h1>Ricambi</h1>-->

    

    <div class="clearfix">
      <div class="float-left">
        <!--<button class="btn btn-primary" @click="newSpare">Nuovo ricambio <i class="fa fa-plus"></i></button>-->
      </div>
    </div>
    <!--@customerChanged="onChangeCustomer"-->
    <customer-list 
      
      :list="customerList"
      :machine-list="machineList" />
  </div>
</template>

<script>
import CustomerList from "@/components/customer/CustomerList.vue";


import { mapState } from "vuex";
//import customerService from "../services/customer";

export default {
  name: "CustomerView",
  data() {
    return {
      selectedCustomer: null
    };
  },
  mounted() {
    this.$store.dispatch("customerModule/loadCustomers");
    //this.$store.dispatch("machineModule/loadMachines");
  },
  computed: {
    ...mapState("customerModule", {
      customerList: function(state) {
        return state.customerList;
      }
    }),
    ...mapState("machineModule", {
      machineList: function(state) {
        return state.machineList;
      }
    }),
    inModifyMode() {
      return !!(this.selectedSpare && this.selectedSpare._id)
    }
  },
  methods: {
    
    
    
  },
  components: {
    CustomerList,
  }
};
</script>


<template>
  <div class="interventionPage">
    <h1>Richiesta intervento di manutenzione</h1>
      <div>
        <b-tabs content-class="mt-3">
          <b-tab v-if="statusPage=='NEW'" title="Nuova segnalazione" active>
            <div class="col-sm-6">
              <div class="form-group">
                  <label>Macchinario: </label>
                  <v-autocomplete
            v-model="intervention.machineId"
            :items="machineListFormatted"
            dense
            filled
            label="Macchinario:"
          ></v-autocomplete>

              </div>
              <div class="form-group">
                  <label>Operatore:</label>
                  <input class="form-control" v-model="intervention.openBy"/>
              </div>


              <div class="col-12 mb-3 mt-3">
              <div>
                <v-checkbox v-model="urgent" :label="`Urgente `"></v-checkbox>
                <v-checkbox v-model="alreadyReported" :label="`Già segnalato `"></v-checkbox>  
              </div>
              </div>
            </div>
            <div class="col-12" v-if="intervention.type !== 'PROGRAMMATA'">          
              <div class="form-group">
                  <label>Descrizione problema</label>
                  <textarea class="form-control" v-model="intervention.issueDescription"></textarea>
              </div>
            </div>
            <div class="float-left">
              <v-btn
                depressed
                color="primary"
                @click="onOkIntervention"
              >
                CREA <i class="fa fa-plus"></i>
              </v-btn>
              
            </div>

          </b-tab>
          <b-tab title="Inviata" v-if="statusPage=='SENT'">
            <p>Segnalazione registrata</p>
            <div class="float-left">
              <button class="btn btn-primary" @click="onNewIntervention" type="button">Invia nuova segnalazione <i class="fa fa-plus"></i></button>
            </div>
          </b-tab>
          <b-tab title="Errore" v-if="statusPage=='ERROR'" >
            <p>Qualcosa non ha funzionato, non è possibile inviare la segnalazione!</p>
            
          </b-tab>
        </b-tabs>
      </div>

      

    
  </div>
</template>

<script>
//import InterventionList from "@/components/intervention/InterventionList.vue";


import { mapState } from "vuex";
import interventionService from "../services/intervention";
import 'vue-select/dist/vue-select.css';


export default {
  name: "InterventionView",
  
  data() {
    return {
      machineIsSelected: null,
      alreadyReported: false,
      urgent: false,
      //machineLocalList:[],
      statusPage : 'NEW',
      intervention: { type: Object, default: () => null },
      interventionStatusList:{
        'OPEN':'Aperto',
        'ASSIGNED':'Assegnato',
        'WAITING PARTS':'Attesa ricambi',
        'CLOSED':'Chiuso',
      },
      helpTypeIntervention : {
        'RICHIESTA':'Richiesta di intervento',
        'PROGRAMMATA':'Programmato (parte delle manutenzioni)',
        'PROVVISORIA':'Provvisorio (da rivedere)',
        'ESTERNA':'Esterno (interviene azienda esterna)',
        'PERMANENTE':'Permanente (intervento stabile)'
      },
      selectedIntervention: null
    };
  },
  mounted() {
    if(!this.$route.query.tenant) this.statusPage = 'ERROR';
    this.intervention.machineId = this.$route.query.machine;
    this.$store.dispatch("machineModule/loadPublicMachines",this.$route.query.tenant);
    //this.machineLocalList =  await machineService.getPublicMachines( this.$route.query.tenant);
  },
  computed: {
    
    machineListFormatted(){
      let data = this.machineList.map( elem => {
        return {
          value: elem._id.toString(),
          text: elem.name + ' (' + elem.shortCode + ')', //elem.machineId
        }
      })
      //console.log("Oggetto:" + JSON.stringify(data));
      return data;
    },
    /*...mapState("interventionModule", {
      interventionList: function(state) {
        return state.interventionList;
      }
    }),*/
    ...mapState("machineModule", {
      machineList: function(state) {
        return state.machineList;
      }
    }),
    inModifyMode() {
      return !!(this.selectedIntervention && this.selectedIntervention._id)
    }
  },
  methods: {
    async onOkIntervention() {
      this.intervention.alreadyReported = this.alreadyReported;
      if(this.urgent) this.intervention.priority = 'URGENT';
      else this.intervention.priority = 'NORMAL';
      //console.log('Intervention new: ' , this.intervention);
      try {
        if(this.$route.query.tenant) {
          await interventionService.createPublicIntervention(this.$route.query.tenant,this.intervention);
        }else {
          this.statusPage='ERROR';
        }
        
        this.statusPage='SENT';
      } catch (err) {
        this.statusPage='ERROR';
      }
    },
    async onNewIntervention() {
      this.intervention =  { type: Object, default: () => null };
      this.statusPage='NEW';
    }
    
    
  },
  
};
</script>


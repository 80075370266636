import axios from "axios"
import global from "../../globalvar";

async function getEvents(startDate = 12112021, endDate = 14122022) {
	return (await axios.get(global.ApiBaseUrl + `/api/action/sft/maintenance/${startDate}/${endDate}`, {
		headers: {
			"x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
		}
	}))
}

export default {
	getEvents
}


import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import axios from 'axios';
import VueGeolocation from 'vue-browser-geolocation';

import VueApexCharts from 'vue-apexcharts'

Vue.use(VueGeolocation);
Vue.use(VueApexCharts)
// import './utils/datesUtils'
// import './registerServiceWorker'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import "bootstrap-vue/dist/bootstrap-vue.css";
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css' // Ensure you are using css-loader
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';
import vuetify from './plugins/vuetify'
Vue.use(VueGoodTablePlugin);
Vue.use(Vuetify)

Vue.component('apexchart', VueApexCharts)



Vue.use(require('vue-moment'));

Vue.config.productionTip = false

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
      el.clickOutsideEvent = function (event) {
          if (!(el == event.target || el.contains(event.target))) {
              if(vnode.context[binding.expression])
                vnode.context[binding.expression](event);
          }
      };
      document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
      document.body.removeEventListener('click', el.clickOutsideEvent)
  }
});

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  if(localStorage.getItem('appModule/authInfo')) {
    config.headers.Authorization = localStorage.getItem('appModule/authInfo');
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error?.response?.status === 401) {
    store.dispatch("appModule/setAuthInfo", null, { root: true });
    console.log("---401: ", error);
    location.reload();
    return Promise.reject(new Error("Non autorizzato"));
  }
  
  return Promise.reject(error);
});

new Vue({
  router,

  //vuetify, 

  store,

  vuetify,
  render: h => h(App)
}).$mount('#app')


<template>
  <div class="homePage">

    <v-row>
      
      <b-col>
        <v-card class="mx-auto" max-width="400">
          <v-card-text>
            <p class="text-h4 text--primary">
              Attività ora:
            </p>

            Aperte:{{nextMaintenances.length + interventionList1.length}}<br>
            In corso:<br>
            Scadute:{{nextMaintenances.length}}<br>
            Urgenti:<br>
            
            <!--<div class="text--primary">
              well meaning and kindly.<br>
              "a benevolent smile"
            </div>-->
          </v-card-text>

          <!--<v-card-actions>
            <v-btn text color="deep-purple accent-4">
              Learn More
            </v-btn>
          </v-card-actions>-->
        </v-card>
      </b-col>

      <b-col>
        <v-card class="mx-auto" max-width="400">
          <v-card-text>
            <p class="text-h4 text--primary">
              Download reports:
            </p>

            <v-btn depressed color="primary" @click="downloadRepManProgWithLast">
              Man. prog. con ultimo inter.
            </v-btn>
            <p></p>
            <v-btn depressed color="primary" @click="downloadMachineSiteAndStateLast">
              Macchinari con Stato e Luogo
            </v-btn>
           
            
          </v-card-text>

          
        </v-card>
      </b-col>
      <b-col>
        <v-card class="mx-auto" max-width="400">
          <v-card-text>
            <p class="text-h4 text--primary">
              Dati produzione:
            </p>

            <v-btn depressed color="primary" @click="goToRoute('productionstat')">
              Statistiche giornaliere
            </v-btn>
          </v-card-text>

          
        </v-card>
      </b-col>

      <!--<b-col>
        <v-card class="mx-auto" max-width="400">
          <v-card-text>
            <p class="text-h4 text--primary">
              Ultimi 90gg:
            </p>
          
            <p>Macchinari installati:{{stat.last90.newMachine}}</p>
            <p>Interventi chiusi:{{stat.last90.interventionClosed}}</p>
            <p>Interventi aperti:{{stat.last90.interventionOpen}}</p>
            <p>Visite fatte:{{stat.last90.visitsDone}}</p>
            
            
           
          </v-card-text>

         
        </v-card>
      </b-col>-->



    </v-row>

    <!--<h1>Attività da fare</h1>-->
   
   
  <div>
      <apexchart width="900" height="300" type="bar" :options="chartOpt60days" :series="chartData60day"></apexchart>
  </div>

  <div>
      <apexchart width="900" type="bar" :options="chartOptNumInt" :series="chartDataNumInt"></apexchart>
  </div>

<v-btn variant="outlined" @click="graphPreviousPage()">
  Macchine Precedenti
</v-btn>
<v-btn variant="outlined" @click="graphNextPage()">
  Macchine Successive
</v-btn>

<v-btn  @click="downloadInterventionPerMachine()">
  Download grafico
</v-btn>
    
   
    

  </div>
 
</template>

<script>
import serviceIntervention from "../services/intervention"
import serviceMaintenance from "../services/maintenance"
//import VueApexCharts from 'vue-apexcharts'
const ExcelJS = require('exceljs');

import moment from 'moment';
import { mapState } from "vuex";

export default {
  
  props: {
    machineList: { type: Array, default: () => [] },
    interventionList1: { type: Array, default: () => [] },
  },
  data() {
    return {

      chartData60day: [
          
          /*{
            name: "High - 2013",
            data: [2, 9, 3, 6, 2, 2, 3]
          },
          {
            name: "Low - 2013",
            data: [12, 11, 14, 18, 17, 13, 13]
          }*/
        ],
        
      chartOpt60days : {
          chart: {
          height: 10,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#77B6EA', '#545454'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: 'Interventi aperti e chiusi nell ultimo periodo',
          align: 'left'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          categories: [],//'1', '2', '3', '4', '5', '6', '7'],
          title: {
            text: ''
          }
        },
        yaxis: {
          title: {
            text: 'Quantita'
          },
          min: 0,
          max: 10
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      },

      chartDataNumIntStore: {
        scheduledTempChartData : [],
        otherTempChartData : [],
        nameTempChartData : [],
        scheduledTempChartDataOld : [],
        otherTempChartDataOld : [],
        nameTempChartDataOld : [],
      },
      
      chartDataNumInt: [
          
          
        ],
      chartOptNumInt : {
          extraStartingElement:0,
          chart: {
          height: 15,
          type: 'bar',
          /*dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },*/
          stacked: true,
          toolbar: {
            show: false
          }
        },
        colors: ['#77B6EA', '#545454' , '#745754' , '#A454A4'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: 'Interventi eseguiti per macchinario',
          align: 'left'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          categories: [],//'1', '2', '3', '4', '5', '6', '7'],
          title: {
            text: 'Macchinario'
          }
        },
        yaxis: {
          title: {
            text: 'Quantita'
          },
          
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      },




      

      nextMaintenances:[],
      lastMaintenanceTable:[],
      stat: {
        last90: {
          newMachine:  0,
          interventionClosed: 0,
          interventionOpen: 0,
          visitsDone: 0

        }
      },
      tableColumns: [{
          label: 'Macchinario',
          field: 'machineId',
        }, {
          label: 'Descrizione',
          field: 'description',
        }
        ,
        //{
        //  label: 'Interventi fatti',
        //  field: 'interventionCount',
        //},
         {
          label: 'Frequenza man.(gg)',
          field: 'triggeredByDays',
        }, {
          label: 'Ultima manutenzione',
          field: 'lastInterventionDate',
        },
        {
          label: 'Scadenza',
          field: 'expiringDate',
        },
        {
          label: 'Giorni alla scadenza',
          field: 'daysToExpire',
        }
        
      ]
      
    };
  },
  async mounted() {
    //let original_interventionsDone;
    //let original_arrMaintenances;

    let interventionsDone = (await serviceIntervention.getDoneIntervations()).data;
    let interventionsDone1 = await JSON.stringify(interventionsDone);
    

    let tempInteventionsDic = {};
    for(let intervention of interventionsDone) {
      if(!tempInteventionsDic[intervention.maintenanceId]){
        tempInteventionsDic[intervention.maintenanceId] = intervention;
      } else {
        let _i = tempInteventionsDic[intervention.maintenanceId];
        if(new Date(intervention.endDate) > new Date(_i.endDate)) {
          tempInteventionsDic[intervention.maintenanceId] =intervention;
        }
      }
    }
    //console.log(tempInteventionsDic);
    let arrMaintenances = (await serviceMaintenance.getAllMaintenances()).data;
    arrMaintenances.forEach(m => {
      m.interventionDone = tempInteventionsDic[m._id];
      m.isToDo = this.isToDoMaintenance(m);
      if(m.interventionDone){  
        m.expirationDate = this.getExpirationDate(m.interventionDone.endDate, m.triggeredByDays);
      } else {
        m.expirationDate = this.getExpirationDate(new Date(1950,1,1,1,1,1,1), m.triggeredByDays);
      }
    });
    arrMaintenances = arrMaintenances.filter(m => m.isToDo);
    
    //ordino
    arrMaintenances.sort((a,b) => {
      return new Date(b) - new Date(a);
    })                           
    this.nextMaintenances = [...arrMaintenances];

    let arrMaintenances1 = (await serviceMaintenance.getAllMaintenances()).data;
    arrMaintenances1.forEach(m =>{ 
      m.lastInterventionDate = new Date(1950,1,1,1,1,1,1);
      let interventionsDone2 = JSON.parse(interventionsDone1);
      let interventionFiltered = interventionsDone2.filter(function(i) {
        return (i.maintenanceId == m._id)
      });
      interventionFiltered.forEach( fi => {
        if(new Date(fi.endDate) > new Date(m.lastInterventionDate) ) {
          m.lastInterventionDate = fi.endDate;
        }
      } )
      let now = new Date();
      
      let lastIntDate = new Date(m.lastInterventionDate);
      let expiring = new Date(lastIntDate);
      m.interventionCount = interventionFiltered.length;
      expiring.setDate( expiring.getDate() + parseInt(m.triggeredByDays) );
      
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      m.daysToExpire = Math.round((expiring - now) / oneDay);
      m.expiringDate = expiring;
    })
    this.lastMaintenanceTable = arrMaintenances1;


    //verify if there are a next maintenance that is started but not completed
    this.nextMaintenances.forEach(main => {
      let iDoing = this.interventionList1.filter( lmain => {
       return (lmain.maintenanceId == main._id) 
      } )
      if(iDoing.length>0) {
        main.doing = true;
      }
    })
    //console.log("NMAIN:" + JSON.stringify(this.nextMaintenances))


    // ------------------- CALCOLO STATISTICHE -------------------------------------------------------------------------------

    const today = new Date();
    const thirtyDaysAgo = new Date(today.setDate(today.getDate() - 30));
    //----------------Last30Day int x day----------------------
    
    
    //const filteredData = interventionsDone.filter(item => new Date(item.startDate) > thirtyDaysAgo);
    
    const filteredData = interventionsDone.filter(item => new Date(item.endDate) > thirtyDaysAgo);
    //console.log("Elm.last30day:" + JSON.stringify(filteredData) )
    const conteggioPerData = {};
    const oggi = new Date();
    const giorniDaConsiderare = 30;

    // Inizializza l'array con 0 per ogni giorno da oggi fino a 60 giorni fa (serve per il grafico)
    const arrayConteggi = Array.from({ length: giorniDaConsiderare }, (_, index) => {
      const dataGiorno = new Date(oggi);
      dataGiorno.setDate(oggi.getDate() - index);
      this.chartOpt60days.xaxis.categories.push(dataGiorno.toISOString().split('T')[0])
      return { data: dataGiorno.toISOString().split('T')[0], conteggio: 0 };
    });
    const arrayConteggiNext = JSON.parse(JSON.stringify(arrayConteggi));

    filteredData.forEach((elemento) => {
      const dataElemento = new Date(elemento.startDate);
      const differenzaGiorni = Math.floor((oggi - dataElemento) / (1000 * 60 * 60 * 24));

      // Verificare se la data è negli ultimi 60 giorni
      if (differenzaGiorni >= 0 && differenzaGiorni < giorniDaConsiderare) {
        const dataSenzaOrario = elemento.startDate.split('T')[0];
        conteggioPerData[dataSenzaOrario] = (conteggioPerData[dataSenzaOrario] || 0) + 1;
      }
    });

    // Aggiorna l'arrayConteggi con i conteggi effettivi
    arrayConteggi.forEach((conteggio) => {
      const dataSenzaOrario = conteggio.data;
      conteggio.conteggio = conteggioPerData[dataSenzaOrario] || 0;
    });
    const arrayConteggiNumerici = arrayConteggi.map((conteggio) => conteggioPerData[conteggio.data] || 0);


    let newData = {
      name: "Interventi aperti e chiusi",
      data: arrayConteggiNumerici
    }
    console.log("Add to Chart" +JSON.stringify(newData))
    this.chartData60day.push(newData)

    console.log("INT.BY.DAY.LAST30" +JSON.stringify(arrayConteggiNumerici))

    //----------------Last x Day requests open NEXT ----------------------
    
    
    const filteredNextMaintenance30dd = this.nextMaintenances.filter(item => new Date(item.endDate) > thirtyDaysAgo);
    filteredNextMaintenance30dd.forEach((elemento) => {
      const dataElemento = new Date(elemento.startDate);
      const differenzaGiorni = Math.floor((oggi - dataElemento) / (1000 * 60 * 60 * 24));

      // Verificare se la data è negli ultimi XX giorni
      if (differenzaGiorni >= 0 && differenzaGiorni < giorniDaConsiderare) {
        const dataSenzaOrario = elemento.startDate.split('T')[0];
        conteggioPerData[dataSenzaOrario] = (conteggioPerData[dataSenzaOrario] || 0) + 1;
      }
    });
    // Aggiorna l'arrayConteggi con i conteggi effettivi
    arrayConteggiNext.forEach((conteggio) => {
      const dataSenzaOrario = conteggio.data;
      conteggio.conteggio = conteggioPerData[dataSenzaOrario] || 0;
    });
    const arrayConteggiNumericiNext = arrayConteggi.map((conteggio) => conteggioPerData[conteggio.data] || 0);


    let newDataNext = {
      name: "Interventi da fare",
      data: arrayConteggiNumericiNext
    }
    console.log("Add to Chart" +JSON.stringify(newDataNext))
    this.chartData60day.push(newDataNext)

    console.log("INT.BY.DAY.LAST30" +JSON.stringify(arrayConteggiNumericiNext))


    //----------------calculating stats--------------------------
    /*
    let refDate = new Date();
    refDate.setDate(refDate.getDate() -  90)
    console.log("Refdate:" + refDate + '|' + refDate.getTime())

    this.visitList.forEach(vi=> {
      if(vi.datetime > refDate)
        this.stat.last90.visitsDone++;
    })
    this.machineList.forEach(m=> { 
      //console.log('Compare:' + m.installDate + ' | ' + refDate )
      if(m.installDate)
        if(m.installDate.getTime()>refDate.getTime()){
          console.log('Last90.newMachine')
          this.stat.last90.newMachine++;
        }
    })*/


    console.log("Macchinari:" + this.machineList.length)
    console.log("Interventi finiti:" + interventionsDone.length)

    let interventionsArchived = (await serviceIntervention.getArchivedIntervations()).data;
    //-------------interveti per macchinario per anno

    const todayIs = moment();
    const fistDayCurrentY = moment(todayIs).startOf("year");
    const fistDayLastY = moment(todayIs).subtract(1, "years").startOf("year");

    for(let i=0;i<this.machineList.length;i++){ 
      const interentionOnMachine = interventionsArchived.filter(int => int.machineId == this.machineList[i]._id && int.openDate > fistDayCurrentY.toISOString()  );
      const scheduled = interentionOnMachine.filter(int => int.type == 'PROGRAMMATA')
      const other = interentionOnMachine.filter(int => int.type != 'PROGRAMMATA')
      console.log(this.machineList[i].name + " - Interventions SCHED/OTHER: " + scheduled.length + "/" + other.length  + " - " + this.machineList[i]._id )
      
      

      this.chartDataNumIntStore.nameTempChartData.push(this.machineList[i].name.toString())
      this.chartDataNumIntStore.scheduledTempChartData.push(scheduled.length)
      this.chartDataNumIntStore.otherTempChartData.push(other.length)  

      const interentionOnMachine2 = interventionsArchived.filter(int => int.machineId == this.machineList[i]._id && int.openDate < fistDayCurrentY.toISOString() && int.openDate > fistDayLastY.toISOString()  );
      const scheduled2 = interentionOnMachine2.filter(int => int.type == 'PROGRAMMATA')
      const other2 = interentionOnMachine2.filter(int => int.type != 'PROGRAMMATA')
      this.chartDataNumIntStore.nameTempChartDataOld.push(this.machineList[i].name.toString())
      this.chartDataNumIntStore.scheduledTempChartDataOld.push(scheduled2.length)
      this.chartDataNumIntStore.otherTempChartDataOld.push(other2.length)  



    }
    this.renderGraph();

    

    // ------------------- CALCOLO STATISTICHE FINE-------------------------------------------------------------------------------
    //------------ Intervention analisys -----------------------

  },
  methods: {
    renderGraph() {
      //console.log('Rendering graph')
      while(this.chartOptNumInt.xaxis.categories.length > 0) {
        this.chartOptNumInt.xaxis.categories.pop();
      }
      
      for(let i=this.chartOptNumInt.extraStartingElement;i<(this.chartOptNumInt.extraStartingElement+20);i++){
        this.chartOptNumInt.xaxis.categories.push(this.chartDataNumIntStore.nameTempChartData[i])
      }
      const todayIs = moment();
      this.chartDataNumInt = [
      {
              name: "Prog. " + todayIs.format("YYYY") ,
              data: this.chartDataNumIntStore.scheduledTempChartData.slice(this.chartOptNumInt.extraStartingElement, this.chartOptNumInt.extraStartingElement+20),
              group: 'LAST'
            },
            {
              name: "Altri" + + todayIs.format("YYYY"),
              data: this.chartDataNumIntStore.otherTempChartData.slice(this.chartOptNumInt.extraStartingElement, this.chartOptNumInt.extraStartingElement+20),
              group: 'LAST'
            },
            {
              name: "Prog. " + (todayIs.format("YYYY")-1),
              data: this.chartDataNumIntStore.scheduledTempChartDataOld.slice(this.chartOptNumInt.extraStartingElement, this.chartOptNumInt.extraStartingElement+20),
              group: 'PREV'
            },
            {
              name: "Altri " + (todayIs.format("YYYY")-1),
              data: this.chartDataNumIntStore.otherTempChartDataOld.slice(this.chartOptNumInt.extraStartingElement, this.chartOptNumInt.extraStartingElement+20),
              group: 'PREV'
            },
            

      ]
    },
    graphNextPage() {
      if(this.chartOptNumInt.extraStartingElement+20> this.chartDataNumIntStore.scheduledTempChartData.length)
        return;
      this.chartOptNumInt.extraStartingElement = this.chartOptNumInt.extraStartingElement+20
      this.renderGraph()
    },
    graphPreviousPage() {
      if(this.chartOptNumInt.extraStartingElement-20 < 0)
        return;
      this.chartOptNumInt.extraStartingElement = this.chartOptNumInt.extraStartingElement-20
      this.renderGraph()
    },
    goToRoute(destination) {
      // Naviga alla nuova rotta usando $router.push
      this.$router.push('/'+ destination);
    },
    async downloadRepManProgWithLast() {
      const workbook = new ExcelJS.Workbook();
      workbook.creator = 'Kilab.eu System';
      workbook.lastModifiedBy = 'Download';
      workbook.created = new Date();
      workbook.modified = new Date();
      const sheet = workbook.addWorksheet('Manutenzioni programmate');
      sheet.columns = [
        { header: 'Macchinario', key: 'machine',  width: 28 },
        { header: 'Descrizione', key: 'desc' , width: 34},
        { header: 'Frequenza man.(gg)', key: 'f' , width: 15},
        { header: 'Ultima manutenzione', key: 'desc' , width: 20},
        { header: 'Scadenza', key: 'desc' , width: 20},
        { header: 'Giorni alla scadenza', key: 'desc' , width: 20 }
      ];

      this.lastMaintenanceTable.forEach(el=> {
        let rowFormatted = [];
        rowFormatted.push(this.getMachineLabel(el.machineId)) 
        rowFormatted.push(el.description)
        rowFormatted.push(el.triggeredByDays)
        rowFormatted.push( moment(el.lastInterventionDate).format("DD/MM/YYYY"))
        rowFormatted.push(moment(el.expiringDate).format("DD/MM/YYYY"))
        rowFormatted.push(el.daysToExpire)

        sheet.addRow(rowFormatted);
      })
      const buffer = await workbook.xlsx.writeBuffer();
      
      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'manutenzioni.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();



    },

    async downloadInterventionPerMachine() {
      const workbook = new ExcelJS.Workbook();
      workbook.creator = 'Kilab.eu System';
      workbook.lastModifiedBy = 'Download';
      workbook.created = new Date();
      workbook.modified = new Date();
      const sheet = workbook.addWorksheet('Manutenzioni per macchinario');
      sheet.columns = [
        { header: 'Macchinario', key: 'machine',  width: 28 },
        { header: 'Programmate anno in corso', key: 'schethisy' , width: 34},
        { header: 'Altre anno in corso', key: 'otherthisy' , width: 15},
        { header: 'Programmate anno precedente', key: 'schelasty' , width: 34},
        { header: 'Altre anno precedente', key: 'othelasty' , width: 15},
        
      ];

      for(let i=0;i<this.chartDataNumIntStore.nameTempChartData.length;i++){
        let rowFormatted = [];
        rowFormatted.push(this.chartDataNumIntStore.nameTempChartData[i]);
        rowFormatted.push(this.chartDataNumIntStore.scheduledTempChartData[i]);
        rowFormatted.push(this.chartDataNumIntStore.otherTempChartData[i]);
        rowFormatted.push(this.chartDataNumIntStore.scheduledTempChartDataOld[i]);
        rowFormatted.push(this.chartDataNumIntStore.otherTempChartDataOld[i]);
        //rowFormatted.push(this.chartDataNumIntStore.nameTempChartData);
        //this.chartDataNumIntStore.nameTempChartDataOld.push(this.machineList[i].name.toString())


        sheet.addRow(rowFormatted);
      } 

      const buffer = await workbook.xlsx.writeBuffer();
      
      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'manutenzioni-per-macchina.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();



    },

    async downloadMachineSiteAndStateLast() {
      const workbook = new ExcelJS.Workbook();
      workbook.creator = 'Kilab.eu System';
      workbook.lastModifiedBy = 'Download';
      workbook.created = new Date();
      workbook.modified = new Date();
      const sheet = workbook.addWorksheet('Manutenzioni programmate');
      sheet.columns = [
        { header: 'Codice', key: 'machine',  width: 28 },
        { header: 'Serial N.', key: 'f' , width: 22},
        { header: 'Modello', key: 'desc' , width: 26},
        { header: 'Cod.Cliente', key: 'desc' , width: 26},
        { header: 'Proprietà', key: 'desc' , width: 26},
        { header: 'Stato', key: 'status' , width: 15},
        { header: 'Luogo', key: 'desc' , width: 30},
        { header: 'Data installazione', key: 'desc' , width: 20 },
        { header: 'Ultimo aggiornamento', key: 'desc' , width: 20 }
      ];

      this.machineList.forEach(el=> {
        let rowFormatted = [];
        rowFormatted.push(el.shortCode) 
        rowFormatted.push(el.serial)
        rowFormatted.push(el.model)
        rowFormatted.push(el.customer)
        rowFormatted.push(this.getCustoData(el.customData))//this.getCustoData())//(el?.customData.find(cd=> cd.name== 'Proprieta' ).value) )
        rowFormatted.push(this.getLastStatusMachine(el._id))
        rowFormatted.push(this.getSiteFromId(el.site))
        rowFormatted.push(moment(el.installDate).format("DD/MM/YYYY"))
        rowFormatted.push(moment(el.lastUpdate).format("DD/MM/YYYY"))

        sheet.addRow(rowFormatted);
      })
      const buffer = await workbook.xlsx.writeBuffer();
      
      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'manutenzioni.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();



    },
    isToDoMaintenance(mantenanceWithLastIntervention) {
      var expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() - (mantenanceWithLastIntervention.triggeredByDays - 15));
      if(!mantenanceWithLastIntervention.interventionDone) 
        return true;
      return expirationDate > new Date(mantenanceWithLastIntervention.interventionDone.endDate);
    },
    getExpirationDate(date, days) {
      var expirationDate = new Date(date);
      expirationDate.setDate(expirationDate.getDate() + days);
      return expirationDate;
    },
    getMachineData(mId) {
      //console.log('Machine Id:' , mId);
      //console.log('Machine List:' , this.machineList);
      let machineFound = this.machineList.find(m => m._id == mId);
      if(machineFound) {
        return '(' + machineFound.shortCode + ') ' + machineFound.name;
      }
      return "MACCHINARIO NON INSERITO";
      
    },
    getSiteFromId(sid) {
      let sf = this.siteList.find(m => m._id == sid);
      if(sf) {
        return sf.name + '(' + sf.city + ' - ' +sf.address + ') |' + sf.code ;
      }
      return "LUOGo NON IDENTIFICATO";
    },
    getLastStatusMachine(mid) {
      //console.log('ID passato:' +mid)
      if(!mid)
        return "Mancanza ID macchinario"
      let msList= []
      //if( mid == '62b0e7c2cc22252fe65dadee')
        //console.log('Machine to check, visitlist long:'+ this.visitList.length)
      
      for(let j = 0; j<this.visitList.length;j++){
        let v=this.visitList[j]
        if( mid == '62b0e7c2cc22252fe65dadee'){
          //console.log("----->Visits:" + JSON.stringify(v))
          //console.log('Lista:' + JSON.stringify(msList) );
        }
        for(let k=0;k<v.machines.length;k++ ){
          if(v.machines[k].machineId && v.machines[k].machineId == mid ){
            let found = msList.find(it=> it.id==v.machines[k].machineId)
            if(found){
              if( mid == '62b0e7c2cc22252fe65dadee'){
                //console.log('Found in msList:' + JSON.stringify(found) )
                //console.log(' -- Compare dates:' +found.date + ' | ' + v.datetime)
              } 
              
              if(moment(found.date).isBefore(v.datetime)){
                console.log("New value status:" + v.machines[k].status + " in date:" + v.datetime)
                found.status = v.machines[k].status
                found.date = v.datetime
              }
            }else

              if( mid == '62b0e7c2cc22252fe65dadee'){
                //console.log('Push:' + JSON.stringify({ id: v.machines[k].machineId || '', status: v.machines[k].status || 'Ignoto', date: v.datetime }) )
              }
              msList.push({ id: v.machines[k].machineId || '', status: v.machines[k].status || 'Ignoto', date: v.datetime })
          }
          
        }
      }
      //console.log("Lista:" + msList.length)
      //console.log("Lista:" + JSON.stringify(msList))
      let ma = msList.find(ms => { 
        //console.log(mid)
        return ms.id == mid
      })
      if(ma)
          return ma.status
      return "IGNOTO";
    },
    getMachineLabel(mId) {
      let machineName = this.machineList.find(m => m._id == mId||'');
      if(!machineName) machineName = 'Non trovata';
      else machineName = machineName.name + ' (' + machineName.shortCode + ')'; 
      return machineName; 
    },
    getCustoData(cd) {
      if(!cd)
        return 'Non presente'
      //console.log('CD:'+ JSON.stringify(cd))
      let rcd = cd.find(c=> c.name == 'Proprieta')
      if(!rcd)
        return 'Non presente'
      return rcd.value
      
    },
    
    newIntervention() {
      this.onChangeIntervention({
         "status": "OPEN",
         "type": "RICHIESTA",
         "startDate": new Date().toISOString(),
         "endDate": new Date().toISOString(),
        "scheduledMaintenance": {
            "checkList": []
        },
        "notes":[]
      })
    },
    onChangeIntervention(intervention) {
      if(intervention) {
        intervention.isActive = intervention.isActive === undefined ? false : intervention.isActive;
        this.selectedIntervention = JSON.parse(JSON.stringify(intervention));        
        this.$refs['intervention-modal'].show()
      } else {       
        this.selectedIntervention = null; 
        this.$refs['intervention-modal'].hide()
      }
    }
  },
  computed: {
    ...mapState("siteModule", {
      siteList: function(state) {
        return state.siteList;
      }
    }),
    ...mapState("visitModule", {
      visitList: function(state) {
        return state.visitList;
      }
    }),
  },
  components:{
   
    //VueApexCharts,
  
    
  }
};
</script>

<template>
  <div class="">
    <b-row>
      <b-col>
    <div class="form-group">
      
    </div>
    <b-button size="sm" to="/configuration/sensor">Configura sensori</b-button>

    <b-button v-b-toggle.collapse-user-inner size="sm">Dati dettagliati utente</b-button>
      <b-collapse id="collapse-user-inner" class="mt-2">
        <b-form-textarea
          id="textarea"
          v-model="authInfo"
          placeholder="Enter something..."
          rows="6"
          max-rows="15"
        ></b-form-textarea>
    </b-collapse>


    <b-button v-b-toggle.collapse-tenant-inner size="sm">Dati dettalgiati Tenant</b-button>
      <b-collapse id="collapse-tenant-inner" class="mt-2">
        <b-form-textarea
          id="textarea1"
          v-model="tenantData"
          placeholder="Enter something..."
          rows="6"
          max-rows="15"
        ></b-form-textarea>
    </b-collapse>

    <div class="form-group">
        Utente: 
        Permessi in Core: 
        Permessi in Maintenance: 

    </div>

    <div class="form-group">
        Codice Servizio: XXX
        Tenant: XXXX
        Configurazione: 
        Permessi in Maintenance: 

    </div>

    <div class="form-group">
        Codice Servizio: maintenance
        Tenant: XXXX
        Configurazione: 
        

    </div>
  

    
      </b-col>
    </b-row>
  </div>
</template>

<script>
//import machineService from "../services/machine"
//import maintenanceService from "../services/maintenance"
//import interventionService from "../services/intervention"
//import QrcodeVue from 'qrcode.vue'
//import * as htmlToImage from 'html-to-image';
export default {
  name: "Configuration",
  data() {
    return {
      authLocalInfo: '',
      tenantInfo: '',
      machine: null,
      associatedMintenances: [],
      associatedIntervations: [],
      qrcodevalue: "https://maintenance.kilab.eu/form?tenant=" + this.$store.getters["appModule/authInfo"].TCODE ,
    }
  },
  async mounted() {    
    /*this.machine = (await machineService.getMachine(this.$route.params.id)).data;
    if(this.machine) {
      this.associatedMintenances = (await maintenanceService.getMaintenancesByMachine(this.machine._id)).data
      this.associatedIntervations = (await interventionService.getIntervationsByMachine(this.machine._id)).data
    }*/
  },
  methods: {
    //goTo(destination ){

    //},
    /*
    printMachine() {
      alert("Machine")
    },
    printLabelJpg(labelElement) {
      //var htmlToImage = require('html-to-image');
      let linkname = 'machine_' + this.machine.name + '.jpeg';
      htmlToImage.toJpeg(document.getElementById(labelElement), { quality: 0.90 }) //this.machine.shortCode), { quality: 0.90 })
      .then(function (dataUrl) {
        var link = document.createElement('a');
        
        console.log(linkname);
        link.download = linkname;
        //link.download = 'machine_.jpeg';
        link.href = dataUrl;
        link.click();
      });


    },
    printLabelPng(labelElement) {
      //var htmlToImage = require('html-to-image');
      let linkname = 'machine_' + this.machine.name + '.png';
      htmlToImage.toPng(document.getElementById(labelElement), { quality: 0.90 })
      .then(function (dataUrl) {
        var link = document.createElement('a');
        console.log(linkname);
        link.download = linkname;
        //link.download = 'machine_.jpeg';
        link.href = dataUrl;
        link.click();
      });


    }
    */
  },
  computed: {
    authInfo() {
      
      return this.$store.getters["appModule/authInfo"];
    },
    tenantData() {
      return this.$store.getters["appModule/tenantData"];

    }
  },
  components: {
    //QrcodeVue,
  }
};
</script>
import axios from "axios"
import global from "../../../globalvar";

let state = {
    machineList: [],
    machineDetail: null,
    filter : {
        ownerid : null
    }
};

//
let getters = {
    machineListFiltered() {
        let result = state.machineList;
        if(state.filter.ownerid)
            result = result.machineList.filter(el => {
                return el.owner = state.filter.ownerid;
            })

        return result
    }

};
let mutations = {
    setMachineList(state, machineList) {
        state.machineList = machineList
    },
    setMachineDetial(state,machine){
        state.machineDetail = machine;
    },
    setOwnerFilter(state,owner){
        state.filter.ownerid = owner;
    }
};
let actions = {
    async loadMachineDetails({commit, dispatch}, machine) {//async loadMachines({commit, dispatch, rootState}) {
        //console.log(rootState);
        try {
            commit("appModule/startFetching", null, { root: true });
            if(!machine){
                commit("setMachineDetial", {
                    customData:[]
                })
                return
            }
            
            let machineRes = (await axios.get(global.ApiBaseUrl +"/api/maintenance/machine/single/" + machine._id ,  {
                headers: {
                    "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
                }
            })).data;
            commit("setMachineDetial", machineRes)

        } catch (e) {
            dispatch("appModule/addErrorMessage", "Errore nel caricamento", { root: true } )
        }
        finally{
            commit("appModule/endFetching", null, { root: true })
        }
    },

    async loadMachines({commit, dispatch}) {//async loadMachines({commit, dispatch, rootState}) {
        //console.log(rootState);
        try {
            commit("appModule/startFetching", null, { root: true });
            let machineList = (await axios.get(global.ApiBaseUrl +"/api/maintenance/machine/all", {
                headers: {
                    "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
                }
            })).data;
            commit("appModule/endFetching", null, { root: true })
            commit("setMachineList", machineList)
        } catch (e) {
            dispatch("appModule/addErrorMessage", e, { root: true } )
        }
    },

    async loadPublicMachines({commit, dispatch},tenantId) {
        //console.log(rootState); , rootState
        //console.log('Load data from tenant:' + tenantId);
        try {
            commit("appModule/startFetching", null, { root: true });
            let machineList = (await axios.get(global.ApiBaseUrl +"/api/public/machine/" + tenantId + "/all", {
                
            })).data;
            commit("appModule/endFetching", null, { root: true })
            commit("setMachineList", machineList)
        } catch (e) {
            dispatch("appModule/addErrorMessage", e, { root: true } )
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
import axios from "axios"
import global from "../../../globalvar";

let state = {
    sensorList: []
};
let getters = {};
let mutations = {
    setSensorList(state, sensorList) {
        state.sensorList = sensorList
    }
};
let actions = {
    async loadSensors({commit, dispatch, rootState}) {
        console.log(rootState);
        try {
            commit("appModule/startFetching", null, { root: true });
            let sensorList = (await axios.get(global.ApiBaseUrl +"/api/maintenance/sensor/all", {
                headers: {
                    "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
                }
            })).data;
            commit("appModule/endFetching", null, { root: true })
            commit("setSensorList", sensorList)
        } catch (e) {
            dispatch("appModule/addErrorMessage", e, { root: true } )
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
import axios from "axios"
import global from "../../globalvar";

async function getAllMaintenances() {
    return (await axios.get(global.ApiBaseUrl +"/api/maintenance/maintenance/all", {
        headers: {
            "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
        }
    }))
}
async function getMaintenancesByMachine(machineId) {
    return (await axios.get(global.ApiBaseUrl +"/api/maintenance/maintenance/bymachine/" + machineId, { 
        headers: {
            "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
        }
    }))
}
async function createMaintenance(maintenance) {
    const _maintenance = JSON.parse(JSON.stringify(maintenance));
    return (await axios.post(global.ApiBaseUrl +"/api/maintenance/maintenance/create", _maintenance, { 
        headers: {
            "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
        }
    }))
}
async function updateMaintenance(maintenance) {
    const _maintenance = JSON.parse(JSON.stringify(maintenance));
    return (await axios.put(global.ApiBaseUrl +"/api/maintenance/maintenance/" + maintenance._id + "/edit", 
        _maintenance, 
        { 
            headers: {
                "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
            }
        }
    ))
}
async function deleteMaintenance(maintenanceId) {
    return (await axios.delete(global.ApiBaseUrl +"/api/maintenance/maintenance/delete/" + maintenanceId,
        { 
            headers: {
                "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
            }
        }
    ))
}


export default {
    getAllMaintenances,
    getMaintenancesByMachine,
    createMaintenance,
    updateMaintenance,
    deleteMaintenance
}
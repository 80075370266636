import axios from "axios"
import global from "../../globalvar";

async function getAllsensors() {
    return (await axios.get(global.ApiBaseUrl +"/api/maintenance/sensor/all", {
        headers: {
            "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
        }
    }))
}
async function getsensorsByMachine(machineId) {
    return (await axios.get(global.ApiBaseUrl +"/api/maintenance/sensor/bymachine/" + machineId, { 
        headers: {
            "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
        }
    }))
}
async function createsensor(sensor) {
    const _sensor = JSON.parse(JSON.stringify(sensor));
    return (await axios.post(global.ApiBaseUrl +"/api/maintenance/sensor/create", _sensor, { 
        headers: {
            "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
        }
    }))
}
async function updatesensor(sensor) {
    const _sensor = JSON.parse(JSON.stringify(sensor));
    return (await axios.put(global.ApiBaseUrl +"/api/maintenance/sensor/" + _sensor._id + "/edit", 
        _sensor, 
        { 
            headers: {
                "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
            }
        }
    ))
}
async function deleteSensor(sensorId) {
    return (await axios.delete(global.ApiBaseUrl +"/api/maintenance/sensor/delete/" + sensorId,
        { 
            headers: {
                "x-auth-token": JSON.parse(localStorage.getItem("authInfo")).token
            }
        }
    ))
}


export default {
    getAllsensors,
    getsensorsByMachine,
    createsensor,
    updatesensor,
    deleteSensor
}
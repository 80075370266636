let ApiBaseUrl= "https://k-core.herokuapp.com";//"https://k-core.apps.kilab.eu";
//ApiBaseUrl= "http://localhost:3081";
//let ApiBaseUrl= process.env.KILABMODE ? "http://localhost:3081" : "https://k-core.herokuapp.com" ;



if(window.webpackHotUpdate || process.env.NODE_ENV=="development" ) {
    ApiBaseUrl= "http://localhost:3081";
    
}
console.log('ENDPOINT IS =' + ApiBaseUrl);
exports.ApiBaseUrl = ApiBaseUrl;
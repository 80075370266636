export const BOUND_LAT_LONG_LIMIT = 0.018;
export const BOUND_NUMBER_LIMIT = 3;
export const distantsDictionary = {};

export function isInRectBounds(point1, point2) {
  let boundLatStart = point1.lat - BOUND_LAT_LONG_LIMIT;
  let boundLatEnd = point1.lat + BOUND_LAT_LONG_LIMIT;
  let boundLongStart = point1.long - BOUND_LAT_LONG_LIMIT;
  let boundLongEnd = point1.long + BOUND_LAT_LONG_LIMIT;

  return (
    boundLatStart < point2.lat &&
    point2.lat < boundLatEnd &&
    boundLongStart < point2.long &&
    point2.long < boundLongEnd
  );
}

export function distance(point1, point2) {
  const dicrionaryKey = `key:${point1.lat}/${point1.long}/${point2.lat}/${point2.long}`;
  if (distantsDictionary[dicrionaryKey])
    return distantsDictionary[dicrionaryKey];

  var p = Math.PI / 180;
  var a =
    0.5 -
    Math.cos((point2.lat - point1.lat) * p) / 2 +
    (Math.cos(point1.lat * p) *
      Math.cos(point2.lat * p) *
      (1 - Math.cos((point2.long - point1.long) * p))) /
      2;

  distantsDictionary[dicrionaryKey] = 12742 * Math.asin(Math.sqrt(a));
  return distantsDictionary[dicrionaryKey];
}
<template>
  <div class="sitePage">
  <!--
    <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      
    <v-data-table
    :headers="headers"
    :items="siteList"
    sort-by="calories"
    class="elevation-1"
    
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Luoghi</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              New Item
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.name"
                      label="Dessert name"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.calories"
                      label="Calories"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.fat"
                      label="Fat (g)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.carbs"
                      label="Carbs (g)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.protein"
                      label="Protein (g)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn>
    </template>
  </v-data-table>
-->

    <!--<h1>Ricambi</h1>-->

    <b-modal size="xl" ref="site-modal" 
      :title="(inModifyMode ? 'Modifica' : 'Crea') + ' luogo'"
      :ok-title="inModifyMode ? 'Modifica' : 'Crea'"
      @close="onChangeSite(null)"
      @clanel="onChangeSite(null)"
      @ok="onOkSite">
      <site-dettails 
        :site="selectedSite"
        :machine-list="machineList"
        @deleteSite="onDeleteSite"
        
         />
        
    </b-modal>

    <div class="clearfix">
      
      <v-btn
        depressed
        color="primary"
        @click="newSite"
      >
      Nuovo Luogo <i class="fa fa-plus"></i>
      </v-btn>
    </div>
    <!--@siteChanged="onChangesite"-->
    <site-list 
      @siteChanged="onChangeSite"
      :list="siteList"
      :machine-list="machineList" />
  </div>
</template>

<script>
import SiteList from "@/components/site/siteList.vue";
import SiteDettails from "@/components/site/siteDettails.vue";
import { mapState } from "vuex";
import siteService from "../services/site";

export default {
  name: "siteView",
  data() {
    return {
      selectedSite: null,

      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'Dessert (100g serving)',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Indirizzo', value: 'address' },
        { text: 'Citta', value: 'city' },
        { text: 'Codice', value: 'code' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],      
      editedIndex: -1,
      editedItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      defaultItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },



    };
  },
  mounted() {
    //this.$store.dispatch("siteModule/loadSites");
    //this.$store.dispatch("machineModule/loadMachines");
  },
  computed: {
    ...mapState("siteModule", {
      siteList: function(state) {
        return state.siteList;
      }
    }),
    ...mapState("machineModule", {
      machineList: function(state) {
        return state.machineList;
      }
    }),
    inModifyMode() {
      return !!(this.selectedSite && this.selectedSite._id)
    }
  },
  methods: {
    async onOkSite() {
      if(!this.selectedSite)
        return;
      if(this.selectedSite._id) {
        await siteService.updateSite(this.selectedSite)
      } else {
        await siteService.createSite(this.selectedSite)
      }
      
      this.$store.dispatch("siteModule/loadSites")
    },
    async onDeleteSite(site) {
      //console.log('Deleting?');
      if(!this.selectedSite || !this.selectedSite._id || this.selectedSite._id !== site._id)
        return;
      if(!confirm("Confermi di voler cancellare questa spare")) {
        return;
      }
      await siteService.deleteSpare(this.selectedSite._id)
      this.$store.dispatch("siteModule/loadSites")
    },
    newSite(){
        this.onChangeSite({})
    },
    onChangeSite(site) {
      if(site) {
        this.selectedSite = JSON.parse(JSON.stringify(site));        
        this.$refs['site-modal'].show()
      } else {       
        this.selectedSite = null; 
        this.$refs['site-modal'].hide()
      }
    }
    
    
  },
  components: {
    SiteDettails,
    SiteList
    
  }
};
</script>

